import "./summary.css";
import React from "react";
import moment from "moment";
import { Installation } from "../../../api/requests/installationsService";
import { installationLabel } from "../../../costants/costants";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type InstallationSummaryProps = {
  selected_installation: Installation | null;
};

const InstallationSummary: React.FC<InstallationSummaryProps> = ({
  selected_installation,
}) => {
  const { t } = useTranslation();

  const dateFormatter = (date: string) => {
    return moment(date).format("DD/MM/YY");
  };

  return (
    <Card
      variant="outlined"
      style={{ marginBottom: "5%", marginLeft: "5%", marginRight: "5%" }}
    >
      <CardContent>
        <TableContainer>
          <Table
            size="small"
            aria-label="simple table"
            title={installationLabel}
          >
            <TableBody>
              {selected_installation?.installation_info?.mate_sn && (
                <TableRow key="matesn">
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ borderBottom: "none" }}
                  >
                    {t("mateSn")}
                  </TableCell>
                  <TableCell align="left" style={{ borderBottom: "none" }}>
                    {selected_installation?.installation_info?.mate_sn ?? ""}
                  </TableCell>
                </TableRow>
              )}
              {selected_installation?.installation_info?.active_box_sn && (
                <TableRow key="activeboxsn">
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ borderBottom: "none" }}
                  >
                    {t("activeBoxSn")}
                  </TableCell>
                  <TableCell align="left" style={{ borderBottom: "none" }}>
                    {selected_installation?.installation_info?.active_box_sn ??
                      ""}
                  </TableCell>
                </TableRow>
              )}
              <TableRow key="model">
                <TableCell
                  component="th"
                  scope="row"
                  style={{ borderBottom: "none" }}
                >
                  {t("model")}
                </TableCell>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  {selected_installation?.model ?? ""}
                </TableCell>
              </TableRow>
              <TableRow key="createdat">
                <TableCell
                  component="th"
                  scope="row"
                  style={{ borderBottom: "none" }}
                >
                  {t("creationDate")}
                </TableCell>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  {dateFormatter(selected_installation?.created_at ?? "")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default InstallationSummary;
