/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  LinearProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useCallback, useEffect, useState } from "react";
import {
  checkServerPort,
  getInstallation,
  saveLogs,
} from "../../../api/services/installationsService";
import TitleWithBack from "../../custom/TitleWithBack";
import { technicalTestingTitle } from "../title";
import {
  devicesAction,
  FileObject,
  firstPortRange,
  lastPortRange,
  maxConnectionAttempts,
  ResponseObject,
  TestObject,
  testSignal,
} from "./costant";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import ResultTesting from "./ResultTesting";
import { useHistory } from "react-router";
import { deviceConnectionConfigurationUrl, selectTestsUrl } from "../costants";
import {
  receiveChannels,
  sendChannels,
} from "../deviceConnectionConfiguration/costant";
import { BackendUrl } from "../../../api/constants";
import { Installation } from "../../../api/requests/installationsService";
import { defaultype, primaryType } from "../../../costants/costants";
import "./testing.css";
import { debounceFunc } from "../../../utilities/utilities";
import { Alert, AlertTitle, Grid } from "@mui/material";
import ButtonItem from "../../custom/ButtonItem";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

type TechnicalTestingProps = {
  installationId: string;
  initialTests: TestObject[];
  email: string;
};

const TechnicalTesting: React.FC<TechnicalTestingProps> = ({
  installationId,
  initialTests,
  email,
}) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [board, setBoard] = useState<string>("");
  const [uid, setUid] = useState<string>("");
  const [loadPage, setLoadPage] = useState<boolean>(true);

  const [loadInstallatation, setLoadInstallation] = useState<boolean>(true);
  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);

  useEffect(() => {
    getInstallation({ id: installationId }).then((res: any) => {
      if (res && res.installation) {
        setSelectedInstallation(res.installation);
      }
      setLoadInstallation(false);
    });
  }, []);

  const [logs, setLogs] = useState<ResponseObject[]>([]);
  const [portError, setPortError] = useState<boolean>(false);
  useEffect(() => {
    if (portError) {
      saveLogs(installationId, [
        {
          action: "loading_test_firmware",
          status: "finished",
          result: "success",
          request: "loading TEST firmware",
          msg: "ERROR exception loading",
          date: new Date().toISOString(),
          user: email,
        },
      ]).then((res) => {
        if (res && !res.err) {
          console.log("LOGS SAVED CORRECTLY");
        } else {
          console.log(res?.err?.message || "ERROR SAVING LOGS");
        }
      });
    }
  }, [portError]);

  useEffect(() => {
    if (!loadPage && !loadInstallatation && !portError) {
      saveLogs(installationId, [
        {
          action: "loading_test_firmware",
          status: "finished",
          result: "success",
          request: "loading TEST firmware",
          msg: "OK firmware loaded successfully",
          date: new Date().toISOString(),
          user: email,
        },
      ]).then((res) => {
        if (res && !res.err) {
          console.log("LOGS SAVED CORRECTLY");
        } else {
          console.log(res?.err?.message || "ERROR SAVING LOGS");
        }
      });
    }
  }, [loadPage, loadInstallatation, portError]);

  const ztc_check_discover = (result: any) => {
    if (!result) {
      enqueueSnackbar(t("deviceDetectionTitle"), {
        variant: "error",
        preventDuplicate: true,
      });
      setPortError(true);
    } else {
      setPortError(false);
    }
  };

  const [socketRef, setSocketRef] = useState<WebSocket>();
  const [devicePort, setDevicePort] = useState<string>("");
  const ztc_discover = useCallback(
    (result: any) => {
      console.log("DISCOVER: ", result);
      const devices: any[] = result.result;
      if (devices.length > 0) {
        let tmp_board: string | null = null;
        let tmp_uid: string | null = null;
        devices.map((el) => {
          if (!board && !uid) {
            tmp_board = el.board;
            tmp_uid = el.uid;
          }
        });
        if (!tmp_board || !tmp_uid) {
          setPortError(true);
        } else {
          setBoard(tmp_board);
          setUid(tmp_uid);
          if (socketRef) {
            sendWebSocketCommand(devicePort, "temp");
          }
        }
      } else {
        setPortError(true);
      }
    },
    [socketRef, devicePort]
  );

  const firmware_download = (result: string) => {
    console.log(sendChannels.technical_testing);
    if (result !== "") {
      setLoadPage(false);
    } else {
      setPortError(true);
    }
  };

  const connect = useCallback(() => {
    let userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(" electron/") > -1) {
      // Electron-specific code
      window.api.send(sendChannels.ztc_discover);
    }
  }, []);

  useEffect(() => {
    if (!loadInstallatation) {
      const firmware_download_result = window.api.receive(
        receiveChannels.technical_testing_result,
        (result: string) => firmware_download(result)
      );
      const ztc_discover_result = window.api.receive(
        receiveChannels.ztc_discover_result,
        (devices: any[]) => ztc_discover(devices)
      );
      const check_discover_result = window.api.receive(
        receiveChannels.ztc_check_discover_result,
        (result: any) => ztc_check_discover(result)
      );
      return () => {
        firmware_download_result();
        ztc_discover_result();
        check_discover_result();
      };
    }
  }, [loadInstallatation, socketRef, devicePort]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      socketRef?.close();
      clearInterval(intervalId);
    };
  }, []);

  const [feedback, setFeedback] = useState<ResponseObject[]>([
    { request: "uid" },
    { request: "wifi" },
    { request: "temphum" },
    { request: "accelerometer" },
    { request: "battery" },
    { request: "led_info" },
    { request: "led_battery" },
    { request: "motion_LH" },
    { request: "motion_RH" },
  ]);

  let attempts = 0;
  let intervalId: NodeJS.Timeout;
  let currentPort = firstPortRange;

  const connectSocket = useCallback((port: number) => {
    const socket = new WebSocket(`ws://localhost:${port}`);

    // socket connection
    socket.onopen = () => {
      console.log("Websocket connection opened ");
      setSocketRef(socket);
      listener(socket);
      let request: { [k: string]: any } = {
        action: devicesAction,
      };
      socket.send(JSON.stringify(request));
    };

    // socket on close and on error
    socket.onclose = () => {
      console.log("WebSocket closed");
    };
  }, []);

  useEffect(() => {
    if (feedback.length > 0) {
      if (
        feedback[feedback.length - 1] &&
        feedback[feedback.length - 1].response &&
        feedback[feedback.length - 1]?.response?.msg
          .toLowerCase()
          .includes("error")
      ) {
        setFailedTests((failedTests) => [
          ...failedTests,
          tests[feedback.length - 1],
        ]);
      }
    }
  }, [feedback]);

  const listener = useCallback(
    (socket: WebSocket) => {
      let first: boolean = true;
      socket.addEventListener("message", function (event) {
        try {
          const jsonMsg = JSON.parse(event.data.toString());
          console.log("RESPONSE ", jsonMsg);
          if (jsonMsg && jsonMsg.action) {
            switch (jsonMsg.action) {
              case devicesAction:
                if (
                  (jsonMsg.devices as any[]).length === 1 &&
                  jsonMsg.devices[0].board === "4ZeroBoxMobile"
                ) {
                  setDevicePort(jsonMsg.devices[0].port);
                  connect();
                } else {
                  setPortError(true);
                }
                break;
              case testSignal:
                if (event.data.toString().includes("OK measure") && first) {
                  first = false;
                  setLoadPage(false);
                  window.api.send(sendChannels.technical_testing, [
                    `${BackendUrl}/firmwares/tech_test`,
                    board,
                    uid,
                  ]);
                } else {
                  console.log(!event.data.toString().includes("OK measure"));
                  console.log(loadPage);
                  if (!event.data.toString().includes("OK measure") && first) {
                    first = false;
                    window.api.send(sendChannels.technical_testing, [
                      `${BackendUrl}/firmwares/tech_test`,
                      board,
                      uid,
                    ]);
                  } else {
                    setLoading(false);
                    let tmpFeedBack = [...feedback];
                    tmpFeedBack.map((f) => {
                      if (f.request === jsonMsg.request) {
                        f.response = { ...jsonMsg };
                      }
                    });
                    setFeedback((feedback) => [...tmpFeedBack]);
                    setLogs((logs) => [
                      ...logs,
                      {
                        ...jsonMsg,
                        date: new Date().toISOString(),
                        user: email,
                      },
                    ]);
                  }
                }
                break;
            }
          }
        } catch {
          console.log("error");
        }
      });
    },
    [feedback, loadInstallatation, selectedInstallation]
  );

  const startSocket = useCallback(() => {
    attempts = 0;
    intervalId = setInterval(() => {
      checkServerPort(currentPort.toString())
        .then((res: { status: string }) => {
          if (res && res.status && res.status === "ok") {
            clearInterval(intervalId);
            connectSocket(currentPort);
          }
        })
        .catch(() => {
          if (currentPort <= lastPortRange) {
            console.log(
              "Port: ",
              currentPort,
              " no server answer. Incrementing"
            );
            currentPort += 1;
          } else {
            if (attempts < maxConnectionAttempts) {
              console.log(
                "Attempt n: ",
                attempts,
                " finished, restarting from port: ",
                firstPortRange
              );
              attempts += 1;
              currentPort = firstPortRange;
            } else {
              console.log("Sorry. Connection failed");
              clearInterval(intervalId);
            }
          }
        });
    }, 200);
  }, []);

  useEffect(() => {
    if (!loadInstallatation) {
      startSocket();
    }
  }, [loadInstallatation]);

  const sendWebSocketCommand = (
    port: string,
    a: string,
    u?: string,
    f?: FileObject[]
  ) => {
    let request: { [k: string]: any } = {
      action: testSignal,
      port: port,
      value: a,
    };
    if (u) {
      request = {
        ...request,
        devicePort,
      };
    }
    if (f) {
      request = {
        ...request,
        files: f,
      };
    }
    console.log("Request sent: ", request);
    socketRef?.send(JSON.stringify(request));
  };

  const [tests, setTests] = useState<TestObject[]>(initialTests);
  const [failedTests, setFailedTests] = useState<any[]>([]);
  const handleNext = useCallback(
    (action: string) => {
      sendWebSocketCommand(devicePort, action);
      setLoading(true);
    },
    [devicePort]
  );
  const handleSkip = useCallback(
    (request: string) => {
      setLoading(false);
      let tmpFeedBack = [...feedback];
      tmpFeedBack.map((f) => {
        if (f.request === request) {
          f.response = { action: "", msg: "skip", result: "", status: "" };
        }
      });
      setFeedback((feedback) => [...tmpFeedBack]);
    },
    [feedback]
  );
  const handleReset = () => {
    setFeedback([
      { request: "uid" },
      { request: "wifi" },
      { request: "temphum" },
      { request: "accelerometer" },
      { request: "battery" },
      { request: "led_info" },
      { request: "led_battery" },
      { request: "motion_LH" },
      { request: "motion_RH" },
    ]);
    setTests([...failedTests]);
    setFailedTests([]);
  };

  const history = useHistory();
  const [finish, setFinish] = useState<boolean>(false);

  if (portError) {
    return (
      <>
        <TitleWithBack
          disabled={loading}
          title={technicalTestingTitle}
          key={technicalTestingTitle}
        />
        <Alert severity="error">
          <AlertTitle>{t("deviceDetectionTitle")}</AlertTitle>
          <Grid flexDirection="column">
            <Grid item>{t("deviceDetectionSubTitle")}</Grid>
            <Grid container item style={{ marginTop: "8px" }}>
              <Grid item style={{ marginRight: "8px" }}>
                <ButtonItem
                  buttonType={defaultype}
                  label={t("reload")}
                  buttonOnClick={debounceFunc(() => {
                    window.api.send(sendChannels.ztc_check_discover, [
                      board,
                      uid,
                    ]);
                  })}
                />
              </Grid>
              <Grid item>
                <ButtonItem
                  buttonType={primaryType}
                  label={t("goBack")}
                  buttonOnClick={debounceFunc(() => {
                    history.push(selectTestsUrl(installationId));
                  })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Alert>
      </>
    );
  }

  if (loadPage || loadInstallatation) {
    return (
      <>
        <TitleWithBack
          disabled={loading}
          title={t("technicalTesting")}
          key={technicalTestingTitle}
        />
        <div className="loading-container">
          <h3>{t("loadingTechnicalTests")}</h3>
          <LinearProgress color="info" />
        </div>
      </>
    );
  }

  return (
    <>
      <TitleWithBack
        disabled={loading}
        title={t("technicalTesting")}
        key={technicalTestingTitle}
      />
      {!finish ? (
        <>
          <Stepper
            orientation="vertical"
            color="red"
            style={{ marginRight: "5%", marginLeft: "35%" }}
          >
            {tests.map((step, index) => (
              <Step active key={step.command}>
                <StepLabel
                  optional={
                    feedback.filter((f) => f.request === step.command)
                      .length === 1
                      ? feedback.filter((f) => f.request === step.command)[0]
                          ?.response?.msg
                      : "" /*feedback[index]?.response?.msg*/
                  }
                  icon={
                    feedback.filter((f) => f.request === step.command)
                      .length === 1 ? (
                      feedback
                        .filter((f) => f.request === step.command)[0]
                        ?.response?.msg.toLowerCase()
                        .includes("error") ? (
                        <CancelSharpIcon style={{ color: "red" }} />
                      ) : feedback
                          .filter((f) => f.request === step.command)[0]
                          ?.response?.msg.toLowerCase() &&
                        !feedback
                          .filter((f) => f.request === step.command)[0]
                          ?.response?.msg.toLowerCase()
                          .includes("skip") ? (
                        <CheckCircleSharpIcon style={{ color: "green" }} />
                      ) : (
                        <Brightness1Icon />
                      )
                    ) : (
                      <Brightness1Icon />
                    )
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  <Typography> {step.description} </Typography>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <LoadingButton
                        variant="contained"
                        onClick={() => {
                          handleNext(step.command);
                        }}
                        sx={{
                          mt: 1,
                          mr: 1,
                          color: "white",
                          backgroundColor: "#cd8133",
                        }}
                        loading={loading}
                      >
                        {t("runTest")}
                      </LoadingButton>
                      <Button
                        onClick={() => handleSkip(step.command)}
                        disabled={loading}
                        sx={{ mt: 1, mr: 1 }}
                        style={{ color: !loading ? "#cd8133" : "#808080" }}
                      >
                        {t("skip")}
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          <div className="button-container">
            <ButtonItem
              buttonType={primaryType}
              label={t("finish")}
              buttonOnClick={() => {
                saveLogs(installationId, logs).then((res) => {
                  if (res && !res.err) {
                    console.log("LOGS SAVED CORRECTLY");
                  } else {
                    console.log(res?.err?.message || "ERROR SAVING LOGS");
                  }
                  setFinish(true);
                });
              }}
            />
          </div>
        </>
      ) : (
        <>
          <ResultTesting feedback={feedback} steps={tests} />
          <div className="btn-container">
            {failedTests.length > 0 ? (
              <Button
                variant="contained"
                onClick={debounceFunc(handleReset)}
                sx={{ mt: 1, mr: 1 }}
              >
                {t("retryFailedTests")}
              </Button>
            ) : null}
            <Button
              variant="contained"
              onClick={debounceFunc(() =>
                history.push(selectTestsUrl(installationId))
              )}
              sx={{ mt: 1, mr: 1 }}
            >
              {t("goBack")}
            </Button>
            <Button
              variant="contained"
              onClick={debounceFunc(() =>
                history.push(deviceConnectionConfigurationUrl(installationId))
              )}
              sx={{ mt: 1, mr: 1 }}
            >
              {t("deviceConfiguration")}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default TechnicalTesting;

/*
LOGS EXAMPLE
[
    {
        "action": "test_signal",
        "status": "finished",
        "result": "success",
        "request": "button",
        "msg": "OK counter 0\n",
        "date": "2022-11-22T14:54:32.991Z",
        "user": "installer@installer.com"
    },
    {
        "action": "test_signal",
        "status": "finished",
        "result": "success",
        "request": "wifi",
        "msg": "ERROR exception WifiBadSSID: \n",
        "date": "2022-11-22T14:54:46.358Z",
        "user": "installer@installer.com"
    },
    {
        "action": "test_signal",
        "status": "finished",
        "result": "success",
        "request": "wifi",
        "msg": "ERROR exception WifiBadSSID: \n",
        "date": "2022-11-22T14:55:00.573Z",
        "user": "installer@installer.com"
    }
]
*/
