import { MenuType, SubMenuType } from "../types/navbar/types";
import {
  adminPanelIconsUrl,
  alarmsPageUrl,
  createCustomerPageUrl,
  createInstallationUrl,
  createInstallerPageUrl,
  createSubscriptionPageUrl,
  customersListPageUrl,
  dealersAssociationsUrl,
  dealersListPageUrl,
  installationsListToUpdateUrl,
  installerPanelIconsUrl,
  installersAssociationsUrl,
  installersListPageUrl,
  routes,
  subscriptionsListToDataHistoryPageUrl,
  subscriptionsListToInstallationPageUrl,
  subscriptionsListToLogsUrl,
  subscriptionsListToUpdateUrl,
} from "../pages/costants";
import {
  addCustomerKey,
  adminRole,
  alarmsKey,
  createDealerKey,
  createInstallationKey,
  createInstallerKey,
  createSubscriptionKey,
  dataHistoryKey,
  dataRealTimeKey,
  dealerAssociationsKey,
  installerAssociationsKey,
  installerRole,
  updateCustomerKey,
  updateDealerKey,
  updateInstallationKey,
  updateInstallerKey,
  updateSubscriptionKey,
} from "../../costants/costants";

const adminItem: (t: any) => SubMenuType[] = (t: any) => [
  {
    key: createInstallerKey,
    label: t("createInstaller"),
    link: createInstallerPageUrl,
    elements: [],
  },
  {
    key: updateInstallerKey,
    label: t("editInstaller"),
    link: installersListPageUrl,
    elements: [],
  },
  {
    key: installerAssociationsKey,
    label: t("userList"),
    link: installersAssociationsUrl,
    elements: [],
  },
  {
    key: createDealerKey,
    label: t("createDealer"),
    link: routes.createDealer,
    elements: [],
  },
  {
    key: updateDealerKey,
    label: t("editDealer"),
    link: dealersListPageUrl,
    elements: [],
  },
  {
    key: dealerAssociationsKey,
    label: t("dealersAssociations"),
    link: dealersAssociationsUrl,
    elements: [],
  },
];

const installer: (t: any) => SubMenuType[] = (t: any) => [
  {
    key: addCustomerKey,
    label: t("createCustomer"),
    link: createCustomerPageUrl,
    elements: [],
  },
  {
    key: updateCustomerKey,
    label: t("editCustomer"),
    link: customersListPageUrl,
    elements: [],
  },
  {
    key: createSubscriptionKey,
    label: t("createFleet"),
    link: createSubscriptionPageUrl,
    elements: [],
  },
  {
    key: updateSubscriptionKey,
    label: t("editFleet"),
    link: subscriptionsListToUpdateUrl,
    elements: [],
  },
  {
    key: createInstallationKey,
    label: t("createDevice"),
    link: createInstallationUrl,
    elements: [],
  },
  {
    key: updateInstallationKey,
    label: t("editDevice"),
    link: installationsListToUpdateUrl,
    elements: [],
  },
  {
    key: "testHistory",
    label: t("testHistory"),
    link: subscriptionsListToLogsUrl,
    elements: [],
  },
];

export const adminMenuItems: (t: any) => MenuType[] = (t: any) => [
  // TODO: remove first element, it is necessary because the first element is not shown
  {
    key: "",
    label: "",
    elements: [],
    link: "",
  },
  {
    key: dataRealTimeKey,
    label: t("realTimeData"),
    elements: [],
    link: subscriptionsListToInstallationPageUrl,
  },
  {
    key: dataHistoryKey,
    label: t("dataHistory"),
    elements: [],
    link: subscriptionsListToDataHistoryPageUrl,
  },
  {
    key: alarmsKey,
    label: t("alarms"),
    link: alarmsPageUrl,
    elements: [],
  },
  {
    key: "wifiConfiguration",
    label: t("wifiConfiguration"),
    link: routes.subscriptionsListToWifi,
    elements: [],
  },
  {
    key: adminRole,
    label: t("adminPanel"),
    link: adminPanelIconsUrl,
    elements: adminItem(t),
  },
  {
    key: installerRole,
    label: t("configurator"),
    elements: installer(t),
    link: installerPanelIconsUrl,
  },
];
