import "./Header.css";
import { logout } from "../../api/services/loginService";
import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import { routes } from "../pages/costants";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import {
  AppBar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useStyles } from "../pages/muiStyle";
import clsx from "clsx";
import {
  fixedPosition,
  iconColor,
  iconEdge,
  iconLabel,
  logo as logoKey,
  menuId,
  successStatusKey,
} from "../../costants/costants";
import mateLogo from "../../images/MATE-logo.png";
import comauLogo from "../../images/Logo-COMAU-white.png";
import userLogo from "../../images/user.svg";
import { useTranslation } from "react-i18next";

type HeaderItemProps = {
  SetNavbarIsCollapsed: (collapsed: boolean) => void;
  navbar_collapsed: boolean;
};

const HeaderItem: React.FC<HeaderItemProps> = ({
  SetNavbarIsCollapsed,
  navbar_collapsed,
}) => {
  const { t, i18n } = useTranslation();

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [heigth, setHeigth] = useState<number>(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeigth(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const logOut = () => {
    logout().then((res) => {
      if (res !== undefined && res.status === successStatusKey) {
        history.push(res.location);
      }
    });
  };

  const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const menuStyles = {
    menu: {
      "& .MuiPaper-root": {
        backgroundColor: "lightblue",
      },
    },
  };

  return (
    <AppBar
      position={fixedPosition}
      className={clsx(classes.appBar)}
      style={{ backgroundColor: "#1f3366" }}
    >
      <Toolbar className={"header"} style={{ backgroundColor: "#1f3366" }}>
        {width > 768 && heigth > 414 ? (
          <img
            key={logoKey}
            className="logo"
            alt={logoKey}
            src={mateLogo}
            onClick={() => {
              history.push(routes.mainPage);
              SetNavbarIsCollapsed(true);
            }}
          />
        ) : (
          <IconButton
            color={iconColor}
            aria-label={iconLabel}
            edge={iconEdge}
            onClick={() => {
              SetNavbarIsCollapsed(!navbar_collapsed);
            }}
            size="large"
          >
            <MenuIcon htmlColor="white" />
          </IconButton>
        )}
        <section className={classes.rightToolbar}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              key="logo2"
              className="logo-comau"
              alt={logoKey}
              src={comauLogo}
              onClick={() => {
                history.push(routes.mainPage);
                SetNavbarIsCollapsed(true);
              }}
            />
            <img
              key={logoKey}
              className="logo-user"
              alt={logoKey}
              src={userLogo}
              onClick={openMenu}
            />
          </div>
        </section>

        <Menu
          id={menuId}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
        >
          <MenuItem
            onClick={() => {
              i18n.language === "en"
                ? i18n.changeLanguage("it")
                : i18n.changeLanguage("en");
            }}
            sx={{ menuStyles }}
          >
            {i18n.language === "en" ? "🇮🇹 Italiano" : "🇬🇧 English"}
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              history.push(routes.profile);
            }}
          >
            <PersonOutlineOutlinedIcon />
            {t("profile")}
          </MenuItem>
          <MenuItem onClick={logOut}>
            <PowerSettingsNewOutlinedIcon />
            {t("logout")}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderItem;
