import {
  address,
  city,
  country,
  email,
  name,
  phone,
  province,
  selectType,
  zip,
} from "../../../costants/costants";
import { numberType } from "../../../utilities/utilities";
import { InputType } from "../../types/form/types";

export const userFields: (t: any) => InputType[] = (t: any) => [
  {
    key: name.key,
    name: name.name,
    label: t("name"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: email.key,
    name: email.name,
    label: t("email"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: phone.key,
    name: phone.name,
    label: t("phone"),
    path: phone.name,
    rules: { required: true, message: t("requiredField") },
    type: numberType,
  },
  {
    key: address.key,
    name: address.name,
    label: t("address"),
    path: address.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: city.key,
    name: city.name,
    label: t("city"),
    path: city.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: province.key,
    name: province.name,
    label: t("province"),
    path: province.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: country.key,
    name: country.name,
    label: t("country"),
    path: country.name,
    rules: { required: true, message: t("requiredField") },
    type: selectType,
  },
  {
    key: zip.key,
    name: zip.name,
    label: t("zip"),
    path: zip.name,
    rules: { required: true, message: t("requiredField") },
  },
];
