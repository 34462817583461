import React, { useState } from "react";
import { debounceFunc } from "../../utilities/utilities";
import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";

type ButtonConfProps = {
  buttonType?: "text" | "contained" | "outlined";
  buttonLabel: string;
  buttonOnConfirm: any;
  questionLabel?: string;
  yesLabel?: string;
  noLabel?: string;
  disabled?: boolean;
};

const ButtonConfItem: React.FC<ButtonConfProps> = ({
  buttonType,
  buttonLabel,
  buttonOnConfirm,
  questionLabel,
  yesLabel,
  noLabel,
  disabled,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        variant={buttonType ?? "outlined"}
        id={buttonLabel}
        data-testid={buttonLabel}
        disabled={disabled ? disabled : undefined}
        onClick={handleOpen}
        style={{
          color: buttonType && buttonType !== "outlined" ? "white" : "#cd8133",
          border: "1px solid #cd8133",
          backgroundColor:
            buttonType && buttonType !== "outlined" ? "#cd8133" : undefined,
        }}
      >
        {buttonLabel}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {questionLabel ?? t("sureQuestion")}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={debounceFunc(() => {
              buttonOnConfirm();
              handleClose();
            })}
          >
            {yesLabel ?? t("yes")}
          </Button>
          <Button onClick={debounceFunc(handleClose)} autoFocus>
            {noLabel ?? t("no")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ButtonConfItem;
