import "./installation.css";
import "../../../App.css";
import React, { useCallback, useEffect, useState } from "react";
import { enUrl, installationListToLineChartUrl, itUrl } from "../costants";
import {
  getInstallation,
  maintenanceInstallation,
} from "../../../api/services/installationsService";
import { Installation } from "../../../api/requests/installationsService";
import AlarmSummary from "./summaryCards/AlarmSummary";
import InstallationSummary from "./summaryCards/InstallationSummary";
import RealTimeSummary from "./summaryCards/RealtimeSummary";
import TitleWithBack from "../../custom/TitleWithBack";
import ButtonConfItem from "../../custom/ButtonConfItem";
import { defaultype, primaryType } from "../../../costants/costants";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import ButtonItem from "../../custom/ButtonItem";
import { useTranslation } from "react-i18next";

type TimeSeriesProps = {
  installation_id: string;
};

const InstallationPage: React.FC<TimeSeriesProps> = ({ installation_id }) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [installation, setInstallation] = useState<Installation | null>(null);
  const [maintenanceDone, setMaintenanceDone] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [iFrameUrl, setIFrameUrl] = useState<string>("");
  const [subscriptionId, setSubscriptionId] = useState<string>("");
  useEffect(() => {
    setLoading(true);
    getInstallation({ id: installation_id }).then((res: any) => {
      if (res && res.installation) {
        setInstallation(res.installation);
        setSubscriptionId(res.installation.subscription_id);
        setMaintenanceDone(!res.installation.maintenance_notified);
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (i18n.language === "en") {
      setIFrameUrl(enUrl(installation_id));
    }
    if (i18n.language === "it") {
      setIFrameUrl(itUrl(installation_id));
    }
  }, [i18n.language]);

  const [note, setNote] = useState<string>("");
  const submit = useCallback(() => {
    maintenanceInstallation(installation_id, note).then((res) => {
      if (res && !res.err) {
        enqueueSnackbar(t("maintenanceSuccess"), {
          variant: "success",
          preventDuplicate: true,
        });
        setMaintenanceDone(!maintenanceDone);
        setVisible(false);
      } else {
        enqueueSnackbar(res?.error?.message || t("maintenanceError"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    });
  }, [note]);

  if (loading) {
    return null;
  }

  return (
    <>
      <TitleWithBack
        title={t("realTimeData")}
        key={"real_time_data"}
        link={installationListToLineChartUrl(subscriptionId)}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <InstallationSummary selected_installation={installation} />
        </Grid>
        <Grid item xs={12} md={4}>
          <RealTimeSummary selected_installation={installation} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AlarmSummary installation={installation} />
        </Grid>
      </Grid>
      {!maintenanceDone ? (
        <div className="maintenance-container">
          <ButtonConfItem
            buttonType={primaryType}
            buttonLabel={t("maintenanceDome")}
            buttonOnConfirm={() => setVisible(true)}
          />
        </div>
      ) : null}
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <DialogTitle>{t("maintenanceDevice")}</DialogTitle>
        <DialogContent>
          <TextField
            style={{ marginTop: "8px" }}
            label={t("note")}
            multiline
            rows={4}
            required
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <ButtonItem
            buttonType={defaultype}
            label={t("reset")}
            buttonOnClick={() => setVisible(false)}
          />
          <ButtonItem
            buttonType={primaryType}
            label={t("ok")}
            buttonOnClick={submit}
          />
        </DialogActions>
      </Dialog>
      <iframe
        id="grafana_iframe"
        className="responsive-iframe"
        src={iFrameUrl}
        style={{ height: "2700px" }}
      />
    </>
  );
};

export default InstallationPage;
