import { Chip } from "@mui/material";
import React from "react";
import './table/Table.css';

type CustomTagProps = {
    color: "info" | "success" | "error"
    text: string
}

const CustomTag:React.FC<CustomTagProps> = ({color, text}) => {
    return (
        <Chip className={"custom-tag"} label={text} color={color} />
    )
}

export default CustomTag;