import {
  address,
  city,
  companyAddress,
  companyCity,
  companyCountry,
  companyName,
  companyPec,
  companyPhone,
  companyProvince,
  companySdi,
  companyVat,
  companyZip,
  country,
  name,
  numberType,
  pec,
  phone,
  province,
  sdi,
  selectType,
  subscriptonName,
  vat,
  zip,
} from "../../../costants/costants";
import { InputType } from "../../types/form/types";

export const companyInfo: (t: any, countries: any[]) => InputType[] = (
  t: any,
  countries: any[]
) => [
  {
    key: subscriptonName.key,
    name: subscriptonName.name,
    label: t("name"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyName.key,
    name: companyName.name,
    path: name.name,
    label: t("company"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companySdi.key,
    name: companySdi.name,
    path: sdi.name,
    label: t("sdi"),
  },
  {
    key: companyPec.key,
    name: companyPec.name,
    label: t("pec"),
    path: pec.name,
  },
  {
    key: companyVat.key,
    name: companyVat.name,
    label: t("vat"),
    path: vat.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyPhone.key,
    name: companyPhone.name,
    label: t("phone"),
    path: phone.name,
    rules: { required: true, message: t("requiredField") },
    type: numberType,
  },
  {
    key: companyAddress.key,
    name: companyAddress.name,
    label: t("address"),
    path: address.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyCity.key,
    name: companyCity.name,
    label: t("city"),
    path: city.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyProvince.key,
    name: companyProvince.name,
    label: t("province"),
    path: province.name,
  },
  {
    key: companyCountry.key,
    name: companyCountry.name,
    label: t("country"),
    path: country.name,
    options: countries,
    rules: { required: true, message: t("requiredField") },
    type: selectType,
  },
  {
    key: companyZip.key,
    name: companyZip.name,
    label: t("zip"),
    path: zip.name,
    rules: { required: true, message: t("requiredField") },
  },
];
