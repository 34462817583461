import { useEffect, useState } from "react"
import { useHistory } from "react-router";
import { Installation } from "../../../api/requests/installationsService";
import { getInstallation, getJob } from "../../../api/services/installationsService"
import { calibrationLabel, correctExecLabel, doneStatus, executionLabel, failedLabel, failedStatus, greenColor, modificaLabel, neverDone, pendingStatus, primaryType, redColor, signalConfigurationBtnDivKey, signalConfigurationTitleKey, signalConfigurationTitleLabel, yellowColor } from "../../../costants/costants";
import { updateSignalConfigurationUrl } from "../costants";
import ButtonItem from "../../custom/ButtonItem";
import TitleWithBack from "../../custom/TitleWithBack";
import { signalConfigurationTitle } from "../title"
import { Chip, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";

type SignalConfigurationTableProps = {
    installation_id: string
}

const SignalConfigurationTable: React.FC<SignalConfigurationTableProps> = ({ installation_id }) => {
    const {t} = useTranslation()
    const history = useHistory()

    const [width, setWidth] = useState<number>(window.innerWidth)
    const [height, setHeight] = useState<number>(window.innerHeight)
	function handleWindowSizeChange() {
		setWidth(window.innerWidth)
        setHeight(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])

    const [loading, setLoading] = useState<boolean>(true)
    const [initialJob, setInitialJob] = useState({
        config: {},              
        installation_id: "",
        last_set_status: neverDone
    })

    const [installation, setInstallation] = useState<Installation | null>(null)
    useEffect(() => {
        getJob(installation_id, calibrationLabel).then((res: any) => {
            if(res && res.calibration){
                setInitialJob(res.calibration)    
            }
            getInstallation({id: installation_id}).then((res: any) => {
                if (res && res.installation) {
                    setInstallation(res.installation)
                }
                setLoading(false)
            })
        })
    }, [installation_id])

    const checkBadgeColor = (last_set_status: string) => {
        switch (last_set_status) {
            case doneStatus:
                return "success"
            case pendingStatus:
                return "warning"
            case failedStatus:
                return "error"
            default:
                return "info"
        }
    }

    const checkLastStatus = (last_set_status: string) => {
        switch (last_set_status) {
            case doneStatus:
                return t("executedCorrectly")
            case pendingStatus:
                return t("running")
            case failedStatus:
                return t("failed")
            default:
                return t("neverDone")
        }
    }

    if(loading){
        return null
    }

    return(
        <div style={{marginBottom: "20%"}}>
            <TitleWithBack title={t("signalConfiguration")} key={signalConfigurationTitleKey} />
            <TableContainer>
                <Table size="small" style={{marginLeft: (width <= 575 || height <= 815) ? "1%" : "20%", marginRight: (width <= 575 || height <= 815) ? "1%" : "20%"}}>
                    <TableBody>
                        <TableRow>
                            <Chip 
                                color={checkBadgeColor(initialJob.last_set_status)} 
                                label={checkLastStatus(initialJob.last_set_status)} 
                            />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="btn-container" key={signalConfigurationBtnDivKey}>
                <ButtonItem buttonType={primaryType} label={t("change")} buttonOnClick={() => {history.push(updateSignalConfigurationUrl(installation_id))}} />
            </div>
        </div>
    )
}

export default SignalConfigurationTable;