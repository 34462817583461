import "./DataHistoryPanel.css";
import React, { useCallback, useEffect, useState } from "react";
import { Installation } from "../../../api/requests/installationsService";
import ButtonConfItem from "../../custom/ButtonConfItem";
import ButtonItem from "../../custom/ButtonItem";
import { getInstallation } from "../../../api/services/installationsService";
import moment from "moment";
import { GetExportInfoRequest } from "../../../api/requests/timeSeries";
import { getExportInfo } from "../../../api/services/timeSeries";
import {
  dataHistoryTitleKey,
  downloadError,
  primaryType,
} from "../../../costants/costants";
import TitleWithBack from "../../custom/TitleWithBack";
import InstallationSummary from "../../summary/installationSummary/InstallationSummary";
import { useSnackbar } from "notistack";
import { Card, CardContent, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

type DataHistoryPanelProps = {
  installation_id: string;
};

const DataHistoryPanel: React.FC<DataHistoryPanelProps> = ({
  installation_id,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  useEffect(() => {
    getInstallation({ id: installation_id }).then((res: any) => {
      if (res && res.installation) {
        setSelectedInstallation(res.installation);
      }
    });
  }, []);

  const downloadFile = useCallback((url: string) => {
    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = url;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }, []);

  const dateFormatter = (date: Date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const [values, setValues] = useState({ start_date: "", end_date: "" });
  const handleSubmit = useCallback(() => {
    const first = new Date(values.start_date);
    const second = new Date(values.end_date);
    let differenceInTime = second.getTime() - first.getTime();
    let differenceInDays = differenceInTime / (1000 * 3600 * 24);
    if (differenceInDays <= 6) {
      const info: GetExportInfoRequest = {
        tag: "data",
        start: dateFormatter(new Date(values.start_date)),
        end: dateFormatter(new Date(values.end_date)),
        entity_id: installation_id,
      };
      getExportInfo(info)
        .then((resp) => {
          if (resp && resp.url) {
            downloadFile(resp.url);
          } else if (resp && resp.err) {
            enqueueSnackbar(resp?.error?.message || downloadError, {
              variant: "error",
              preventDuplicate: true,
            });
          }
        })
        .catch((e) => console.log("error: ", e));
    } else {
      enqueueSnackbar(t("sevenDaysError"), {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [values]);

  if (!selectedInstallation) {
    return null;
  }

  return (
    <>
      <TitleWithBack title={t("dataHistory")} key={dataHistoryTitleKey} />
      <InstallationSummary selected_installation={selectedInstallation} />
      <Card
        variant="outlined"
        style={{
          marginLeft: width <= 575 || height <= 815 ? "2%" : "25%",
          marginRight: width <= 575 || height <= 815 ? "2%" : "25%",
        }}
      >
        <CardContent>
          <Grid container spacing={4} justifyContent="space-around">
            <Grid item>
              <TextField
                size="small"
                style={{ marginTop: "4%" }}
                label={t("startDate")}
                type="date"
                value={moment(values.start_date).format("YYYY-MM-DD")}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e: any) => {
                  setValues({ ...values, start_date: e.target.value });
                }}
                inputProps={{
                  "&::-webkit-calendar-picker-indicator": {
                    display: "none",
                    "-webkit-appearance": "none",
                  },
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                size="small"
                style={{ marginTop: "4%" }}
                label={t("endDate")}
                type="date"
                value={moment(values.end_date).format("YYYY-MM-DD")}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e: any) => {
                  setValues({ ...values, end_date: e.target.value });
                }}
              />
            </Grid>
          </Grid>
          <div className="btn-container">
            <ButtonConfItem
              buttonLabel={t("reset")}
              buttonOnConfirm={() => {
                setValues({ start_date: "", end_date: "" });
              }}
              questionLabel={t("question")}
            />
            <ButtonItem
              buttonType={primaryType}
              label={t("ok")}
              buttonOnClick={handleSubmit}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default DataHistoryPanel;
