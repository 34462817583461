import React from "react";
import { debounceFunc } from "../../utilities/utilities";
import { Button } from "@mui/material";

type ButtonProps = {
  buttonType?: "text" | "contained" | "outlined";
  label: string;
  buttonOnClick: any;
  disabled?: boolean;
};

const ButtonItem: React.FC<ButtonProps> = ({
  buttonType,
  label,
  buttonOnClick,
  disabled,
}) => {
  const styles = {
    "&.MuiButton-root": {
      border: "2px #cd8133 solid",
    },
    "&.MuiButton-outlined": {
      color: "#cd8133",
    },
    "&.MuiButton-contained": {
      color: "white",
    },
  };

  return (
    <Button
      itemType="submit"
      variant={buttonType ?? "outlined"}
      id={label}
      data-testid={label}
      disabled={disabled ? disabled : undefined}
      onClick={debounceFunc(buttonOnClick)}
      type="submit"
      style={{
        backgroundColor: buttonType !== "outlined" ? "#cd8133" : undefined,
      }}
      sx={styles}
    >
      {label}
    </Button>
  );
};

export default ButtonItem;
