import "./AddSubscription.css";
import "../../../App.css";
import ButtonItem from "../../custom/ButtonItem";
import ButtonConfItem from "../../custom/ButtonConfItem";
import React, { useCallback, useEffect, useState } from "react";
import { GetCustomers, GetDealers } from "../../../api/services/userService";
import { addSubscription } from "../../../api/services/subscriptionService";
import { CreateSubscriptionRequest } from "../../../api/requests/subscription";
import CustomTitle from "../../custom/CustomTitle";
import FormInput from "../../custom/input/FormInput";
import { companyInfo } from "./inputs";
import FormSelect from "../../custom/select/FormSelect";
import { engCountries, itaCountries } from "../../custom/select/countries";
import OperationResult from "../../custom/OperationResult";
import {
  adminRole,
  dealerRole,
  errorStatus,
  formInputKey,
  installerRole,
  invioLabel,
  primaryType,
  resetLabel,
  selectType,
  textType,
} from "../../../costants/costants";
import { Card, CardContent, Grid } from "@mui/material";
import { checkValues } from "../../../utilities/utilities";
import { useTranslation } from "react-i18next";

type AddSubscriptionProps = {
  role: string;
  email: string;
  uid: string;
};

const AddSubscription: React.FC<AddSubscriptionProps> = ({
  role,
  email,
  uid,
}) => {
  const { t, i18n } = useTranslation();

  const [usrOpt, setUsrOpt] = useState<any[]>([]);
  const [dealerOpt, setDealerOpt] = useState<any[]>([]);
  const [status, setStatus] = useState<string>("");

  const goBack = () => setStatus("");

  useEffect(() => {
    GetCustomers("-1", "", "").then((res: any) => {
      let tmp: any[] = [];
      if (res && res.customers) {
        res.customers.map((el: any) => {
          tmp = [
            ...tmp,
            {
              [el.uid]: el.email,
            },
          ];
        });
        setUsrOpt([...tmp]);
      }
    });
  }, []);

  useEffect(() => {
    if (role === adminRole || role === installerRole) {
      GetDealers("-1", "", "").then((res: any) => {
        let tmp: any[] = [];
        if (res && res.dealers) {
          res.dealers.map((el: any) => {
            tmp = [
              ...tmp,
              {
                [el.uid]: el.email,
              },
            ];
          });
          setDealerOpt([...tmp]);
        }
      });
    }
  }, []);

  const [options, setOptions] = useState<any[]>([]);
  useEffect(() => {
    setOptions([...usrOpt, ...dealerOpt]);
  }, [usrOpt, dealerOpt]);

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [error, setError] = useState<boolean>(false);
  const [values, setValues] = useState<any>({
    user_panel: "",
    subscription_name: "",
    company_name: "",
    company_vat_number: "",
    company_phone: "",
    company_address: "",
    company_city: "",
    company_province: "",
    company_country: "",
    company_zip_code: "",
  });

  const handleSubmit = useCallback(() => {
    if (checkValues(values)) {
      const req: CreateSubscriptionRequest = {
        customer_id: values.user_panel,
        name: values.subscription_name,
        company_info: {
          name: values.company_name,
          sdi: (values?.company_sdi as any) || "",
          pec: (values?.company_pec as any) || "",
          vat_number: values.company_vat_number,
          phone: values.company_phone,
          address: values.company_address,
          city: values.company_city,
          province: values.company_province,
          country: values.company_country,
          zip_code: values.company_zip_code,
        },
      };
      addSubscription(req).then((res: any) => {
        if (res && !res.err) {
          setStatus("success");
        } else {
          setStatus(res?.err?.message || "");
        }
      });
    } else {
      setError(true);
    }
  }, [values]);

  if (status) {
    return (
      <OperationResult
        onClickProp={status === errorStatus ? goBack : undefined}
        status={status === "success" ? "success" : "error"}
        operation={"create"}
        entity={"subscription"}
        error={status}
      />
    );
  }

  return (
    <>
      <CustomTitle title={t("createFleet")} />
      <Card
        variant="outlined"
        style={{
          marginLeft: width <= 575 || height <= 815 ? "2%" : "25%",
          marginRight: width <= 575 || height <= 815 ? "2%" : "25%",
        }}
      >
        <CardContent>
          <Grid container spacing={2} key="sub-grid">
            {companyInfo(
              t,
              options,
              i18n.language === "en" ? engCountries : itaCountries
            ).map((el) => {
              return (
                <Grid item xs={12} md={4}>
                  {el.type !== selectType ? (
                    <FormInput
                      error={error}
                      key={formInputKey(el.key)}
                      keyValue={el.key}
                      name={el.name}
                      rules={el.rules}
                      placeholder={el.label}
                      type={el.type ?? textType}
                      value={(values as any)[el.name]}
                      onchange={(e: any) => {
                        setValues({ ...values, [el.name]: e.target.value });
                      }}
                    />
                  ) : (
                    <FormSelect
                      error={error}
                      key={formInputKey(el.key)}
                      placeholder={el.label}
                      keyValue={el.key}
                      rules={el.rules}
                      name={el.name}
                      options={el.options || []}
                      value={(values as any)[el.name]}
                      onChange={(e: any) => {
                        setValues({ ...values, [el.name]: e.target.value });
                      }}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
          <div className="btn-container">
            <ButtonConfItem
              buttonLabel={resetLabel}
              buttonOnConfirm={() =>
                setValues({
                  user_panel: "",
                  subscription_name: "",
                  company_name: "",
                  company_vat_number: "",
                  company_phone: "",
                  company_address: "",
                  company_city: "",
                  company_province: "",
                  company_country: "",
                  company_zip_code: "",
                })
              }
              questionLabel={t("question")}
            />
            <ButtonItem
              buttonType={primaryType}
              label={invioLabel}
              buttonOnClick={handleSubmit}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default AddSubscription;
