import React, { useEffect, useState } from "react";
import { GetUserProfile } from "../../../api/services/userService";
import { UserType } from "../../types/profile/types";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "./Profile.css";
import { roles } from "../costants";
import SubscriptionManagement from "../subscriptionSManagement/SubscriptionManagement";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Chip,
  CardHeader,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type ProfileProps = {
  SetPopupObject: (objects: Object[]) => void;
};

const Profile: React.FC<ProfileProps> = ({ SetPopupObject }) => {
  const { t } = useTranslation();

  const [profileItem, setProfileItem] = useState<UserType | null>(null);

  const history = useHistory();
  useEffect(() => {
    GetUserProfile().then((res: any) => {
      if (res && res.user && res.user.roles && res.user.roles.length > 0) {
        setProfileItem({
          ...profileItem,
          uid: res.user.uid,
          name: res.user.name,
          email: res.user.email,
          role: res.user.roles[0],
          user_info: {
            phone: res.user.user_info.phone,
            address: res.user.user_info.address,
            city: res.user.user_info.city,
            province: res.user.user_info.province,
            country: res.user.user_info.country,
            zip_code: res.user.user_info.zip_code,
          },
          created_at: res.user.created_at,
        });
      } else {
        history.push("/");
      }
    });
  }, []);

  const dateFormatter = (date: string) => {
    return moment(date).format("DD/MM/YYYY HH:mm");
  };

  if (profileItem) {
    return (
      <>
        <div className={"profileDescriptionContainer"}>
          <Card
            variant="outlined"
            className={"profileDescription profileDescriptionResponsive"}
          >
            <CardHeader title={t("userData")} />
            <CardContent>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow key="name">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("name")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {profileItem.name}
                      </TableCell>
                    </TableRow>
                    <TableRow key="email">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("email")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {profileItem.email}
                      </TableCell>
                    </TableRow>
                    <TableRow key="role">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("role")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        <Chip label={profileItem.role} color="info" />
                      </TableCell>
                    </TableRow>
                    <TableRow key="phone">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("phone")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {profileItem.user_info.phone}
                      </TableCell>
                    </TableRow>
                    <TableRow key="address">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("address")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {profileItem.user_info.address}
                      </TableCell>
                    </TableRow>
                    <TableRow key="city">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("city")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {profileItem.user_info.city}
                      </TableCell>
                    </TableRow>
                    <TableRow key="province">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("province")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {profileItem.user_info.province}
                      </TableCell>
                    </TableRow>
                    <TableRow key="country">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("country")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {profileItem.user_info.country}
                      </TableCell>
                    </TableRow>
                    <TableRow key="zip_code">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("zip")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {profileItem.user_info.zip_code}
                      </TableCell>
                    </TableRow>
                    <TableRow key="createdat">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("creationDate")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {dateFormatter(profileItem.created_at)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </div>
        {profileItem.role === roles.customer ? (
          <SubscriptionManagement SetPopupObject={SetPopupObject} />
        ) : null}
      </>
    );
  } else return null;
};

export default Profile;
