import React, { useEffect, useState } from "react";
import "../../navbar/Navbar.css";
import "../../../App.css";
import { Subscription } from "../../../api/requests/subscription";
import {
  getSubscription,
  getSubscriptionInstallations,
} from "../../../api/services/subscriptionService";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { useHistory } from "react-router";
import { installationsKey, name } from "../../../costants/costants";
import { installationsListColumns } from "./inputs";
import TitleWithBack from "../../custom/TitleWithBack";
import SubscriptionSummary from "../../summary/subscriptionSummary/SubscriptionSummary";
import { useTranslation } from "react-i18next";

type InstallationsListProps = {
  SetPopupObject: (obj: any) => void;
  subscription_id: string;
  redirectUrl: (id: string) => string;
};

const InstallationsListSubscriptionSummary: React.FC<
  InstallationsListProps
> = ({ SetPopupObject, subscription_id, redirectUrl }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription | null>(null);
  const [data, setData] = useState<Object[]>([]);
  useEffect(() => {
    getSubscription({ subscription_id }).then((res: any) => {
      if (res && res.subscription) {
        setSelectedSubscription(res.subscription);
      }
    });
  }, []);

  const redirectFun: (value: string) => void = (value: string) => {
    history.push(redirectUrl(value));
  };

  return (
    <>
      <TitleWithBack title={t("devicesList")} />
      <SubscriptionSummary subscription={selectedSubscription} />
      <ResponsiveTable
        columns={installationsListColumns(
          t,
          data,
          SetPopupObject,
          redirectFun,
          false
        )}
        getPageAndSortedData={getSubscriptionInstallations}
        defaultSort={name.name}
        responseDataName={installationsKey}
        id={subscription_id}
        setData={setData}
      />
    </>
  );
};

export default InstallationsListSubscriptionSummary;
