import { InputType } from "../../types/form/types";
import { email } from "../../../costants/costants";
import { Person } from "@mui/icons-material";

export const inputs: (t: any) => InputType[] = (t: any) => [
  {
    key: email.key,
    name: email.name,
    label: t("email"),
    type: email.name,
    rules: { required: true, message: t("requiredField") },
    icon: <Person className="site-form-item-icon" />,
  },
];
