import "../../../App.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import {
  dateType,
  iconType,
  linkType,
  stringType,
  tagType,
} from "../../../utilities/utilities";
import {
  company,
  createdAt,
  customer,
  model,
  onlineKey,
  status,
} from "../../../costants/costants";
import { IconButton, Tooltip } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import IconConfItem from "../../custom/IconConfItem";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DeleteInstallation } from "../../../api/services/installationsService";

export const installationsListColumns: (
  t: any,
  rowData: any,
  SetPopupObject: (obj: any) => void,
  linkFunction: (value: string) => void,
  admin: boolean
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  linkFunction,
  admin
) => [
  {
    label: t("activeBoxSn"),
    name: "name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="details-container">
          <Tooltip title={t("details")}>
            <IconButton
              onClick={() => {
                const tmp: any = rowData[data.rowIndex];
                const objects: any[] = [
                  {
                    active_box_model: tmp.model,
                    ...tmp.installation_info,
                    active_box_sn: tmp.name,
                    created_at: tmp.created_at,
                    status: tmp.status,
                    last_connection: tmp.last_connection,
                    last_disconnection: tmp.last_disconnection,
                  },
                ];
                SetPopupObject(objects);
              }}
            >
              <InfoOutlinedIcon className="details-icon" />
            </IconButton>
          </Tooltip>
          <div className="details">
            <ResponsiveTableCell
              type={linkType}
              value={value}
              link={() => linkFunction(data.tableData[data.rowIndex].id)}
            />
          </div>
        </div>
      ),
    },
  },
  {
    label: t("activationCode"),
    name: "mate_id",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("activeBoxModel"),
    name: model.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("mateSn"),
    name: "installation_info.mate_sn",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("creationDate"),
    name: createdAt.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={dateType} value={value} />
      ),
    },
  },
  {
    label: t("deviceStatus"),
    name: status.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell
          type={tagType}
          value={value}
          color={value === onlineKey ? "success" : "error"}
        />
      ),
    },
  },
];

export const updateInstallationTableColumns: (
  t: any,
  rowData: any,
  SetPopupObject: (obj: any) => void,
  linkFunction: (value: string) => void,
  linkPrintFunction: (value: string) => void,
  admin: boolean,
  enqueueSnackbar: any
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  linkFunction,
  linkPrintFunction,
  admin,
  enqueueSnackbar
) => [
  ...installationsListColumns(t, rowData, SetPopupObject, linkFunction, admin),
  {
    label: t("company"),
    name: company.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("customer"),
    name: customer.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("print"),
    name: "id",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <IconButton onClick={() => linkPrintFunction(value)}>
          <PrintIcon />
        </IconButton>
      ),
    },
  },
  ...adminColumn(admin, t, enqueueSnackbar),
];

const adminColumn = (admin: boolean, t: any, enqueueSnackbar: any) => {
  if (admin) {
    return [
      {
        label: t("delete"),
        name: "id",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: string) => (
            <ResponsiveTableCell
              type={iconType}
              value=""
              icon={
                <IconConfItem
                  icon={<DeleteOutlineOutlinedIcon />}
                  questionLabel={t("deleteInstallationQuestion")}
                  buttonOnConfirm={() => {
                    DeleteInstallation(value).then((res: any) => {
                      if (res && !res.err) {
                        enqueueSnackbar(t("installationDeleteSuccess"), {
                          variant: "success",
                          preventDuplicate: true,
                        });
                        window.location.reload();
                      } else {
                        enqueueSnackbar(
                          res?.err?.message || t("somethingWentWrong"),
                          {
                            variant: "error",
                            preventDuplicate: true,
                          }
                        );
                      }
                    });
                  }}
                />
              }
            />
          ),
        },
      },
    ];
  } else {
    return [];
  }
};
