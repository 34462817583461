import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import { UserRelation } from "../../../api/requests/userService";
import "../../../App.css";
import { stringType } from "../../../utilities/utilities";
import {
  customerEmailRel,
  customerNameRel,
  installerEmailRel,
  installerNameRel,
} from "../../../costants/costants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Tooltip } from "@mui/material";

export const installersListColumns: (
  t: any,
  rowData: Object[],
  SetPopupObject: (obj: any) => void,
  deleteRelation: (record: UserRelation) => void
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  deleteRelation
) => [
  {
    label: t("installerName"),
    name: installerNameRel.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="details-container">
          <Tooltip title={t("details")}>
            <IconButton
              onClick={() => {
                SetPopupObject([rowData[data.rowIndex]]);
              }}
            >
              <InfoOutlinedIcon className="details-icon" />
            </IconButton>
          </Tooltip>
          <div className="details">
            <ResponsiveTableCell type={stringType} value={value} />
          </div>
        </div>
      ),
    },
  },
  {
    label: t("installerEmail"),
    name: installerEmailRel.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("customerName"),
    name: customerNameRel.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("customerEmail"),
    name: customerEmailRel.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
];
