import {
  duration,
  finish,
  installationName,
  level,
  linkType,
  start,
  tag,
} from "../../../costants/costants";
import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import { dateTimeType, stringType } from "../../../utilities/utilities";
import "../../../App.css";
import { Alert } from "@mui/material";

const diffDateAndToString = (small: Date, big: Date) => {
  const Difference_In_Time = big.getTime() - small.getTime();
  const Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
  const Hours = Math.floor(Difference_In_Time / (60 * 1000) / 60 - 24 * Days);
  const Mins = Math.floor(
    Difference_In_Time / (60 * 1000) - 1440 * Days - 60 * Hours
  );
  const diffDate = new Date(Difference_In_Time);
  var result = "";
  if (Mins < 60) {
    result = Mins + "m";
  } else {
    if (Hours < 24) {
      result = diffDate.getMinutes() + "h";
    } else {
      result = Days + "d";
    }
  }
  return { result, Days, Mins, Hours };
};

export const alarmColumns: (
  t: any,
  linkFunction: (link: string) => void
) => ResponsiveTableColumnType[] = (t, linkFunction) => [
  {
    label: "",
    name: "installation_id",
    options: {
      sort: false,
      filter: true,
      display: false,
      customBodyRender: (value) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("device"),
    name: installationName.name,
    options: {
      sort: false,
      filter: true,
      customBodyRender: (value, data) => (
        <ResponsiveTableCell
          type={linkType}
          value={value}
          link={() => linkFunction(data.rowData[0])}
        />
      ),
    },
  },
  {
    label: t("start"),
    name: start.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: any) => (
        <ResponsiveTableCell type={dateTimeType} value={value} />
      ),
    },
  },
  {
    label: t("finish"),
    name: finish.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value) => (
        <ResponsiveTableCell
          type={value !== "0001-01-01T00:00:00Z" ? dateTimeType : stringType}
          value={value !== "0001-01-01T00:00:00Z" ? value : ""}
        />
      ),
    },
  },
  {
    label: t("duration"),
    name: duration.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value, data) => {
        const start = new Date(
          String(data.tableData[data.rowIndex][2] as string)
        );
        const finish =
          String(data.tableData[data.rowIndex][3]) !== "0001-01-01T00:00:00Z"
            ? new Date(String(data.tableData[data.rowIndex][3] as string))
            : new Date();
        const difference = diffDateAndToString(start, finish);
        return (
          <ResponsiveTableCell
            type={stringType}
            value={`${difference.Days} days ${difference.Hours} hours ${difference.Mins} mins`}
          />
        );
      },
    },
  },
  {
    label: t("alarm"),
    name: tag.name,
    options: {
      sort: false,
      filter: true,
      customBodyRender: (value) => {
        let alarm: string = value;
        if (alarm.toLocaleLowerCase().includes("batteria")) {
          alarm = t("battery");
        }
        if (alarm.toLocaleLowerCase().includes("primo tagliando")) {
          alarm = t("firstService");
        }
        if (alarm.toLocaleLowerCase().includes("temperatura alta")) {
          alarm = t("highTemperature");
        }
        if (alarm.toLocaleLowerCase().includes("temperatura bassa")) {
          alarm = t("lowTemperature");
        }
        return <ResponsiveTableCell type={stringType} value={alarm} />;
      },
    },
  },
  {
    label: t("level"),
    name: level.name,
    options: {
      sort: false,
      filter: true,
      customBodyRender: (value) => (
        <Alert color="error" variant="filled" severity="warning">
          {value}
        </Alert>
      ),
    },
  },
];
