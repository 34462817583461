import "./DeviceConnection.css";
import React, { useEffect, useState } from "react";
import {
  CreateIdentity,
  DeleteIdentity,
  GetIdentity,
  getInstallation,
  getZdmDeviceFromIdentity,
  saveLogs,
} from "../../../api/services/installationsService";
import { connectionTitle } from "../title";
import { BackendUrl } from "../../../api/constants";
import { receiveChannels, resultTitle, sendChannels } from "./costant";
import { Alert, AlertTitle, LinearProgress, Typography } from "@mui/material";
import {
  dccTitleKey,
  remainingTimeLabel,
  waitHereLabel,
  waitLabel,
} from "../../../costants/costants";
import TitleWithBack from "../../custom/TitleWithBack";
import { Installation } from "../../../api/requests/installationsService";
import ConfirmOperation from "./ConfirmOperation";
import { useTranslation } from "react-i18next";

type ResultLog = {
  command: string;
  stdOutput: any[];
  stdError: any[];
  result: any;
};

type DeviceConnectionConfigurationProps = {
  installation_id: string;
  email: string;
};

const DeviceConnectionConfiguration: React.FC<
  DeviceConnectionConfigurationProps
> = ({ installation_id, email }) => {
  const { t } = useTranslation();

  const [confirmOptions, setConfirmOptions] = useState({
    open: false,
    question: "",
    onCancel: () => {
      setConfirmOptions({ ...confirmOptions, open: false });
    },
    onConfirm: () => {},
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  useEffect(() => {
    getInstallation({ id: installation_id }).then((res: any) => {
      if (res && res.installation) {
        setSelectedInstallation(res.installation);
      }
      setLoading(false);
    });
  }, []);

  const totalEstimatedSeconds = 106;
  const twentyPercentRemainingSeconds = 103;
  const thirtyPercentRemainingSeconds = 55;
  const fortyPercentRemainingSeconds = 54;
  const sixtyPercentRemainingSeconds = 37;
  const seventyPercentRemainingSeconds = 37;
  const seventyfivePercentRemainingSeconds = 36;
  const eightyPercentRemainingSeconds = 36;
  const hundredPercentRemainingSeconds = 0;

  const [result, setResult] = useState<boolean | null>(null);
  const [progress, setProgress] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);
  const [checkDevice, setCheckDevice] = useState<boolean>(true);
  const [remainingSeconds, setRemainingSeconds] = useState<number>(
    totalEstimatedSeconds
  );
  const [interval, setIntervalId] = useState<any>();

  let board: string = "";
  let uid: string = "";
  let phys_id: string = "";
  let path: string = "";

  useEffect(() => {
    saveLogs(installation_id, [
      {
        action: "loading_production_firmware",
        status: "finished",
        result: "success",
        request: "loading PRODUCTION firmware",
        msg: "OK firmware loading started successfully",
        date: new Date().toISOString(),
        user: email,
      },
    ]).then((res) => {
      if (res && !res.err) {
        console.log("LOGS SAVED CORRECTLY");
      } else {
        console.log(res?.err?.message || "ERROR SAVING LOGS");
      }
    });
  }, []);

  useEffect(() => {
    if (result === true) {
      saveLogs(installation_id, [
        {
          action: "loading_production_firmware",
          status: "finished",
          result: "success",
          request: "loading PRODUCTION firmware",
          msg: "OK firmware loaded successfully",
          date: new Date().toISOString(),
          user: email,
        },
      ]).then((res) => {
        if (res && !res.err) {
          console.log("LOGS SAVED CORRECTLY");
        } else {
          console.log(res?.err?.message || "ERROR SAVING LOGS");
        }
      });
    }
    if (result === false) {
      saveLogs(installation_id, [
        {
          action: "loading_production_firmware",
          status: "finished",
          result: "success",
          request: "loading PRODUCTION firmware",
          msg: "ERROR exception loading",
          date: new Date().toISOString(),
          user: email,
        },
      ]).then((res) => {
        if (res && !res.err) {
          console.log("LOGS SAVED CORRECTLY");
        } else {
          console.log(res?.err?.message || "ERROR SAVING LOGS");
        }
      });
    }
  }, [result]);

  const connect: () => void = () => {
    setProgress(true);
    let userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(" electron/") > -1) {
      // Electron-specific code
      window.api.send(
        sendChannels.firmware_download,
        `${BackendUrl}/firmwares/latest?model=${
          selectedInstallation?.model.toLocaleLowerCase().includes("lh")
            ? "lh"
            : "rh"
        }`
      );
    }
  };

  const secondsToTimer = (r: number) => {
    let minutes,
      seconds = "";

    let m = Math.floor((r % 3600) / 60);
    let s = Math.floor((r % 3600) % 60);

    if (m < 10) {
      minutes = "0" + m;
    } else {
      minutes = m.toString();
    }
    if (s < 10) {
      seconds = "0" + s;
    } else {
      seconds = s.toString();
    }
    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (progress) {
      intervalId = setInterval(() => {
        setRemainingSeconds(remainingSeconds - 1);
        if (remainingSeconds <= 0) {
          setRemainingSeconds(0);
          clearInterval(intervalId);
        }
        if (percent >= 100) {
          setRemainingSeconds(0);
          clearInterval(intervalId);
        }
      }, 1000);
      setIntervalId(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [remainingSeconds, progress, percent]);

  const firmware_download = (result: string) => {
    console.log(sendChannels.firmware_download);
    if (result !== "") {
      path = result;
      window.api.send(sendChannels.ztc_discover, "");
    } else {
      setResult(false);
      clearInterval(interval);
    }
  };

  const ztc_discover = (result: ResultLog) => {
    console.log("DISCOVER: ", result);
    const devices: any[] = result.result;
    board = "";
    uid = "";
    if (devices.length > 0) {
      let tmp_board: string | null = null;
      let tmp_uid: string | null = null;
      devices.map((el) => {
        if (!board && !uid) {
          tmp_board = el.board;
          tmp_uid = el.uid;
        }
      });
      if (!tmp_board || !tmp_uid) {
        setResult(false);
        clearInterval(interval);
      } else {
        board = tmp_board;
        uid = tmp_uid;
        setPercent(20);
        if (remainingSeconds !== twentyPercentRemainingSeconds) {
          setRemainingSeconds(twentyPercentRemainingSeconds);
        }
        window.api.send(sendChannels.ztc_erase, [board, uid]);
      }
    } else {
      setResult(false);
      clearInterval(interval);
    }
  };

  const ztc_erase = (result: ResultLog) => {
    console.log("ERASE: ", result);
    if (result.result) {
      setPercent(30);
      if (remainingSeconds !== thirtyPercentRemainingSeconds) {
        setRemainingSeconds(thirtyPercentRemainingSeconds);
      }
      window.api.send(sendChannels.ztc_phys_id, uid);
    } else {
      setResult(false);
      clearInterval(interval);
    }
  };

  const ztc_phys_id = async (result: ResultLog) => {
    console.log("GETTING PHYSID: ", result);
    await window.api.send(sendChannels.ztc_check_discover, [board, uid]);
    if (checkDevice) {
      if (board === "4zerobox_v9") {
        window.api.send(sendChannels.ztc_provision_prepare, [
          `${BackendUrl}/firmwares/provisioning`,
          board,
          uid,
        ]);
      } else {
        phys_id = String(result.result);
        window.api.send(sendChannels.ztc_provision_prepare, [
          `${BackendUrl}/firmwares/provisioning`,
          board,
          phys_id,
        ]);
      }
      setPercent(40);
      if (remainingSeconds !== fortyPercentRemainingSeconds) {
        setRemainingSeconds(fortyPercentRemainingSeconds);
      }
    } else {
      setResult(false);
      clearInterval(interval);
    }
  };

  const ztc_provision_prepare = async (result: ResultLog) => {
    console.log("PROVISION PREPARE: ", result);
    await window.api.send(sendChannels.ztc_check_discover, [board, uid]);
    if (checkDevice) {
      if (Boolean(result.result)) {
        setPercent(60);
        if (remainingSeconds !== sixtyPercentRemainingSeconds) {
          setRemainingSeconds(sixtyPercentRemainingSeconds);
        }
        if (board === "4zerobox_v9") {
          window.api.send(sendChannels.ztc_provision_command, uid);
        } else {
          window.api.send(sendChannels.ztc_provision_command, phys_id);
        }
      } else {
        setResult(false);
        clearInterval(interval);
      }
    } else {
      setResult(false);
      clearInterval(interval);
    }
  };

  const checkDcn = (error: string) => {
    const regex = /Physical board is already associated to (\w+)/;
    const match = error.match(regex);
    if (match && match.length > 1) {
      return match[1];
    } else {
      return "";
    }
  };

  const ztc_provision_command = async (result: ResultLog) => {
    console.log("PROVISION COMMAND: ", result);
    const bundle: string = String(result.result);
    await window.api.send(sendChannels.ztc_check_discover, [board, uid]);
    if (checkDevice) {
      setPercent(70);
      if (remainingSeconds !== seventyPercentRemainingSeconds) {
        setRemainingSeconds(seventyPercentRemainingSeconds);
      }
      GetIdentity(installation_id).then((res) => {
        if (res && !res.err) {
          if (res.identities) {
            //sullo ZDM c'è già una identity
            let old_dcn = res.identities[0]?.dcn;
            let new_dcn = Buffer.from(bundle.split(":")[0], "base64").toString(
              "binary"
            );
            if (new_dcn.includes(old_dcn)) {
              console.log("aggiornamento firmware");
              //non si vuole sostituire il device fisico ma solo aggiornare il firmware
              setPercent(80);
              if (remainingSeconds !== eightyPercentRemainingSeconds) {
                setRemainingSeconds(eightyPercentRemainingSeconds);
              }
              setConfirmOptions({
                open: true,
                question: t("updateFwQuestion"),
                onCancel: () => {
                  setConfirmOptions({ ...confirmOptions, open: false });
                  setResult(false);
                },
                onConfirm: () => {
                  setConfirmOptions({ ...confirmOptions, open: false });
                  setPercent(80);
                  window.api.send(sendChannels.ztc_burn, [board, uid, path]);
                },
              });
            } else {
              console.log("sostituzione device fisico");
              setConfirmOptions({
                open: true,
                question: t("deviceReplacementQuestion"),
                onCancel: () => {
                  setConfirmOptions({ ...confirmOptions, open: false });
                  setResult(false);
                },
                onConfirm: () => {
                  setConfirmOptions({ ...confirmOptions, open: false });
                  //si vuole sostituire il device fisico
                  DeleteIdentity({
                    installation_id: installation_id,
                    dcn: old_dcn,
                  }).then((res) => {
                    if (res && !res.err) {
                      setPercent(75);
                      if (
                        remainingSeconds !== seventyfivePercentRemainingSeconds
                      ) {
                        setRemainingSeconds(seventyfivePercentRemainingSeconds);
                      }
                      CreateIdentity(
                        { phys_id: phys_id, bundle: bundle },
                        installation_id
                      ).then((res: any) => {
                        if (res && !res.err) {
                          setPercent(80);
                          if (
                            remainingSeconds !== eightyPercentRemainingSeconds
                          ) {
                            setRemainingSeconds(eightyPercentRemainingSeconds);
                          }
                          window.api.send(sendChannels.ztc_burn, [
                            board,
                            uid,
                            path,
                          ]);
                        } else {
                          setConfirmOptions({
                            open: true,
                            question:
                              res?.err?.message || t("deviceIdentityError"),
                            onCancel: () => {
                              setConfirmOptions({
                                ...confirmOptions,
                                open: false,
                              });
                              setResult(false);
                              clearInterval(interval);
                            },
                            onConfirm: () => {
                              setConfirmOptions({
                                ...confirmOptions,
                                open: false,
                              });
                              setResult(false);
                              clearInterval(interval);
                            },
                          });
                          setResult(false);
                          clearInterval(interval);
                        }
                      });
                    } else {
                      setResult(false);
                      clearInterval(interval);
                    }
                  });
                },
              });
            }
          } else {
            console.log("primo collegamento");
            //sullo ZDM non c'è nessuna identity quindi è il primo collegamento
            CreateIdentity(
              { phys_id: phys_id, bundle: String(result.result) },
              installation_id
            ).then((res: any) => {
              if (res && !res.err && path !== "") {
                setPercent(80);
                if (remainingSeconds !== eightyPercentRemainingSeconds) {
                  setRemainingSeconds(eightyPercentRemainingSeconds);
                }
                window.api.send(sendChannels.ztc_burn, [board, uid, path]);
              } else {
                let otherDcn: string = checkDcn(res?.err?.message || "");
                if (otherDcn) {
                  getZdmDeviceFromIdentity(otherDcn).then((res: any) => {
                    if (res && res?.device_id) {
                      getInstallation({ id: res?.device_id }).then((r: any) => {
                        if (r && r?.installation) {
                          setConfirmOptions({
                            open: true,
                            question: `${t("identityAlreadyAssociated1")} ${
                              r.installation.name
                            } ${t("identityAlreadyAssociated2")}`,
                            onCancel: () => {
                              setConfirmOptions({
                                ...confirmOptions,
                                open: false,
                              });
                              setResult(false);
                              clearInterval(interval);
                            },
                            onConfirm: () => {
                              DeleteIdentity({
                                installation_id: res.device_id,
                                dcn: otherDcn,
                              }).then((rr) => {
                                if (rr && !rr.err) {
                                  CreateIdentity(
                                    {
                                      phys_id: phys_id,
                                      bundle: String(result.result),
                                    },
                                    installation_id
                                  ).then((res2) => {
                                    if (res2 && !res2.err && path !== "") {
                                      setPercent(80);
                                      if (
                                        remainingSeconds !==
                                        eightyPercentRemainingSeconds
                                      ) {
                                        setRemainingSeconds(
                                          eightyPercentRemainingSeconds
                                        );
                                      }
                                      window.api.send(sendChannels.ztc_burn, [
                                        board,
                                        uid,
                                        path,
                                      ]);
                                    } else {
                                      setResult(false);
                                      clearInterval(interval);
                                    }
                                  });
                                } else {
                                  setResult(false);
                                  clearInterval(interval);
                                }
                              });
                              setConfirmOptions({
                                ...confirmOptions,
                                open: false,
                              });
                            },
                          });
                        } else {
                          setResult(false);
                          clearInterval(interval);
                        }
                      });
                    } else {
                      setResult(false);
                      clearInterval(interval);
                    }
                  });
                } else {
                  setResult(false);
                  clearInterval(interval);
                }
              }
            });
          }
        } else {
          setResult(false);
          clearInterval(interval);
        }
      });
    } else {
      setResult(false);
      clearInterval(interval);
    }
  };

  const ztc_burn = async (result: ResultLog) => {
    console.log("BURN: ", result);
    await window.api.send(sendChannels.ztc_check_discover, [board, uid]);
    if (checkDevice) {
      if (
        result.result.length > 0 &&
        !String(result.result).includes("error")
      ) {
        setPercent(100);
        if (remainingSeconds !== hundredPercentRemainingSeconds) {
          setRemainingSeconds(hundredPercentRemainingSeconds);
        }
        setResult(true);
      } else {
        setResult(false);
        clearInterval(interval);
      }
    } else {
      setResult(false);
      clearInterval(interval);
    }
  };

  const ztc_check_discover = (result: ResultLog) => {
    console.log("DISCOVER: ", result);
    setCheckDevice(Boolean(result.result));
  };

  useEffect(() => {
    const firmware_download_result = window.api.receive(
      receiveChannels.firmware_download_result,
      (result: string) => firmware_download(result)
    );
    const ztc_discover_result = window.api.receive(
      receiveChannels.ztc_discover_result,
      (devices: ResultLog) => ztc_discover(devices)
    );
    const ztc_erase_result = window.api.receive(
      receiveChannels.ztc_erase_result,
      (result: ResultLog) => ztc_erase(result)
    );
    const ztc_phys_id_result = window.api.receive(
      receiveChannels.ztc_phys_id_result,
      async (result: ResultLog) => ztc_phys_id(result)
    );
    const ztc_provision_prepare_result = window.api.receive(
      receiveChannels.ztc_provision_prepare_result,
      async (result: ResultLog) => ztc_provision_prepare(result)
    );
    const ztc_provision_command_result = window.api.receive(
      receiveChannels.ztc_provision_command_result,
      async (result: ResultLog) => ztc_provision_command(result)
    );
    const ztc_burn_result = window.api.receive(
      receiveChannels.ztc_burn_result,
      async (result: ResultLog) => ztc_burn(result)
    );
    const ztc_check_discover_result = window.api.receive(
      receiveChannels.ztc_burn_result,
      (result: ResultLog) => ztc_check_discover(result)
    );
    return () => {
      firmware_download_result();
      ztc_discover_result();
      ztc_phys_id_result();
      ztc_provision_prepare_result();
      ztc_provision_command_result();
      ztc_burn_result();
      ztc_check_discover_result();
      ztc_erase_result();
    };
  }, []);

  useEffect(() => {
    setResult(null);
    setPercent(0);
    setCheckDevice(true);
    if (!loading) {
      connect();
    }
  }, [loading]);

  if (loading) {
    return null;
  }

  return (
    <>
      <TitleWithBack title={connectionTitle} key={dccTitleKey} />
      {progress ? (
        <div className="progress">
          {waitLabel}
          <LinearProgress
            className="progress"
            variant="determinate"
            value={percent}
          />
          {result === false ? null : (
            <Typography>
              {remainingTimeLabel} {secondsToTimer(remainingSeconds)}
            </Typography>
          )}
          <Typography> {waitHereLabel} </Typography>
          {result === true ? (
            <Alert severity="success" style={{ marginTop: "5%" }}>
              <AlertTitle>{resultTitle.success}</AlertTitle>
            </Alert>
          ) : result === false ? (
            <Alert severity="error" style={{ marginTop: "5%" }}>
              <AlertTitle>{resultTitle.error}</AlertTitle>
            </Alert>
          ) : null}
        </div>
      ) : null}
      <ConfirmOperation
        open={confirmOptions.open}
        question={confirmOptions.question}
        onCancel={confirmOptions.onCancel}
        onConfirm={confirmOptions.onConfirm}
      />
    </>
  );
};

export default DeviceConnectionConfiguration;
