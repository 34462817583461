import { installationFields } from "./inputs";
import ButtonItem from "../../custom/ButtonItem";
import ButtonConfItem from "../../custom/ButtonConfItem";
import React, { useCallback, useEffect, useState } from "react";
import { CreateInstallation } from "../../../api/services/installationsService";
import { Subscription } from "../../../api/requests/subscription";
import { getAllSubscriptions } from "../../../api/services/subscriptionService";
import CustomTitle from "../../custom/CustomTitle";
import "./AddInstallation.css";
import FormInput from "../../custom/input/FormInput";
import {
  addInstallationBtnDivKey,
  addInstallationTitleKey,
  formInputKey,
  primaryType,
  selectType,
  textType,
} from "../../../costants/costants";
import FormSelect from "../../custom/select/FormSelect";
import OperationResult from "../../custom/OperationResult";
import { Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";

const AddInstallation: React.FC = () => {
  const { t } = useTranslation();

  const [subOpt, setSubOpt] = useState<any[]>([]);
  const [status, setStatus] = useState<string>("");

  const goBack = () => {
    setValues({
      subscription_id: "",
      name: "",
      model: "lh",
      activeBoxSn: "",
      mateModel: "",
      mateSn: "",
      customer: "",
    });
    setStatus("");
  };

  useEffect(() => {
    getAllSubscriptions("-1", "", "").then((res: any) => {
      let tmp: any[] = [];
      if (res && res.subscriptions) {
        res.subscriptions.map((sub: Subscription) => {
          tmp = [
            ...tmp,
            {
              [sub.id]: `${sub.company_info.name} (${sub.customer_email})`,
            },
          ];
        });
        setSubOpt([...tmp]);
      }
    });
  }, []);

  const [error, setError] = useState<boolean>(false);
  const [values, setValues] = useState({
    subscription_id: "",
    name: "",
    model: "lh",
    activeBoxSn: "",
    mateModel: "",
    mateSn: "",
    customer: "",
  });

  const lhValue: string = "AB(LH)-CR82475800";
  const rhValue: string = "AB(RH)-CR82475700";

  const submit = useCallback(() => {
    if (values.subscription_id && values.model && values.activeBoxSn) {
      CreateInstallation({
        subscription_id: values.subscription_id,
        model: values.model === "lh" ? lhValue : rhValue,
        installation_info: {
          active_box_sn: values.activeBoxSn,
          mate_model: values.mateModel,
          mate_sn: values.mateSn,
          customer: values.customer,
        },
      }).then((res: any) => {
        if (res && res.installation) {
          setStatus("success");
        } else {
          setStatus(res?.err?.message || "");
        }
      });
    } else {
      setError(true);
    }
  }, [values]);

  if (status) {
    return (
      <OperationResult
        onClickProp={goBack}
        status={status === "success" ? "success" : "error"}
        operation={"create"}
        entity={"installation"}
        error={status}
      />
    );
  }

  return (
    <>
      <CustomTitle title={t("createDevice")} key={addInstallationTitleKey} />
      <Card
        variant="outlined"
        style={{ marginLeft: "25%", marginRight: "25%" }}
      >
        <CardContent>
          {installationFields(t, subOpt).map((el: any) => {
            if (el.type === selectType) {
              return (
                <FormSelect
                  error={el.name === "mateModel" ? false : error}
                  key={formInputKey(el.key)}
                  placeholder={el.label}
                  keyValue={el.key}
                  name={el.name}
                  options={
                    el.name === "mateModel"
                      ? el.options.filter((opt: any) =>
                          String(Object.keys(opt)[0])
                            .toLocaleLowerCase()
                            .includes(values.model)
                        )
                      : el?.options || []
                  }
                  rules={el.rules}
                  value={(values as any)[el.name]}
                  onChange={(e: any) => {
                    setValues({ ...values, [el.name]: e.target.value });
                  }}
                />
              );
            } else {
              return (
                <FormInput
                  error={
                    el.name === "mateSn" || el.name === "customer"
                      ? false
                      : error
                  }
                  key={formInputKey(el.key)}
                  name={el.name}
                  keyValue={el.key}
                  placeholder={el.label}
                  type={el.type ?? textType}
                  rules={el.rules}
                  value={(values as any)[el.name]}
                  onchange={(e: any) => {
                    setValues({ ...values, [el.name]: e.target.value });
                  }}
                />
              );
            }
          })}
          <div className="btn-container" key={addInstallationBtnDivKey}>
            <ButtonConfItem
              buttonLabel={t("reset")}
              buttonOnConfirm={() => {
                setValues({
                  subscription_id: "",
                  name: "",
                  model: "product",
                  activeBoxSn: "",
                  mateModel: "",
                  mateSn: "",
                  customer: "",
                });
              }}
              questionLabel={t("question")}
            />
            <ButtonItem
              buttonType={primaryType}
              label={t("ok")}
              buttonOnClick={submit}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default AddInstallation;
