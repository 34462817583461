import "./UpdateSubscription.css";
import "../../../App.css";
import React, { useEffect, useState } from "react";
import { GetUser } from "../../../api/services/userService";
import { GetUserRequest, User } from "../../../api/requests/userService";
import moment from "moment";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type CustomerSummaryProps = {
  customer_id: string;
};

const CustomerSummary: React.FC<CustomerSummaryProps> = ({ customer_id }) => {
  const { t } = useTranslation();

  const [customer, setCustomer] = useState({});
  useEffect(() => {
    GetUser({ user_id: customer_id } as GetUserRequest).then((res: any) => {
      if (res && res.user) {
        setCustomer(res.user);
      }
    });
  }, []);

  const dateFormatter = (date: string) => {
    return moment(date).format("DD/MM/YY HH:mm");
  };

  return (
    <Card
      variant="outlined"
      style={{ marginBottom: "5%", marginLeft: "5%", marginRight: "5%" }}
    >
      <CardContent>
        <TableContainer>
          <Table size="small" aria-label="simple table" title={t("customer")}>
            <TableBody>
              <TableRow key="id">
                <TableCell
                  component="th"
                  scope="row"
                  style={{ borderBottom: "none" }}
                >
                  {t("name")}
                </TableCell>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  {(customer as User).name}
                </TableCell>
              </TableRow>
              <TableRow key="email">
                <TableCell
                  component="th"
                  scope="row"
                  style={{ borderBottom: "none" }}
                >
                  {t("email")}
                </TableCell>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  {(customer as User).email}
                </TableCell>
              </TableRow>
              <TableRow key="createdat">
                <TableCell
                  component="th"
                  scope="row"
                  style={{ borderBottom: "none" }}
                >
                  {t("creationDate")}
                </TableCell>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  {dateFormatter((customer as User).created_at)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default CustomerSummary;
