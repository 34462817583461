import "./RecoverPassword.css";
import React, { useEffect, useState } from "react";
import ButtonItem from "../../custom/ButtonItem";
import { useHistory } from "react-router";
import { loginPageUrl } from "../costants";
import logo from "../../../images/Logo-COMAU-white.png";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../../images/Comau_Mate-XT_Frame.png";

type ResultRecoverPasswordProps = {};

const ResultRecoverPassword: React.FC<ResultRecoverPasswordProps> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.backgroundImage = `url(${backgroundImage})`;
    document.body.style.backgroundRepeat = "no-repeat";
    return () => {
      document.body.style.backgroundImage = "";
      document.body.style.backgroundRepeat = "";
    };
  }, []);

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const history = useHistory();
  const submit = () => history.push(loginPageUrl);

  return (
    <div className={width <= 768 ? "login-box-responsive" : "login-box"}>
      <div className="image-container">
        <img
          src={logo}
          className={width <= 768 ? "image-responsive" : "image"}
          alt="logo-comau"
          style={{ maxWidth: "130px" }}
        />
      </div>
      <Alert severity="success">
        <AlertTitle>{t("changePasswordTitle")}</AlertTitle>
        <Grid flexDirection="column">
          <Grid item>{t("changePasswordSubTitle")}</Grid>
        </Grid>
      </Alert>
      <Grid item style={{ marginTop: "8px" }}>
        <ButtonItem
          buttonType="outlined"
          label={t("login")}
          buttonOnClick={submit}
        />
      </Grid>
    </div>
  );
};

export default ResultRecoverPassword;
