import { MenuType } from "../types/navbar/types";
import {
  alarmsPageUrl,
  deviceConfigurationUrl,
  routes,
  subscriptionsListToDataHistoryPageUrl,
  subscriptionsListToInstallationPageUrl,
} from "../pages/costants";
import { alarmsKey, customerKey, historyKey } from "../../costants/costants";

export const customerMenuItems: (t: any) => MenuType[] = (t: any) => [
  // TODO: remove first element, it is necessary because the first element is not shown
  {
    key: "",
    label: "",
    elements: [],
    link: ""
  },
  {
    key: customerKey,
    label: t("realTimeData"),
    elements: [],
    link: subscriptionsListToInstallationPageUrl,
  },
  {
    key: historyKey,
    label: t("dataHistory"),
    elements: [],
    link: subscriptionsListToDataHistoryPageUrl,
  },
  {
    key: alarmsKey,
    label: t("alarms"),
    link: alarmsPageUrl,
    elements: [],
  },
  {
    key: "deviceConfiguration",
    label: t("addDevice"),
    link: deviceConfigurationUrl,
    elements: [],
  },
  {
    key: "wifiConfiguration",
    label: t("wifiConfiguration"),
    link: routes.subscriptionsListToWifi,
    elements: [],
  },
];
