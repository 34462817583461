import "../../App.css";
import React from "react";
import { Divider, Typography } from "@mui/material";

type CustomTitleProps = {
  title: string;
};

const CustomTitle: React.FC<CustomTitleProps> = ({ title }) => {
  return (
    <>
      <Typography
        style={{ fontWeight: "bold" }}
        className={"tableTitle"}
        variant="h3"
      >
        {title}
      </Typography>
      <Divider className="divider" />
    </>
  );
};

export default CustomTitle;
