import "./summary.css";
import React from "react";
import { Subscription } from "../../../api/requests/subscription";
import { localeDateFormatter } from "../../../utilities/utilities";
import {
  disabledLabel,
  enabledLabel,
  subscriptionOverview,
} from "../../../costants/costants";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Chip,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type SubscriptionSummaryProps = {
  subscription: Subscription | null;
};

const SubscriptionSummary: React.FC<SubscriptionSummaryProps> = ({
  subscription,
}) => {
  const { t } = useTranslation();

  const tagColor = (status: string) => {
    if (status === enabledLabel) {
      return "success";
    }
    if (status === disabledLabel) {
      return "error";
    }
    return "info";
  };

  if (subscription) {
    return (
      <Card
        variant="outlined"
        style={{
          marginLeft: "5%",
          marginRight: "5%",
          marginBottom: "5%",
          backgroundColor: "#3255ab",
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <TableContainer>
                <Table
                  size="small"
                  aria-label="simple table"
                  title={t("fleetOverview")}
                >
                  <TableBody>
                    <TableRow key="name">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("name")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {subscription.name}
                      </TableCell>
                    </TableRow>
                    <TableRow key="expirydate">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("expiration")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {localeDateFormatter(String(subscription.expiry_date))}
                      </TableCell>
                    </TableRow>
                    <TableRow key="status">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("status")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        <Chip
                          color={tagColor(subscription.status)}
                          label={subscription.status}
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6}>
              <TableContainer>
                <Table
                  size="small"
                  aria-label="simple table"
                  title={subscriptionOverview}
                >
                  <TableBody>
                    <TableRow key="companyname">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("company")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {subscription.company_info.name}
                      </TableCell>
                    </TableRow>
                    <TableRow key="vat">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("vat")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {subscription.company_info.vat_number}
                      </TableCell>
                    </TableRow>
                    <TableRow key="address">
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {t("address")}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {subscription.company_info.address},{" "}
                        {subscription.company_info.city}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  } else {
    return null;
  }
};

export default SubscriptionSummary;
