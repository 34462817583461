import React, { useState } from "react";
import "../../../App.css";
import { updateInstallationTableColumns } from "./inputs";
import { GetAllInstallations } from "../../../api/services/installationsService";
import CustomTitle from "../../custom/CustomTitle";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { useHistory } from "react-router";
import { printUrl, updateInstallationUrl } from "../costants";
import { company, installationsKey } from "../../../costants/costants";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

type InstallationListPageProps = {
  SetPopupObject: (obj: any) => void;
  admin: boolean;
};

const InstallationsListPage: React.FC<InstallationListPageProps> = ({
  SetPopupObject,
  admin,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<Object[]>([]);
  const history = useHistory();
  const linkFunction: (value: string) => void = (value: string) => {
    history.push(updateInstallationUrl(value));
  };
  const linkPrintFunction: (value: string) => void = (value: string) => {
    history.push(printUrl(value));
  };

  return (
    <>
      <CustomTitle title={t("editDevice")} />
      <ResponsiveTable
        columns={updateInstallationTableColumns(
          t,
          data,
          SetPopupObject,
          linkFunction,
          linkPrintFunction,
          admin,
          enqueueSnackbar
        )}
        getPageAndSortedData={GetAllInstallations}
        defaultSort={company.name}
        responseDataName={installationsKey}
        setData={setData}
      />
    </>
  );
};

export default InstallationsListPage;
