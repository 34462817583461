import React, { useEffect } from "react";
import MUIDataTable, { Responsive } from "mui-datatables";
import "./Table.css";
import { useState } from "react";
import {
  dateTimeType,
  dateType,
  iconType,
  linkType,
  localeDateFormatter,
  localeDateTimeFormatter,
  numberType,
  statusDate,
  stringType,
  tagType,
} from "../../../utilities/utilities";
import CustomTag from "../../custom/CustomTag";
import { rowsPerTablePage } from "../../../api/constants";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { tableKey } from "../../../costants/costants";
import { Chip, Link } from "@mui/material";
import CustomLoading from "../CustomLoading";
import { ReactComponent as filterIcon } from "../../../images/filter.svg";
import { ReactComponent as searchIcon } from "../../../images/search.svg";
import { useTranslation } from "react-i18next";

interface ResponsiveTableProps {
  title?: string;
  columns: ResponsiveTableColumnType[];
  data?: Object[];
  expandableRows?: boolean;
  renderExpandableRow?: (rowData: any, rowMeta: any) => JSX.Element;
  icon?: boolean;
  getPageAndSortedData?: any;
  defaultSort?: string;
  responseDataName?: string;
  id?: string;
  filter?: boolean;
  setFatherData?: (x: any) => void;
  setData?: React.Dispatch<React.SetStateAction<Object[]>>;
  print?: boolean;
}

interface ResponsiveTableCellProps {
  type: string;
  value: string;
  color?: "info" | "error" | "success";
  link?: (...args: any[]) => void;
  icon?: React.ReactNode;
  background?: string;
}

export type ResponsiveTableColumnType = {
  name: string;
  label: string | any;
  options: {
    filter: boolean;
    sort?: boolean;
    customBodyRender: (a: any, b: any, c: any) => React.ReactNode;
  };
};

export const ResponsiveTableCell: React.FC<ResponsiveTableCellProps> = ({
  type,
  value,
  color,
  link,
  icon,
  background,
}) => {
  switch (type) {
    case stringType:
      return (
        <div className="cell" style={{ backgroundColor: background || "" }}>
          {value}
        </div>
      );
    case tagType:
      return (
        <div className="cell" style={{ backgroundColor: background || "" }}>
          <CustomTag text={value} color={color || "info"} />
        </div>
      );
    case dateType:
      return (
        <div className="cell" style={{ backgroundColor: background || "" }}>
          {localeDateFormatter(value)}
        </div>
      );
    case dateTimeType:
      return (
        <div className="cell" style={{ backgroundColor: background || "" }}>
          {localeDateTimeFormatter(value)}
        </div>
      );
    case linkType:
      return (
        <Link
          className="cell"
          style={{ backgroundColor: background || "", cursor: "pointer" }}
          onClick={link}
        >
          {value}
        </Link>
      );
    case iconType:
      return (
        <a
          className="cell"
          style={{
            backgroundColor: background || "",
            justifyContent: "center",
          }}
          onClick={link}
        >
          {icon}
        </a>
      );
    case numberType:
      return (
        <div className="cell" style={{ backgroundColor: background || "" }}>
          {parseFloat(value).toFixed(2)}
        </div>
      );
    case statusDate:
      if (new Date(value).getFullYear() === 1) {
        return (
          <div className="cell" style={{ backgroundColor: background || "" }}>
            <CustomTag color="error" text="never" />
          </div>
        );
      }
      const minutesDiff = Math.abs(
        (new Date().getTime() - new Date(value).getTime()) / (1000 * 60)
      );
      if (minutesDiff < 10) {
        return (
          <div className="cell" style={{ backgroundColor: background || "" }}>
            <Chip label={localeDateFormatter(value)} color="success" />
          </div>
        );
      } else {
        return (
          <div className="cell" style={{ backgroundColor: background || "" }}>
            <Chip label={localeDateFormatter(value)} color="error" />
          </div>
        );
      }
    default:
      return (
        <div className="cell" style={{ backgroundColor: background || "" }}>
          {value}
        </div>
      );
  }
};

const ResponsiveTable: React.FC<ResponsiveTableProps> = ({
  title,
  columns,
  data,
  expandableRows,
  renderExpandableRow,
  icon,
  getPageAndSortedData,
  defaultSort,
  filter,
  responseDataName,
  id,
  setFatherData,
  setData,
  print,
}) => {
  const [tableData, setTableData] = useState<Object[]>([]);
  const [totalDataLength, setTotalDataLength] = useState<number>(0);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentSort, setCurrentSort] = useState<string>(defaultSort ?? "");
  const [currentSearch, setCurrentSearch] = useState<string>("");

  const handleSetData = (res: any) => {
    if (setData) {
      setData(res);
    }
  };

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (getPageAndSortedData && responseDataName) {
      if (id) {
        getPageAndSortedData(id, "0", defaultSort!, currentSearch).then(
          (res: Object) => {
            if (res && (res as any)[responseDataName]) {
              setTableData((res as any)[responseDataName]);
              handleSetData((res as any)[responseDataName]);
              setTotalDataLength((res as any)["total_count"] ?? 0);
            }
            setLoading(false);
          }
        );
      } else {
        getPageAndSortedData("0", defaultSort!, currentSearch).then(
          (res: Object) => {
            if (res && (res as any)[responseDataName]) {
              setTableData((res as any)[responseDataName]);
              handleSetData((res as any)[responseDataName]);
              setTotalDataLength((res as any)["total_count"] ?? 0);
            }
            setLoading(false);
          }
        );
      }
    } else if (data) {
      setTableData(data);
      handleSetData(data);
      setLoading(false);
    }
    if (setFatherData) {
      setFatherData(tableData);
    }
  }, []);

  const changePage = (page: number) => {
    setCurrentPage(page);
    if (id) {
      getPageAndSortedData(id, String(page), currentSort, currentSearch).then(
        (res: any) => {
          if (res && responseDataName && (res as any)[responseDataName]) {
            setTableData((res as any)[responseDataName]);
            handleSetData((res as any)[responseDataName]);
          }
        }
      );
    } else {
      getPageAndSortedData(String(page), currentSort, currentSearch).then(
        (res: any) => {
          if (responseDataName && res && (res as any)[responseDataName]) {
            setTableData((res as any)[responseDataName]);
            handleSetData((res as any)[responseDataName]);
          }
        }
      );
    }
  };

  const changeSort = (sort: string) => {
    setCurrentSort(sort);
    if (id) {
      getPageAndSortedData(id, String(currentPage), sort, currentSearch).then(
        (res: any) => {
          if (res && responseDataName && (res as any)[responseDataName]) {
            setTableData((res as any)[responseDataName]);
            handleSetData((res as any)[responseDataName]);
          }
        }
      );
    } else {
      getPageAndSortedData(String(currentPage), sort, currentSearch).then(
        (res: any) => {
          if (responseDataName && res && (res as any)[responseDataName]) {
            setTableData((res as any)[responseDataName]);
            handleSetData((res as any)[responseDataName]);
          }
        }
      );
    }
  };

  const changeSearch = (text: string) => {
    setCurrentSearch(text);
    setCurrentPage(0);
    if (id) {
      getPageAndSortedData(id, "0", currentSort, text).then((res: any) => {
        if (res && responseDataName) {
          if ((res as any)[responseDataName]) {
            setTableData((res as any)[responseDataName]);
            handleSetData((res as any)[responseDataName]);
          } else {
            setTableData([]);
            handleSetData([]);
          }
        }
      });
    } else {
      getPageAndSortedData("0", currentSort, text).then((res: any) => {
        if (res && responseDataName) {
          if ((res as any)[responseDataName]) {
            setTableData((res as any)[responseDataName]);
            handleSetData((res as any)[responseDataName]);
          } else {
            setTableData([]);
            handleSetData([]);
          }
        }
      });
    }
  };

  const { t } = useTranslation();

  let options: Object = {};

  if (getPageAndSortedData) {
    options = {
      textLabels: {
        body: {
          noMatch: t("noRecordsFound"),
          toolTip: t("sort"),
        },
        pagination: {
          next: t("nextPage"),
          previous: t("previousPage"),
          rowsPerPage: t("rowsPerPage"),
          displayRows: t("of"),
        },
        toolbar: {
          search: t("search"),
          downloadCsv: t("downloadCsv"),
          print: t("print"),
          viewColumns: t("viewColumns"),
          filterTable: t("filterTable"),
        },
        filter: {
          all: t("all"),
          title: t("filters"),
          reset: t("reset"),
        },
        viewColumns: {
          title: t("showColumns"),
          titleAria: t("showHideColumns"),
        },
      },
      enableNestedDataAccess: ".",
      selectableRows: "none" as "none",
      filter: filter ?? false,
      print: print ? true : false,
      download: false,
      serverSide: true,
      responsive: "standard" as Responsive,
      isRowSelectable: () => false,
      expandableRows: expandableRows ?? false,
      renderExpandableRow: !!expandableRows ? renderExpandableRow : undefined,
      rowsPerPage: rowsPerTablePage,
      count: totalDataLength,
      rowsPerPageOptions: [],
      onTableChange: (action: string, tableState: any) => {
        switch (action) {
          case "changePage":
            changePage(tableState.page);
            break;
          case "sort":
            let sort;
            if (tableState.sortOrder.direction === "asc") {
              sort = tableState.sortOrder.name;
              setCurrentSort(sort);
            } else {
              sort = `-${tableState.sortOrder.name}`;
              setCurrentSort(sort);
            }
            changeSort(sort);
            break;
          case "search":
            changeSearch(tableState.searchText);
            break;
          default:
            break;
        }
      },
    };
  }

  if (!getPageAndSortedData) {
    options = {
      textLabels: {
        body: {
          noMatch: t("noRecordsFound"),
          toolTip: t("sort"),
        },
        pagination: {
          next: t("nextPage"),
          previous: t("previousPage"),
          rowsPerPage: t("rowsPerPage"),
          displayRows: t("of"),
        },
        toolbar: {
          search: t("search"),
          downloadCsv: t("downloadCsv"),
          print: t("print"),
          viewColumns: t("viewColumns"),
          filterTable: t("filterTable"),
        },
        filter: {
          all: t("all"),
          title: t("filters"),
          reset: t("reset"),
        },
        viewColumns: {
          title: t("showColumns"),
          titleAria: t("showHideColumns"),
        },
      },
      enableNestedDataAccess: ".",
      selectableRows: "none" as "none",
      filter: filter ?? false,
      download: false,
      responsive: "standard" as Responsive,
      isRowSelectable: () => false,
      expandableRows: expandableRows ?? false,
      renderExpandableRow:
        expandableRows === true ? renderExpandableRow : undefined,
    };
  }

  const hideIcons = {
    enableNestedDataAccess: ".",
    selectableRows: "none" as "none",
    isRowSelectable: () => false,
    expandableRows: expandableRows ?? false,
    renderExpandableRow:
      expandableRows === true ? renderExpandableRow : undefined,
    download: false,
    filter: false,
    print: true,
    search: false,
    viewColumns: false,
    responsive: "standard" as Responsive,
  };

  if (loading) {
    return <CustomLoading />;
  }

  if (icon === false) {
    return (
      <div key={tableKey} className="verticalBorder">
        <MUIDataTable
          title={title}
          data={data ? data : tableData}
          columns={columns}
          options={hideIcons}
        />
      </div>
    );
  } else {
    return (
      <StyledEngineProvider injectFirst>
        <div key={tableKey} className="verticalBorder">
          <ThemeProvider
            theme={createTheme({
              palette: {
                mode: "dark",
              },
              components: {
                MuiPaper: {
                  styleOverrides: {
                    root: {
                      backgroundColor: "#3255ab",
                      boxShadow: "none",
                      backgroundImage: "none",
                      color: "white",
                    },
                  },
                },
              },
            })}
          >
            <MUIDataTable
              title={title}
              data={data ? data : tableData}
              columns={columns}
              options={options}
              components={{
                icons: {
                  SearchIcon: searchIcon,
                  ViewColumnIcon: filterIcon,
                },
              }}
            />
          </ThemeProvider>
        </div>
      </StyledEngineProvider>
    );
  }
};

export default ResponsiveTable;
