export const sendChannels = {
    ztc_check_discover: "ztc_check_discover",
    firmware_download: "firmware_download",
    ztc_change_connection: "ztc_change_connection",
    ztc_discover: "ztc_discover",
    ztc_phys_id: "ztc_phys_id",
    ztc_provision_prepare: "ztc_provision_prepare",
    ztc_provision_command: "ztc_provision_command",
    ztc_burn: "ztc_burn",
    ztc_erase: "ztc_device_erase",
    zdm_sim_active: "zdm_sim_active",
    technical_testing: "technical_testing"
}

export const receiveChannels = {
    ztc_check_discover_result: "ztc_check_discover_result",
    firmware_download_result: "firmware_download_result",
    ztc_change_connection_result: "ztc_change_connection_result",
    ztc_discover_result: "ztc_discover_result",
    ztc_phys_id_result: "ztc_phys_id_result",
    ztc_provision_prepare_result: "ztc_provision_prepare_result",
    ztc_provision_command_result: "ztc_provision_command_result",
    ztc_burn_result: "ztc_burn_result",
    ztc_erase_result: "ztc_device_erase_result",
    zdm_sim_active_result: "zdm_sim_active_result",
    technical_testing_result: "technical_testing_result"
}

export const select = {
    label: "Connection type",
    name: "connection_type",
    placeholder: "Connection type",
    options: [
        //{wifi: "WiFi"},
        {gsm: "gsm"}
    ]
}

export const wifi = [
    {
        label: "Network name",
        name: "sid",
        requiredLabel: "Required field!",
        placeholder: "Network name",
        type: "text"
    },
    {
        label: "Password",
        name: "password",
        requiredLabel: "Required field!",
        placeholder: "Password",
        type: "password"
    }
]

export const gsm = {
    label: "Select the telephone operator",
    requiredLabel: "Required field!",
    name: "operator",
    placeholder: "...",
    options: [
        {"mobile.vodafone.it": "Vodafone"},
        {"ibox.tim.it": "TIM"},
        {"internet.wind": "Wind"},
        {"TM": "Things Mobile"},
        {"iot.secure": "Zerynth Sim"},
    ]
}

export const resultStatus = {
    success: "success",
    error: "error"
}

export const resultTitle = {
    success: "The operation was completed successfully",
    error: "The operation was not successful"
}