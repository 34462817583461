import { BackendUrl } from "../constants";

export const isTest = process.env.NODE_ENV === "test";

export const verticalFetch = (
  method: string,
  url: string,
  param?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    credentials: "include",
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
};

export const httpFetch = (
  method: string,
  url: string,
  param?: string
): Promise<any> => {
  const hrs = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  return fetch(url, {
    method: method,
    body: param,
    headers: hrs,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw Error(error.responseText);
    });
};

const checkApiKey = () => {
  //local
  if (String(BackendUrl).toLocaleLowerCase().includes("applocal")) {
    return "D8dObyZXKd.KURFg3ZRwW4WkInh7iQyPfe8XddHG9DPDzUmpwcm05GC9uyLezVpaGU4ZbjYLhhZ";
  }
  //test
  if (String(BackendUrl).toLocaleLowerCase().includes("apptest")) {
    return "BTBtcuppVz.iNWSzWwkuAXhUSHgzb7d1pTr8feg8AwtLX0lnKcwGRsmtDxHWjDZl-VtRA5xYJnr";
  }
  //production
  return "Alk6KVcxBT.fpYUp9bg-kxdVO7s9PrOmtVVwYJR71DWFc24HPu3wN-g$frSHVXg3ZGjhpvgu3cW";
};

export const httpZDMFetch = (
  method: string,
  url: string,
  param?: string
): Promise<any> => {
  const hrs = {
    Accept: "application/json",
    "Content-Type": "application/json",
    //"Access-Control-Allow-Origin": "*",
    mode: "no-cors",
    "X-API-KEY": checkApiKey(),
  };

  return fetch(url, {
    method: method,
    body: param,
    headers: hrs,
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
};

// used to download files
export const blobFetch = (
  method: string,
  url: string,
  param?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    body: param,
    headers: {
      Accept: "application/octet-stream",
    },
  })
    .then((response) => response.blob())
    .catch((error) => {
      console.log(error);
    });
};

export const verticalCheck = (
  method: string,
  url: string,
  param?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return response.json();
      } else {
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const methodPost = "POST";
export const methodPut = "PUT";
export const methodGet = "GET";
export const methodDelete = "DELETE";
