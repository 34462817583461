import { MenuType, SubMenuType } from "../types/navbar/types";
import "./Navbar.css";
import {
  alarmsPageUrl,
  createCustomerPageUrl,
  createInstallationUrl,
  createSubscriptionPageUrl,
  customersListPageUrl,
  installationsListToUpdateUrl,
  installerPanelIconsUrl,
  subscriptionsListToDataHistoryPageUrl,
  subscriptionsListToInstallationPageUrl,
  subscriptionsListToLogsUrl,
  subscriptionsListToUpdateUrl,
} from "../pages/costants";
import {
  addCustomerKey,
  alarmsKey,
  createInstallationKey,
  createSubscriptionKey,
  dataRealTimeKey,
  historyKey,
  installerRole,
  updateCustomerKey,
  updateInstallationKey,
  updateSubscriptionKey,
} from "../../costants/costants";

const installer: (t: any) => SubMenuType[] = (t: any) => [
  {
    key: addCustomerKey,
    label: t("createCustomer"),
    link: createCustomerPageUrl,
    elements: [],
  },
  {
    key: updateCustomerKey,
    label: t("editCustomer"),
    link: customersListPageUrl,
    elements: [],
  },
  {
    key: createSubscriptionKey,
    label: t("createFleet"),
    link: createSubscriptionPageUrl,
    elements: [],
  },
  {
    key: updateSubscriptionKey,
    label: t("editFleet"),
    link: subscriptionsListToUpdateUrl,
    elements: [],
  },
  {
    key: createInstallationKey,
    label: t("createDevice"),
    link: createInstallationUrl,
    elements: [],
  },
  {
    key: updateInstallationKey,
    label: t("editDevice"),
    link: installationsListToUpdateUrl,
    elements: [],
  },
  {
    key: "testHistory",
    label: t("testHistory"),
    link: subscriptionsListToLogsUrl,
    elements: [],
  },
];

export const installerMenuItems: (t: any) => MenuType[] = (t: any) => [
    // TODO: remove first element, it is necessary because the first element is not shown
    {
        key: "",
        label: "",
        elements: [],
        link: ""
    },
    {   
        key: dataRealTimeKey,
        label: t("realTimeData"),
        elements: [],
        link: subscriptionsListToInstallationPageUrl
    },
    {
        key: historyKey,
        label: t("dataHistory"),
        elements: [],
        link: subscriptionsListToDataHistoryPageUrl
    },
    {   
        key: alarmsKey, 
        label: t("alarms"),
        link: alarmsPageUrl,
        elements: []
    },
    {
        key: installerRole,
        label: t("configurator"),
        elements: installer(t),
        link: installerPanelIconsUrl
    }
]
