import ButtonItem from "../../custom/ButtonItem";
import ButtonConfItem from "../../custom/ButtonConfItem";
import {
  GetUser,
  UpdateUser as ApiUpdateUser,
} from "../../../api/services/userService";
import React, { useCallback, useEffect, useState } from "react";
import "../../navbar/Navbar.css";
import "../../../App.css";
import { User } from "../../../api/requests/userService";
import { userFields } from "./inputs";
import "./UpdateUser.css";
import FormInput from "../../custom/input/FormInput";
import { engCountries, itaCountries } from "../../custom/select/countries";
import FormSelect from "../../custom/select/FormSelect";
import { updateUserTitle } from "../title";
import OperationResult from "../../custom/OperationResult";
import {
  dealerRole,
  errorStatus,
  formInputKey,
  installerRole,
  primaryType,
  selectType,
  successStatus,
  textType,
  updateUserBtnDivKey,
} from "../../../costants/costants";
import TitleWithBack from "../../custom/TitleWithBack";
import { Card, CardContent, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

type UpdateUserProps = {
  user_id: string;
};

const UpdateUser: React.FC<UpdateUserProps> = ({ user_id }) => {
  const { t, i18n } = useTranslation();

  const [status, setStatus] = useState<string>("");
  const goBack = () => {
    setStatus("");
  };

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    province: "",
    country: "",
    zip_code: "",
  });
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  useEffect(() => {
    GetUser({ user_id: user_id }).then((res: any) => {
      if (res && res.user && res.user.user_info) {
        setValues({
          name: res.user.name,
          email: res.user.email,
          phone: res.user.user_info.phone,
          address: res.user.user_info.address,
          city: res.user.user_info.city,
          province: res.user.user_info.province,
          country: res.user.user_info.country,
          zip_code: res.user.user_info.zip_code,
        });
        setSelectedUser(res.user);
      }
    });
  }, []);

  const handleSubmit = useCallback(() => {
    ApiUpdateUser({
      user_id: selectedUser?.uid || "",
      name: values.name,
      email: values.email,
      user_info: {
        phone: values.phone,
        address: values.address,
        city: values.city,
        province: values.province,
        country: values.country,
        zip_code: values.zip_code,
      },
    }).then((res) => {
      if (res && res.user) {
        setStatus("success");
      } else {
        setStatus(res?.err?.message || "");
      }
    });
  }, [selectedUser, values]);

  if (!selectedUser) {
    return null;
  }

  if (status) {
    return (
      <OperationResult
        onClickProp={status === errorStatus ? goBack : undefined}
        status={status === "success" ? "success" : "error"}
        operation={"update"}
        entity={
          selectedUser.roles.includes(installerRole)
            ? "installer"
            : selectedUser.roles.includes(dealerRole)
            ? "dealer"
            : "user"
        }
        error={status}
      />
    );
  }

  return (
    <>
      <TitleWithBack
        title={
          selectedUser.roles.includes(installerRole)
            ? t("editInstaller")
            : selectedUser.roles.includes(dealerRole)
            ? t("editDealer")
            : t("editCustomer")
        }
        key={updateUserTitle}
      />
      <Card
        variant="outlined"
        style={{
          marginLeft: width <= 575 || height <= 815 ? "2%" : "25%",
          marginRight: width <= 575 || height <= 815 ? "2%" : "25%",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            {userFields(t).map((el) => {
              return (
                <Grid item xs={12} md={6}>
                  {el.type !== selectType ? (
                    <FormInput
                      rules={el.rules}
                      key={formInputKey(el.key)}
                      keyValue={el.key}
                      name={el.name}
                      placeholder={el.label}
                      type={el.type ?? textType}
                      value={(values as any)[el.name]}
                      onchange={(e: any) => {
                        setValues({ ...values, [el.name]: e.target.value });
                      }}
                    />
                  ) : (
                    <FormSelect
                      rules={el.rules}
                      key={formInputKey(el.key)}
                      placeholder={el.label}
                      keyValue={el.key}
                      name={el.name}
                      options={
                        i18n.language === "en" ? engCountries : itaCountries
                      }
                      value={(values as any)[el.name]}
                      onChange={(e: any) => {
                        setValues({ ...values, [el.name]: e.target.value });
                      }}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
          <div className="btn-container" key={updateUserBtnDivKey}>
            <ButtonConfItem
              buttonLabel={t("reset")}
              buttonOnConfirm={() =>
                setValues({
                  name: "",
                  email: "",
                  phone: "",
                  address: "",
                  city: "",
                  province: "",
                  country: "",
                  zip_code: "",
                })
              }
              questionLabel={t("question")}
            />
            <ButtonItem
              buttonType={primaryType}
              label={t("ok")}
              buttonOnClick={handleSubmit}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default UpdateUser;
