import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import "../../../App.css";
import {
  dateType,
  iconType,
  linkType,
  stringType,
} from "../../../utilities/utilities";
import {
  address,
  city,
  createdAt,
  email,
  name,
  province,
  userInfo,
  zip,
} from "../../../costants/costants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import IconConfItem from "../../custom/IconConfItem";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DeleteUser } from "../../../api/services/userService";

export const installersListColumns: (
  t: any,
  rowData: any,
  SetPopupObject: (obj: any) => void,
  linkFunction: (value: string) => void,
  enqueueSnackbar: any
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  linkFunction,
  enqueueSnackbar
) => [
  {
    label: t("name"),
    name: name.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        return (
          <div className="details-container">
            <Tooltip title={t("details")}>
              <InfoOutlinedIcon
                className="details-icon"
                onClick={() => {
                  SetPopupObject([
                    {
                      email: rowData[data.rowIndex]?.email || "",
                      name: rowData[data.rowIndex]?.name || "",
                      role: rowData[data.rowIndex]?.roles || "",
                      created_at: rowData[data.rowIndex]?.created_at || "",
                      phone: rowData[data.rowIndex]?.user_info?.phone || "",
                      address: rowData[data.rowIndex]?.user_info?.address || "",
                      city: rowData[data.rowIndex]?.user_info?.city || "",
                      province:
                        rowData[data.rowIndex]?.user_info?.province || "",
                      country: rowData[data.rowIndex]?.user_info?.country || "",
                      zip_code:
                        rowData[data.rowIndex]?.user_info?.zip_code || "",
                    },
                  ]);
                }}
              />
            </Tooltip>
            <div className="details">
              <ResponsiveTableCell
                type={linkType}
                value={value}
                link={() => {
                  linkFunction(data.tableData[data.rowIndex].uid);
                }}
              />
            </div>
          </div>
        );
      },
    },
  },
  {
    label: t("email"),
    name: email.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("address"),
    name: `${userInfo}.${address.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("city"),
    name: `${userInfo}.${city.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("province"),
    name: `${userInfo}.${province.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("zip"),
    name: `${userInfo}.${zip.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("creationDate"),
    name: createdAt.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={dateType} value={value} />
      ),
    },
  },
  {
    label: t("delete"),
    name: "uid",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell
          type={iconType}
          value=""
          icon={
            <IconConfItem
              icon={<DeleteOutlineOutlinedIcon />}
              questionLabel={t("deleteInstallerQuestion")}
              buttonOnConfirm={() => {
                DeleteUser(value).then((res: any) => {
                  if (res && !res.err) {
                    enqueueSnackbar(t("userDeleteSuccess"), {
                      variant: "success",
                      preventDuplicate: true,
                    });
                    window.location.reload();
                  } else {
                    enqueueSnackbar(
                      res?.err?.message || t("somethingWentWrong"),
                      {
                        variant: "error",
                        preventDuplicate: true,
                      }
                    );
                  }
                });
              }}
            />
          }
        />
      ),
    },
  },
];
