import React from "react"
import '../../navbar/Navbar.css'
import './alarmTable.css'
import '../../../App.css'
import CustomTitle from "../../custom/CustomTitle";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { GetAllAlarms } from "../../../api/services/installationsService";
import { alarmColumns } from "./columns";
import { alarmsKey, alarmSort } from "../../../costants/costants";
import { useHistory } from "react-router";
import { installationPageUrl } from "../costants";
import { useTranslation } from "react-i18next";

const AlarmTable: React.FC = () => {
    const {t} = useTranslation()

    const history = useHistory();
    const linkFunction: (value: string) => void = (value: string) => { 
        history.push(installationPageUrl(value))
    }

    return(
        <>
            <CustomTitle title={t("alarms")}/>
            <ResponsiveTable 
                columns={alarmColumns(t, linkFunction)} 
                getPageAndSortedData={GetAllAlarms}
                defaultSort={alarmSort}
                responseDataName={alarmsKey}
            />
        </>
    )
}

export default AlarmTable;