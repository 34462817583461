import React from "react";
import {
  customersListPageUrl,
  dealersListPageUrl,
  installationsListToUpdateUrl,
  installersListPageUrl,
  mainUrl,
  subscriptionsListToUpdateUrl,
} from "../pages/costants";
import { Alert, AlertTitle } from "@mui/material";
import TitleWithBack from "./TitleWithBack";
import { useTranslation } from "react-i18next";

type OperationResultProps = {
  status: "success" | "error";
  operation: "create" | "update";
  entity:
    | "user"
    | "installer"
    | "installation"
    | "subscription"
    | "signal"
    | "dealer";
  statusDescription?: string;
  onClickProp?: () => void;
  error?: string;
};

const OperationResult: React.FC<OperationResultProps> = ({
  status,
  operation,
  entity,
  statusDescription,
  onClickProp,
  error,
}) => {
  const { t } = useTranslation();
  const messages = {
    create: {
      user: {
        link: mainUrl,
        success: t("createUserSuccess"),
        error: t("createUserError"),
      },
      installer: {
        link: mainUrl,
        success: t("createInstallerSuccess"),
        error: t("createInstallerError"),
      },
      dealer: {
        link: mainUrl,
        success: t("createDealerSuccess"),
        error: t("createDealerError"),
      },
      installation: {
        link: mainUrl,
        success: t("createInstallationSuccess"),
        error: t("createInstallationError"),
      },
      subscription: {
        link: mainUrl,
        success: t("createSubscriptionSuccess"),
        error: t("createsubscriptionError"),
      },
      signal: {
        link: mainUrl,
        success: t("createSignalSuccess"),
        error: t("createSignalError"),
      },
    },
    update: {
      user: {
        link: customersListPageUrl,
        success: t("updateUserSuccess"),
        error: t("updateUserError"),
      },
      installer: {
        link: installersListPageUrl,
        success: t("updateInstallerSuccess"),
        error: t("updateInstallerError"),
      },
      dealer: {
        link: dealersListPageUrl,
        success: t("updateDealerSuccess"),
        error: t("updateDealerError"),
      },
      installation: {
        link: installationsListToUpdateUrl,
        success: t("updateInstallationSuccess"),
        error: t("updateInstallationError"),
      },
      subscription: {
        link: subscriptionsListToUpdateUrl,
        success: t("updateSubscriptionSuccess"),
        error: t("updatesubscriptionError"),
      },
      signal: {
        link: installationsListToUpdateUrl,
        success: t("updateSignalSuccess"),
        error: t("updateSignalError"),
      },
    },
  };

  return (
    <>
      <TitleWithBack
        title={""}
        link={messages[operation][entity]["link"]}
        onClickProp={onClickProp ? onClickProp : undefined}
      />
      <Alert
        style={{ marginLeft: "30%", marginRight: "30%" }}
        severity={status}
      >
        <AlertTitle>
          {(status === "success"
            ? t("success")
            : t("error")
          ).toLocaleUpperCase()}
        </AlertTitle>
        {error && error !== "success"
          ? error
          : messages[operation][entity][status]}
      </Alert>
    </>
  );
};

export default OperationResult;
