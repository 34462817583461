import React, { useEffect, useRef, useState } from "react";
import TitleWithBack from "../../custom/TitleWithBack";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { getInstallation } from "../../../api/services/installationsService";
import { Installation } from "../../../api/requests/installationsService";
import "../../../App.css";
import { useReactToPrint } from "react-to-print";
import ButtonItem from "../../custom/ButtonItem";
import { primaryType } from "../../../costants/costants";
import CustomLoading from "../../custom/CustomLoading";
import InstallationSummary from "../installation/summaryCards/InstallationSummary";
import mateLogo from "../../../images/Logo-COMAU.png";

type PrintPageProps = {
  installationId: string;
};

const PrintPage: React.FC<PrintPageProps> = ({ installationId }) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const { t } = useTranslation();

  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  useEffect(() => {
    getInstallation({ id: installationId }).then((res: any) => {
      if (res && res.installation) {
        setSelectedInstallation(res.installation);
      }
    });
  }, []);

  const [loading, setLoading] = useState<boolean>(false);
  const componentRef = React.useRef(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const onBeforeGetContentResolve = useRef<any>(null);
  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise((resolve: any) => {
      onBeforeGetContentResolve.current = resolve;
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 2000);
    });
  }, []);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle:
      `Activation code mate (${selectedInstallation?.installation_info?.mate_sn})` ||
      "Activation code mate",
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: () => setPrint(false),
    removeAfterPrint: true,
  });

  const [print, setPrint] = useState<boolean>(false);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <>
      <TitleWithBack title={selectedInstallation?.name || t("print")} />
      <div
        style={{
          marginTop: "5%",
          marginLeft: width <= 575 || height <= 815 ? "2%" : "10%",
          marginRight: width <= 575 || height <= 815 ? "2%" : "10%",
        }}
      >
        <InstallationSummary selected_installation={selectedInstallation} />
      </div>
      <div className="btn-container">
        <ButtonItem
          buttonType={primaryType}
          label={t("print")}
          buttonOnClick={() => {
            setPrint(true);
            handlePrint();
          }}
        />
      </div>
      <div
        ref={componentRef}
        style={{
          marginTop: "5%",
          marginLeft: width <= 575 || height <= 815 ? "2%" : "5%",
          marginRight: width <= 575 || height <= 815 ? "2%" : "5%",
        }}
      >
        <Card
          variant="outlined"
          style={{
            backgroundColor: "white",
            paddingTop: "5%",
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              color: "black",
            }}
          >
            <img
              key="logo"
              style={{ width: "10%", marginBottom: "5%" }}
              alt="logo"
              src={mateLogo}
            />
            <Typography
              variant="h6"
              style={{ fontWeight: "bold", marginBottom: "2%" }}
            >
              COMAU MATE-XT 4.0 ACTIVATION CERTIFICATE :
            </Typography>
          </div>
          <Card variant="outlined" style={{ marginBottom: "5%" }}>
            <CardContent>
              <Grid container direction="column">
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h6">{t("mateModel")}:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">
                      {selectedInstallation?.installation_info?.mate_model ||
                        "---"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h6">{t("activeBoxSn")}:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">
                      {selectedInstallation?.installation_info?.active_box_sn ||
                        "---"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h6">{t("mateSn")}:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">
                      {selectedInstallation?.installation_info?.mate_sn ||
                        "---"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h6">{t("activationCode")}:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">
                      {selectedInstallation?.mate_id || "---"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {print && (
            <Grid
              container
              style={{ marginTop: "80%", color: "black", fontSize: "11px" }}
              xs={12}
            >
              <Grid item container xs={3} flexDirection="column">
                <Grid item style={{ fontWeight: "bold" }}>
                  Comau S.p.A.
                </Grid>
                <Grid item>Via Rivalta, 30</Grid>
                <Grid item>10095 Grugliasco (TO) Italia</Grid>
                <Grid item>Tel. +39 011 0049111</Grid>
                <Grid item>www.comau.com</Grid>
              </Grid>
              <Grid item container xs={6} flexDirection="column">
                <Grid item>Sede legale: Via Rivalta, 30</Grid>
                <Grid item>10095 Grugliasco (TO) Italia</Grid>
                <Grid item>Capitale sociale Euro 48.013.959 i.v.</Grid>
                <Grid item>REA Torino 474119</Grid>
                <Grid item>
                  Reg. Impr. Torino, Cod. Fisc. e P. IVA 00952120012
                </Grid>
                <Grid item>CEE IT00952120012</Grid>
              </Grid>
              <Grid item container xs={3} flexDirection="column">
                <Grid item>Comau S.p.A. a socio unico</Grid>
                <Grid item>Direzione e coordinamento</Grid>
                <Grid item>ex art. 2497 c.c. Stellantis N.V.</Grid>
              </Grid>
            </Grid>
          )}
        </Card>
      </div>
    </>
  );
};

export default PrintPage;
