import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAlarmsCalibration } from "../../../api/services/installationsService";
import {
  primaryType,
  editLabel,
  minLabel,
  maxLabel,
} from "../../../costants/costants";
import ButtonItem from "../../custom/ButtonItem";
import TitleWithBack from "../../custom/TitleWithBack";
import { alarmConfigurationTitle } from "../title";

type AlarmConfigurationProps = {
  installation_id: string;
};

const AlarmConfiguration: React.FC<AlarmConfigurationProps> = ({
  installation_id,
}) => {
  const { t } = useTranslation();
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [alarms, setAlarms] = useState<any[]>([]);
  useEffect(() => {
    getAlarmsCalibration(installation_id).then((res: any) => {
      if (res && res.alarms) {
        setAlarms(res.alarms);
      }
    });
  }, []);

  const checkName = (name: string) => {
    switch (name) {
      default:
        return name;
    }
  };

  const checkStatus = (status: boolean) => {
    if (status) {
      return t("enabled");
    }
    if (!status) {
      return t("disabled");
    }
    return "---";
  };

  return (
    <div style={{ marginBottom: "20%" }}>
      <TitleWithBack title={t("alarms")} key={alarmConfigurationTitle} />
      <div
        className={
          width <= 575 || height <= 815 ? undefined : "summary-container"
        }
      >
        <Card
          variant="outlined"
          style={{
            marginLeft: width <= 575 || height <= 815 ? "2%" : "10%",
            marginRight: width <= 575 || height <= 815 ? "2%" : "10%",
          }}
        >
          <CardHeader title={t("alarmCalibrationStatus")} />
          <CardContent>
            {alarms.map((a: any, index: number) => {
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      marginBottom: "2%",
                      marginTop: "2%",
                    }}
                  >
                    <div
                      style={{
                        width: width <= 575 || height <= 815 ? `100%` : "350px",
                      }}
                    >
                      <div className="p-style">
                        <div>{checkName(a.name)}:</div>
                        <div>{checkStatus(a.config.enabled) || "---"}</div>
                      </div>
                      <div className="p-style">
                        <div>
                          {a.config.upper_threshold !== ""
                            ? maxLabel
                            : minLabel}
                          :
                        </div>
                        <div>
                          {`${
                            a.config.upper_threshold !== ""
                              ? a.config.upper_threshold
                              : a.config.lower_threshold
                          }` || "---"}
                        </div>
                      </div>
                    </div>
                    <ButtonItem
                      buttonType={primaryType}
                      label={editLabel}
                      buttonOnClick={() => {
                        /*
                        history.push(
                          updateAlarmConfigurationUrl(installation_id, a.id)
                        );
                        */
                      }}
                    />
                  </div>
                  {index !== alarms.length - 1 ? <Divider /> : null}
                </>
              );
            })}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AlarmConfiguration;
