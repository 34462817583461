import "./Navbar.css";
import { adminMenuItems } from "./adminMenuItems";
import { installerMenuItems } from "./installerMenuItems";
import { customerMenuItems } from "./customerMenuItems";
import React, { useEffect, useState } from "react";
import { GetUserProfile } from "../../api/services/userService";
import { MenuType, SubMenuType } from "../types/navbar/types";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  adminRole,
  arrowKey,
  collapseKey,
  dealerRole,
  divKey,
  drawerKey,
  installerRole,
  listKey,
  persistentVariant,
  secondaryListKey,
  textKey,
} from "../../costants/costants";
import { useTranslation } from "react-i18next";
import arrow from "../../images/arrow.svg";
import { dealerMenuItems } from "./dealerMenuItems";

type NavbarProps = {
  SetNavbarIsCollapsed: (collapsed: boolean) => void;
  navbar_collapsed: boolean;
};

const Navbar: React.FC<NavbarProps> = ({
  SetNavbarIsCollapsed,
  navbar_collapsed,
}) => {
  const { t, i18n } = useTranslation();

  const [click, setClick] = useState<string>("");
  const [over, setOver] = useState<string>("");

  const [selectedItem, setSelectedItem] = useState<string>("");
  const [panelOpen, setPanelOpen] = useState<boolean[]>([false]);
  const [menuItem, setMenuItem] = useState<MenuType[]>([]);
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    GetUserProfile().then((res: any) => {
      if (res && res.user && res.user.roles && res.user.roles.length > 0) {
        const role: string = res["user"]["roles"][0];
        if (role.includes(installerRole)) {
          setMenuItem(installerMenuItems(t));
        } else {
          if (role.includes(adminRole)) {
            setPanelOpen([false, false]);
            setMenuItem(adminMenuItems(t));
          } else {
            if (role.includes(dealerRole)) {
              setMenuItem(dealerMenuItems(t));
            } else {
              setMenuItem(customerMenuItems(t));
            }
          }
        }
      }
    });
  }, [i18n.language]);

  return (
    <>
      <Drawer
        key={drawerKey}
        onClose={() => SetNavbarIsCollapsed(true)}
        open={isMobile ? !navbar_collapsed : true}
        variant={!isMobile ? persistentVariant : undefined}
        sx={{ borderRight: "4px solid #56a6f5" }}
      >
        <List style={{ zIndex: -1 }} key={listKey}>
          {menuItem.map((item: MenuType, index: number) => {
            if ((isMobile && index !== 0) || !isMobile) {
              return (
                <div key={divKey(item.key)}>
                  <ListItem
                    button
                    key={item.key}
                    style={{
                      backgroundColor:
                        click === item.key || over === item.key
                          ? "#3E4A56"
                          : undefined,
                      color:
                        click === item.key || over === item.key
                          ? "#cd8133"
                          : "#56a6f5",
                    }}
                    onMouseOver={() => setOver(item.key)}
                    onMouseLeave={() => setOver("")}
                    onClick={() => {
                      setClick(item.key);
                      if (item?.link) {
                        if (typeof item.link === "string") {
                          history.push(item.link);
                        } else {
                          history.push(item.link());
                        }
                      }
                      if (item.elements.length > 0) {
                        let tmp = panelOpen;
                        tmp[index] = !tmp[index];
                        setPanelOpen([...tmp]);
                      } else {
                        setSelectedItem(item.key);
                        SetNavbarIsCollapsed(true);
                      }
                    }}
                    selected={selectedItem === item.key}
                  >
                    <ListItemText
                      key={textKey(item.key)}
                      primary={item.label}
                    />
                    {item.elements.length > 0 ? (
                      <img
                        key={arrowKey}
                        className="arrow"
                        alt={arrowKey}
                        src={arrow}
                      />
                    ) : null}
                  </ListItem>
                  {item.elements.length > 0 ? (
                    <Collapse
                      key={collapseKey(item.key)}
                      in={panelOpen[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List
                        key={secondaryListKey(item.key)}
                        component="div"
                        disablePadding
                      >
                        {item.elements.map((e: SubMenuType) => (
                          <ListItem
                            key={e.key}
                            selected={selectedItem === e.key}
                            button
                            style={{
                              backgroundColor:
                                click === e.key || over === e.key
                                  ? "#3E4A56"
                                  : undefined,
                              color:
                                click === e.key || over === e.key
                                  ? "#cd8133"
                                  : "#56a6f5",
                            }}
                            onMouseOver={() => setOver(e.key)}
                            onMouseLeave={() => setOver("")}
                            onClick={() => {
                              setClick(e.key);
                              if (e?.link) {
                                setSelectedItem(e.key);
                                if (typeof e.link === "string") {
                                  history.push(e.link);
                                } else {
                                  history.push(e.link());
                                }
                                SetNavbarIsCollapsed(true);
                              }
                            }}
                          >
                            <ListItemText
                              key={textKey(e.key)}
                              primary={e.label}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  ) : null}
                </div>
              );
            }
          })}
        </List>
      </Drawer>
    </>
  );
};

export default Navbar;
