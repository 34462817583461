import "./resetPassword.css";
import React, { useEffect, useState } from "react";
import logo from "../../../images/Logo-COMAU-white.png";
import { Alert, AlertTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../../images/Comau_Mate-XT_Frame.png";

type ResultResetPasswordProps = {};

const ResultResetPassword: React.FC<ResultResetPasswordProps> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.backgroundImage = `url(${backgroundImage})`;
    document.body.style.backgroundRepeat = "no-repeat";
    return () => {
      document.body.style.backgroundImage = "";
      document.body.style.backgroundRepeat = "";
    };
  }, []);

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <div className={width <= 768 ? "login-box-responsive" : "login-box"}>
        <div className="image-container">
          <img
            src={logo}
            className={width <= 768 ? "image-responsive" : "image"}
            alt="logo-comau"
            style={{ maxWidth: "130px" }}
          />
        </div>
        <Alert severity="success">
          <AlertTitle>{t("resetPasswordResultTitle")}</AlertTitle>
          {t("resetPasswordResultSubTitle")}
        </Alert>
      </div>
    </>
  );
};

export default ResultResetPassword;
