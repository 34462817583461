import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import React from "react";

type CustomLoadingProps = {};

const CustomLoading: React.FC<CustomLoadingProps> = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}>
      <CircularProgress size={"8rem"} style={{ color: "white" }} />
    </div>
  );
};

export default CustomLoading;
