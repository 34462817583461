import {
  address,
  city,
  confirmPassword,
  country,
  customerAddress,
  customerCity,
  customerCountry,
  customerPhone,
  customerProvince,
  customerZip,
  email,
  name,
  numberType,
  password,
  passwordType,
  phone,
  province,
  selectType,
  zip,
} from "../../../costants/costants";
import {
  confirmPasswordRules,
  emailRules,
  passwordRules,
} from "../../types/form/rules";
import { InputType } from "../../types/form/types";

export const createDealerFields: (t: any) => InputType[] = (t: any) => [
  {
    key: name.key,
    name: name.name,
    label: t("name"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: email.key,
    name: email.name,
    label: t("email"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: password.key,
    name: password.name,
    label: t("password"),
    rules: { required: true, message: t("requiredField") },
    type: passwordType,
  },
  {
    key: confirmPassword.key,
    name: confirmPassword.name,
    label: t("confirmPassword"),
    rules: { required: true, message: t("requiredField") },
    type: passwordType,
  },
  {
    key: customerPhone.key,
    name: customerPhone.name,
    label: t("phone"),
    path: phone.name,
    rules: { required: true, message: t("requiredField") },
    type: numberType,
  },
  {
    key: customerAddress.key,
    name: customerAddress.name,
    label: t("address"),
    path: address.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: customerCity.key,
    name: customerCity.name,
    label: t("city"),
    path: city.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: customerProvince.key,
    name: customerProvince.name,
    label: t("province"),
    path: province.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: customerCountry.key,
    name: customerCountry.name,
    label: t("country"),
    path: country.name,
    rules: { required: true, message: t("requiredField") },
    type: selectType,
  },
  {
    key: customerZip.key,
    name: customerZip.name,
    label: t("zip"),
    path: zip.name,
    rules: { required: true, message: t("requiredField") },
  },
];
