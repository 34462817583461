import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { mateIdConfiguration } from "../../../api/services/installationsService";
import {
  formInputKey,
  textType,
  primaryType,
} from "../../../costants/costants";
import ButtonItem from "../../custom/ButtonItem";
import CustomTitle from "../../custom/CustomTitle";
import FormInput from "../../custom/input/FormInput";

type DeviceConfigurationProps = {};

const DeviceConfiguration: React.FC<DeviceConfigurationProps> = ({}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [mateId, setMateId] = useState<string>("");

  const handleSubmit = useCallback(() => {
    mateIdConfiguration(mateId).then((res) => {
      if (res && res.installation && res.installation.id) {
        enqueueSnackbar(t("addMateIdSuccess"), {
          variant: "success",
          preventDuplicate: true,
        });
        history.push(`/wifi/installations/${res.installation.id}`);
      } else {
        enqueueSnackbar(res.err?.message || t("somethingWentWrong"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    });
  }, [mateId]);

  return (
    <>
      <CustomTitle title={t("addDevice")} />
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Card
            variant="outlined"
            style={{ marginLeft: "25%", marginRight: "25%" }}
          >
            <CardContent>
              <Typography variant="subtitle1">
                {t("descriptionActivationCode")}
              </Typography>
              <FormInput
                key={formInputKey("mateid")}
                name="mateid"
                keyValue="mateid"
                placeholder={t("activationCode")}
                type={textType}
                value={mateId}
                onchange={(e: any) => setMateId(e.target.value)}
              />
              <div className="btn-container">
                <ButtonItem
                  buttonType={primaryType}
                  label={t("ok")}
                  buttonOnClick={handleSubmit}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default DeviceConfiguration;
