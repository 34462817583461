import { GrafanaUrl } from "../../api/constants";
import { isTest } from "../../api/services/httpRequests";

export const idParam = ":id";
export const nameParam = ":name";

export const resetPasswordPageUrlResult = `/reset-result`;
export const recoverPageUrlResult = `/recover-result`;
export const mainUrl = `/`;
export const profileUrl = `/profile`;
export const loginPageUrl = `/login`;
export const resetPasswordPageUrl = `/recover`;
export const recoverPageUrl = `/auth/recover/end`;
export const createInstallerPageUrl = `/installers/new`;
export const createCustomerPageUrl = `/customers/new`;
export const customersListPageUrl = `/customers`;
export const installersListPageUrl = `/installers`;
export const dealersListPageUrl = `/dealers`;
export const updateUserPageUrl = (id: string) => `/users/update/${id}`;
export const createSubscriptionPageUrl = `/subscriptions/new`;
export const installationPageUrl = (id: string) => `/installations/${id}`;
export const subscriptionsListToInstallationPageUrl = `/data/subscriptions`;
export const installationListToLineChartUrl = (id: string) =>
  `/data/subscriptions/${id}/installations`;
export const subscriptionsListToUpdateUrl = `/subscriptions/update`;
export const updateSubscriptionUrl = (id: string) =>
  `/subscriptions/update/${id}`;
export const subscriptionsListToDataHistoryPageUrl = `/history/subscriptions`;
export const installationListToDataHistoryUrl = (id: string) =>
  `/history/subscriptions/${id}/installations`;
export const dataHistoryPanelUrl = (id: string) =>
  `/history/subscriptions/installations/${id}`;
export const createInstallationUrl = `/new_installation`;
export const installersAssociationsUrl = `/subscriptions/installers`;
export const dealersAssociationsUrl = `/subscriptions/dealers`;
export const installationsListToUpdateUrl = `/subscriptions/installations`;
export const updateInstallationUrl = (id: string) =>
  `/installations/${id}/update`;
export const alarmsPageUrl = `/alarms`;
export const deviceConnectionConfigurationUrl = (id: string) =>
  `/installations/${id}/configuration`;
export const technicalTestingUrl = (id: string) =>
  `/installations/${id}/testing`;
export const selectTestsUrl = (id: string) => `/installations/${id}/select`;
export const adminPanelIconsUrl = `/adminpanel`;
export const installerPanelIconsUrl = `/installerpanel`;
export const configurationAlarmsPageUrl = (id: string) =>
  `/${id}/alarms/configuration`;
export const updateAlarmPageUrl = (id: string, name: string) =>
  `/alarms/${id}/update/${name}`;
export const signalConfigurationUrl = (id: string) =>
  `/${id}/signal/configuration`;
export const updateSignalConfigurationUrl = (id: string) =>
  `/${id}/signal/configuration/update`;
export const alarmConfigurationUrl = (id: string) =>
  `/${id}/alarm/configuration`;
export const updateAlarmConfigurationUrl = (
  device_id: string,
  alarm_id: string
) => `/${device_id}/alarm/${alarm_id}/configuration`;
export const subscriptionsListToWifiUrl = `/wifi/subscriptions`;
export const installationsListToWifiUrl = (id: string) =>
  `/wifi/subscriptions/${id}/installations`;
export const installationWifiUrl = (id: string) => `/wifi/installations/${id}`;
export const createDealer = `/dealers/new`;
export const deviceConfigurationUrl = `/device_configuration/installations`;
export const subscriptionsListToLogsUrl = `/logs/subscriptions`;
export const installationsListToLogsUrl = (id: string) =>
  `/logs/subscriptions/${id}/installations`;
export const logsUrl = (id: string) => `/logs/installations/${id}`;
export const printUrl = (id: string) =>
  `/subscriptions/installations/${id}/print`;

export const routes = {
  mainPage: mainUrl,
  adminPanel: adminPanelIconsUrl,
  configuratorPanel: installerPanelIconsUrl,
  login: loginPageUrl,
  recoverStart: resetPasswordPageUrl,
  recoverEnd: recoverPageUrl,
  resetResult: resetPasswordPageUrlResult,
  recoverResult: recoverPageUrlResult,
  profile: profileUrl,
  addInstaller: createInstallerPageUrl,
  addCustomer: createCustomerPageUrl,
  installationPage: installationPageUrl(idParam),
  customersList: customersListPageUrl,
  installersList: installersListPageUrl,
  dealersList: dealersListPageUrl,
  updateUser: updateUserPageUrl(idParam),
  installationsListToLineChart: installationListToLineChartUrl(idParam),
  installationsListToDataHistory: installationListToDataHistoryUrl(idParam),
  addSubscription: createSubscriptionPageUrl,
  subscriptionsListToUpdate: subscriptionsListToUpdateUrl,
  updateSubscription: updateSubscriptionUrl(idParam),
  subscriptionsListToDataHistory: subscriptionsListToDataHistoryPageUrl,
  dataHistoryPanel: dataHistoryPanelUrl(idParam),
  subscriptionsListToInstallationPage: subscriptionsListToInstallationPageUrl,
  installersAssociations: installersAssociationsUrl,
  dealersAssociations: dealersAssociationsUrl,
  addInstallation: createInstallationUrl,
  installationsListToUpdate: installationsListToUpdateUrl,
  updateInstallation: updateInstallationUrl(idParam),
  alarms: alarmsPageUrl,
  deviceConnectionConfiguration: deviceConnectionConfigurationUrl(idParam),
  technicalTesting: technicalTestingUrl(idParam),
  selectTests: selectTestsUrl(idParam),
  alarmsConfiguration: configurationAlarmsPageUrl(idParam),
  alarmsUpdate: updateAlarmPageUrl(idParam, nameParam),
  signalConfiguration: signalConfigurationUrl(idParam),
  updateSignalConfiguration: updateSignalConfigurationUrl(idParam),
  alarmConfiguration: alarmConfigurationUrl(idParam),
  updateAlarmConfiguration: updateAlarmConfigurationUrl(idParam, nameParam),
  subscriptionsListToWifi: subscriptionsListToWifiUrl,
  installationsListToWifi: installationsListToWifiUrl(idParam),
  installationWifi: installationWifiUrl(idParam),
  createDealer: createDealer,
  deviceConfiguration: deviceConfigurationUrl,
  subscriptionsListToLogs: subscriptionsListToLogsUrl,
  installationsListToLogs: installationsListToLogsUrl(idParam),
  installationLogs: logsUrl(idParam),
  print: printUrl(idParam),
};

export const roles = {
  admin: "admin",
  installer: "installer",
  customer: "customer",
};

export const tabList = [
  {
    key: "avg",
    tab: "Media",
  },
  {
    key: "sum",
    tab: "Somma",
  },
];

export const lineColors = {
  sum: "#8884D8",
  avg: "#8884D8",
  min: "#008000",
  max: "#FF6347",
};

export const checkbox = [
  { label: "avg", value: "avg" },
  { label: "max", value: "max" },
  { label: "min", value: "min" },
];

export const rowsPerTablePage: number = 15;
export const refreshTime: string = isTest
  ? "30s"
  : window._env_.DASHBOARD_REFRESH_TIME!; // 30s

export const enDashboardUrl: string = isTest
  ? " /d/mate_xt_40/single_device?orgId=1&kiosk=tv&var-device_id="
  : window._env_.DASHBOARD_URL_EN!;
export const itDashboardUrl: string = isTest
  ? " /d/mate_xt_40/single_device?orgId=1&kiosk=tv&var-device_id="
  : window._env_.DASHBOARD_URL_IT!;

export const model1: string = "model_1";
export const enUrl: (id: string) => string = (id) =>
  `${GrafanaUrl}${enDashboardUrl}${id}&refresh=${refreshTime}`;
export const itUrl: (id: string) => string = (id) =>
  `${GrafanaUrl}${itDashboardUrl}${id}&refresh=${refreshTime}`;
