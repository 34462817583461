import { tableColumn } from "./tableColumns";
import "./SubscriptionsListTable.css";
import "../../../App.css";
import { getAllSubscriptions } from "../../../api/services/subscriptionService";
import React, { useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useHistory } from "react-router-dom";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { expiryDate, subscriptions } from "../../../costants/costants";
import { useTranslation } from "react-i18next";

type SubscriptionTableProps = {
  SetPopupObject: (obj: any) => void;
  link: (p: string) => string;
};

const SubscriptionsListTable: React.FC<SubscriptionTableProps> = ({
  SetPopupObject,
  link,
}) => {
  const { t } = useTranslation();

  const [data, setData] = useState<Object[]>([]);
  const history = useHistory();
  const linkFunction: (value: string) => void = (value: string) => {
    history.push(link(value));
  };

  return (
    <>
      <CustomTitle title={t("fleetsList")} />
      <ResponsiveTable
        columns={tableColumn(t, data, SetPopupObject, linkFunction)}
        getPageAndSortedData={getAllSubscriptions}
        defaultSort={expiryDate.key}
        responseDataName={subscriptions}
        setData={setData}
      />
    </>
  );
};

export default SubscriptionsListTable;
