import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import "../../../App.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  dateType,
  iconType,
  linkType,
  stringType,
} from "../../../utilities/utilities";
import {
  address,
  city,
  country,
  createdAt,
  email,
  name,
  province,
  userInfo,
  zip,
} from "../../../costants/costants";
import { IconButton, Tooltip } from "@mui/material";
import IconConfItem from "../../custom/IconConfItem";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DeleteUser } from "../../../api/services/userService";

export const customersListColumnsAdmin: (
  t: any,
  rowData: Object[],
  SetPopupObject: (obj: any) => void,
  linkFunction: (value: string) => void,
  enqueueSnackbar: any
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  linkFunction,
  enqueueSnackbar
) => [
  {
    label: t("name"),
    name: name.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        return (
          <div className="details-container">
            <Tooltip title={t("details")}>
              <IconButton
                onClick={() => {
                  SetPopupObject([rowData[data.rowIndex]]);
                }}
              >
                <InfoOutlinedIcon className="details-icon" />
              </IconButton>
            </Tooltip>
            <div className="details">
              <ResponsiveTableCell
                type={linkType}
                value={value}
                link={() => {
                  linkFunction(data.tableData[data.rowIndex].uid);
                }}
              />
            </div>
          </div>
        );
      },
    },
  },
  {
    label: t("email"),
    name: email.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("address"),
    name: `${userInfo}.${address.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("city"),
    name: `${userInfo}.${city.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("province"),
    name: `${userInfo}.${province.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("country"),
    name: `${userInfo}.${country.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("zip"),
    name: `${userInfo}.${zip.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("creationDate"),
    name: createdAt.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={dateType} value={value} />
      ),
    },
  },
  {
    label: t("delete"),
    name: "uid",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell
          type={iconType}
          value=""
          icon={
            <IconConfItem
              icon={<DeleteOutlineOutlinedIcon />}
              questionLabel={t("deleteUserQuestion")}
              buttonOnConfirm={() => {
                DeleteUser(value).then((res: any) => {
                  if (res && !res.err) {
                    enqueueSnackbar(t("userDeleteSuccess"), {
                      variant: "success",
                      preventDuplicate: true,
                    });
                    window.location.reload();
                  } else {
                    enqueueSnackbar(
                      res?.err?.message || t("somethingWentWrong"),
                      {
                        variant: "error",
                        preventDuplicate: true,
                      }
                    );
                  }
                });
              }}
            />
          }
        />
      ),
    },
  },
];

export const customersListColumnsInstaller: (
  t: any,
  rowData: Object[],
  SetPopupObject: (obj: any) => void,
  linkFunction: (value: string) => void
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  linkFunction
) => [
  {
    label: t("name"),
    name: name.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        return (
          <div className="details-container">
            <Tooltip title={t("details")}>
              <IconButton
                onClick={() => {
                  SetPopupObject([rowData[data.rowIndex]]);
                }}
              >
                <InfoOutlinedIcon className="details-icon" />
              </IconButton>
            </Tooltip>
            <div className="details">
              <ResponsiveTableCell
                type={linkType}
                value={value}
                link={() => {
                  linkFunction(data.tableData[data.rowIndex].uid);
                }}
              />
            </div>
          </div>
        );
      },
    },
  },
  {
    label: t("email"),
    name: email.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("address"),
    name: `${userInfo}.${address.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("city"),
    name: `${userInfo}.${city.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("province"),
    name: `${userInfo}.${province.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("country"),
    name: `${userInfo}.${country.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("zip"),
    name: `${userInfo}.${zip.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("creationDate"),
    name: createdAt.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={dateType} value={value} />
      ),
    },
  },
];
