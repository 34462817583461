export const dataRealTimeTitle = "Real Time Data";
export const dataHistoryTitle = "Data History";
export const alarmsTitle = "Alarms";
export const adminPanelTitle = "Admin Panel";
export const addInstallerTitle = "Create Installer";
export const updateInstallerTitle = "Edit Installer";
export const addSubscriptionTitle = "Create Fleet";
export const updateSubscriptionTitle = "Edit Fleet";
export const addUserTitle = "Create Customer";
export const updateUserTitle = "Edit Customer";
export const addInstallationTitle = "Create Device";
export const updateInstallationTitle = "Edit Device";
export const selectInstallationTitle = "Select Device to Change";
export const installerListTitle = "Installers List";
export const subscriptionsListTitle = "Fleets List";
export const installationsListTitle = "Devices List";
export const connectionTitle = "Box Configuration";
export const signalConfigurationTitle = "Signal Configuration";
export const ConfiguratorTitle = "Configurator";
export const technicalTestingTitle: string = "Technical Testing";
export const alarmConfigurationTitle: string = "Alarms";
export const updateAlarmConfigurationTitle: string =
  "Update Alarm Configuration";
