import "./UpdateSubscription.css";
import "../../../App.css";
import ButtonItem from "../../custom/ButtonItem";
import ButtonConfItem from "../../custom/ButtonConfItem";
import React, { useEffect, useState } from "react";
import {
  getSubscription,
  updateSubscriptionExpDate,
  updateSubscriptionInformation,
} from "../../../api/services/subscriptionService";
import {
  Subscription,
  UpdateSubscriptionInfoRequest,
} from "../../../api/requests/subscription";
import { roles } from "../costants";
import FormInput from "../../custom/input/FormInput";
import { engCountries, itaCountries } from "../../custom/select/countries";
import FormSelect from "../../custom/select/FormSelect";
import OperationResult from "../../custom/OperationResult";
import {
  divider,
  errorStatus,
  expiryDate,
  formInputKey,
  primaryType,
  selectType,
  successStatus,
  textType,
  updateSubscriptionTitleKey,
  updateSubscriptioSummaryKey,
} from "../../../costants/costants";
import CustomerSummary from "./CustomerSummary";
import TitleWithBack from "../../custom/TitleWithBack";
import { companyInfo } from "./inputs";
import { Card, CardContent, Grid, Divider, TextField } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";

type UpdateSubscriptionProps = {
  subscriptionId: string;
  role: string;
};

const UpdateSubscription: React.FC<UpdateSubscriptionProps> = ({
  role,
  subscriptionId,
}) => {
  const { t, i18n } = useTranslation();

  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription | null>(null);
  const [values, setValues] = useState({
    subscription_name: "",
    company_name: "",
    company_sdi: "",
    company_pec: "",
    company_vat_number: "",
    company_phone: "",
    company_address: "",
    company_city: "",
    company_province: "",
    company_country: "",
    company_zip_code: "",
    expiry_date: "",
  });
  const [status, setStatus] = useState<string>("");

  const goBack = () => {
    setStatus("");
  };

  useEffect(() => {
    getSubscription({ subscription_id: subscriptionId }).then((res: any) => {
      if (res && res.subscription && res.subscription.company_info) {
        setValues({
          subscription_name: res.subscription.name,
          company_name: res.subscription.company_info.name,
          company_sdi: res.subscription.company_info.sdi,
          company_pec: res.subscription.company_info.pec,
          company_vat_number: res.subscription.company_info.vat_number,
          company_phone: res.subscription.company_info.phone,
          company_address: res.subscription.company_info.address,
          company_city: res.subscription.company_info.city,
          company_province: res.subscription.company_info.province,
          company_country: res.subscription.company_info.country,
          company_zip_code: res.subscription.company_info.zip_code,
          expiry_date: res.subscription.expiry_date,
        });
        setSelectedSubscription(res.subscription);
      }
    });
  }, []);

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleSubmit = () => {
    const req: UpdateSubscriptionInfoRequest = {
      subscription_id: subscriptionId,
      name: values.subscription_name,
      company_info: {
        name: values.company_name,
        sdi: values.company_sdi,
        pec: values.company_pec,
        vat_number: values.company_vat_number,
        phone: values.company_phone,
        address: values.company_address,
        city: values.company_city,
        province: values.company_province,
        country: values.company_country,
        zip_code: values.company_zip_code,
      },
    };
    updateSubscriptionInformation(req).then((res) => {
      if (res && !res.err) {
        setStatus("success");
      } else {
        setStatus(res?.err?.message || "");
      }
    });
    if (role === roles.admin) {
      updateSubscriptionExpDate({
        subscription_id: subscriptionId,
        exp_date: new Date(values.expiry_date).toISOString(),
      });
    }
  };

  if (!selectedSubscription) {
    return null;
  }

  if (status) {
    return (
      <OperationResult
        onClickProp={status === errorStatus ? goBack : undefined}
        status={status === "success" ? "success" : "error"}
        operation={"update"}
        entity={"subscription"}
        error={status}
      />
    );
  }

  return (
    <>
      <TitleWithBack title={t("editFleet")} key={updateSubscriptionTitleKey} />
      <CustomerSummary
        customer_id={selectedSubscription.customer_id}
        key={updateSubscriptioSummaryKey}
      />
      <Card
        variant="outlined"
        style={{
          marginLeft: width <= 575 || height <= 815 ? "2%" : "25%",
          marginRight: width <= 575 || height <= 815 ? "2%" : "25%",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            {companyInfo(
              t,
              i18n.language === "en" ? engCountries : itaCountries
            ).map((el) => {
              return (
                <Grid item xs={12} md={4}>
                  {el.type !== selectType ? (
                    <FormInput
                      key={formInputKey(el.key)}
                      keyValue={el.key}
                      name={el.name}
                      value={(values as any)[el.name]}
                      placeholder={el.label}
                      type={el.type ?? textType}
                      rules={el.rules}
                      onchange={(e: any) => {
                        setValues({ ...values, [el.name]: e.target.value });
                      }}
                    />
                  ) : (
                    <FormSelect
                      key={formInputKey(el.key)}
                      keyValue={el.key}
                      placeholder={el.label}
                      name={el.name}
                      options={el.options || []}
                      value={(values as any)[el.name]}
                      rules={el.rules}
                      onChange={(e: any) => {
                        setValues({ ...values, [el.name]: e.target.value });
                      }}
                    />
                  )}
                </Grid>
              );
            })}
            {role === roles.admin ? (
              <>
                <Grid item xs={12}>
                  <Divider
                    style={{ marginTop: "2%", marginBottom: "1%" }}
                    textAlign="left"
                  >
                    {divider.label}
                  </Divider>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    size="small"
                    style={{ marginTop: "4%" }}
                    label={expiryDate.label}
                    type="date"
                    value={moment(values.expiry_date).format("YYYY-MM-DD")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e: any) => {
                      setValues({ ...values, expiry_date: e.target.value });
                    }}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
          <div className="btn-container">
            <ButtonConfItem
              buttonLabel={t("reset")}
              buttonOnConfirm={() => {
                setValues({
                  subscription_name: "",
                  company_name: "",
                  company_sdi: "",
                  company_pec: "",
                  company_vat_number: "",
                  company_phone: "",
                  company_address: "",
                  company_city: "",
                  company_province: "",
                  company_country: "",
                  company_zip_code: "",
                  expiry_date: "",
                });
              }}
              questionLabel={t("question")}
            />
            <ButtonItem
              buttonType={primaryType}
              label={t("ok")}
              buttonOnClick={handleSubmit}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default UpdateSubscription;
