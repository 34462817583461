import React, { useEffect, useState } from "react";
import "./UpdateInstallation.css";
import { useHistory } from "react-router";
import {
  alarmConfigurationUrl,
  deviceConnectionConfigurationUrl,
  printUrl,
  selectTestsUrl,
} from "../costants";
import { primaryType } from "../../../costants/costants";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { Settings } from "@mui/icons-material";
import ButtonItem from "../../custom/ButtonItem";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

type CardOptionProps = {
  installationId: string;
};

const CardOption: React.FC<CardOptionProps> = ({ installationId }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const [electron, setElectron] = useState(false);

  useEffect(() => {
    let userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(" electron/") > -1) {
      setElectron(true);
    } else {
      setElectron(false);
    }
  }, []);

  const logError = () => {
    enqueueSnackbar(t("configuratorError"), {
      variant: "error",
      preventDuplicate: true,
    });
  };

  return (
    <>
      <Card
        variant="outlined"
        style={{ marginLeft: "22%", marginRight: "22%", marginTop: "5%" }}
      >
        <CardHeader
          avatar={<Settings />}
          title={
            <Typography variant="h6">{t("deviceOptionsTitle")}</Typography>
          }
        />
        <CardContent>
          <Grid container flexDirection="column">
            <Grid item>
              <Typography variant="subtitle1">
                {t("deviceOptionsSubTitle")}
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <ButtonItem
                  buttonType={primaryType}
                  label={t("deviceConfiguration")}
                  buttonOnClick={() =>
                    electron
                      ? history.push(
                          deviceConnectionConfigurationUrl(installationId)
                        )
                      : logError()
                  }
                />
              </Grid>
              <Grid item>
                <ButtonItem
                  buttonType={primaryType}
                  label={t("technicalTesting")}
                  buttonOnClick={() =>
                    electron
                      ? history.push(selectTestsUrl(installationId))
                      : logError()
                  }
                />
              </Grid>
              <Grid item>
                <ButtonItem
                  buttonType={primaryType}
                  label={t("alarms")}
                  buttonOnClick={() =>
                    history.push(alarmConfigurationUrl(installationId))
                  }
                />
              </Grid>
              <Grid item>
                <ButtonItem
                  buttonType={primaryType}
                  label={t("print")}
                  buttonOnClick={() => history.push(printUrl(installationId))}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CardOption;
