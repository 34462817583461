import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import { dateType, stringType } from "../../../utilities/utilities";
import "../../../App.css";
import {
  activationLabel,
  active,
  address,
  companyInfoLabel,
  deactivationLabel,
  deactive,
  disabledLabel,
  enabledLabel,
  expiryDate,
  primaryType,
  status,
  vat,
  zip,
  name,
} from "../../../costants/costants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, IconButton, Tooltip } from "@mui/material";
import ButtonConfItem from "../../custom/ButtonConfItem";

export const tableColumn: (
  t: any,
  rowData: Object[],
  SetPopupObject: (obj: any) => void,
  manageSubscription: (
    subscription_id: string,
    requested_status: string
  ) => void
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  manageSubscription
) => [
  {
    label: t("name"),
    name: name.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="details-container">
          <Tooltip title={t("details")}>
            <IconButton
              onClick={() => {
                const tmp: any = rowData[data.rowIndex];
                const objects: any[] = [
                  {
                    name: tmp.name,
                    status: tmp.status,
                    created_at: tmp.created_at,
                    expiry_date: tmp.expiry_date,
                  },
                  {
                    detail_title: t("companyInfo"),
                    company: tmp.company_info.name,
                    address: tmp.company_info.address,
                    city: tmp.company_info.city,
                    province: tmp.company_info.province,
                    country: tmp.company_info.country,
                    zip_code: tmp.company_info.zip_code,
                    vat_number: tmp.company_info.vat_number,
                    phone: tmp.company_info.phone,
                    sdi: tmp.company_info.sdi,
                    pec: tmp.company_info.pec,
                  },
                  {
                    detail_title: t("customerInfo"),
                    name: tmp.customer_name,
                    address: tmp.customer_info.address,
                    city: tmp.customer_info.city,
                    province: tmp.customer_info.province,
                    country: tmp.customer_info.country,
                    zip_code: tmp.customer_info.zip_code,
                    phone: tmp.customer_info.phone,
                  },
                ];
                SetPopupObject(objects);
              }}
            >
              <InfoOutlinedIcon className="details-icon" />
            </IconButton>
          </Tooltip>
          <div className="details">
            <ResponsiveTableCell type={stringType} value={value} />
          </div>
        </div>
      ),
    },
  },
  {
    label: t("email"),
    name: "customer_email",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("company"),
    name: companyInfoLabel,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].company_info.name}
        />
      ),
    },
  },
  {
    label: t("vat"),
    name: `${companyInfoLabel}.${vat.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("address"),
    name: `${companyInfoLabel}.${address.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={`${value}, ${
            data.tableData[data.rowIndex].company_info.city
          }, ${data.tableData[data.rowIndex].company_info.province}`}
        />
      ),
    },
  },
  {
    label: t("zip"),
    name: `${companyInfoLabel}.${zip.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("status"),
    name: status.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell
          type={stringType}
          value={value === enabledLabel ? active : deactive}
        />
      ),
    },
  },
  {
    label: t("expiration"),
    name: expiryDate.key,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={dateType}
          value={
            data.tableData[data.rowIndex].status === enabledLabel ? value : ""
          }
        />
      ),
    },
  },
  {
    label: t("action"),
    name: status.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        const status: string = value;
        const requestedStatus: string =
          data.tableData[data.rowIndex].requested_status;
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {requestedStatus === activationLabel ? (
              <Button variant="outlined">{t("activationProgress")}</Button>
            ) : requestedStatus === deactivationLabel ? (
              <Button variant="outlined">{t("deactivationProgress")}</Button>
            ) : status === disabledLabel ? (
              <ButtonConfItem
                buttonType={primaryType}
                buttonLabel={t("requestActivation")}
                buttonOnConfirm={() => {
                  manageSubscription(
                    data.tableData[data.rowIndex].id,
                    activationLabel
                  );
                }}
              />
            ) : (
              <ButtonConfItem
                buttonType={primaryType}
                buttonLabel={t("requestDeactivation")}
                buttonOnConfirm={() => {
                  manageSubscription(
                    data.tableData[data.rowIndex].id,
                    deactivationLabel
                  );
                }}
              />
            )}
          </div>
        );
      },
    },
  },
];
