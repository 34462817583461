import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import { DealerRelation } from "../../../api/requests/userService";
import "../../../App.css";
import { iconType, stringType } from "../../../utilities/utilities";
import {
  customerEmailRel,
  customerNameRel,
  dealerEmailRel,
  dealerNameRel,
} from "../../../costants/costants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Tooltip } from "@mui/material";
import IconConfItem from "../../custom/IconConfItem";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export const dealersListColumns: (
  t: any,
  rowData: Object[],
  SetPopupObject: (obj: any) => void,
  deleteRelation: (record: DealerRelation) => void
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  deleteRelation
) => [
  {
    label: t("dealerName"),
    name: dealerNameRel.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="details-container">
          <Tooltip title={t("details")}>
            <IconButton
              onClick={() => {
                SetPopupObject([rowData[data.rowIndex]]);
              }}
            >
              <InfoOutlinedIcon className="details-icon" />
            </IconButton>
          </Tooltip>
          <div className="details">
            <ResponsiveTableCell type={stringType} value={value} />
          </div>
        </div>
      ),
    },
  },
  {
    label: t("dealerEmail"),
    name: dealerEmailRel.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("customerName"),
    name: customerNameRel.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("customerEmail"),
    name: customerEmailRel.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("disassociate"),
    name: "",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={iconType}
          value={""}
          icon={
            <IconConfItem
              icon={<DeleteOutlineOutlinedIcon />}
              buttonOnConfirm={() =>
                deleteRelation(data.tableData[data.rowIndex])
              }
            />
          }
        />
      ),
    },
  },
];
