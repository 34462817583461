import ButtonItem from "../../custom/ButtonItem";
import { inputs } from "./inputs";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { resetPassword } from "../../../api/services/loginService";
import { loginPageUrl, resetPasswordPageUrlResult } from "../costants";
import logo from "../../../images/Logo-COMAU-white.png";
import { TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../../images/Comau_Mate-XT_Frame.png";

type ResetPanelProps = {};

const ResetPassword: React.FC<ResetPanelProps> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.backgroundImage = `url(${backgroundImage})`;
    document.body.style.backgroundRepeat = "no-repeat";
    return () => {
      document.body.style.backgroundImage = "";
      document.body.style.backgroundRepeat = "";
    };
  }, []);

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [email, setEmail] = useState<string>("");

  const history = useHistory();
  const submit = () => {
    resetPassword({ email: email }).then((res) => {
      if (res && !res.err) {
        history.push(resetPasswordPageUrlResult);
      }
    });
  };

  const StyledTextField = {
    "& label": {
      color: "white",
    },
    "&:hover label": {
      fontWeight: 700,
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  };

  return (
    <div className={width <= 768 ? "login-box-responsive" : "login-box"}>
      <div className="image-container">
        <img
          src={logo}
          className={width <= 768 ? "image-responsive" : "image"}
          alt="logo-comau"
          style={{ maxWidth: "130px" }}
        />
      </div>
      <Typography
        style={{ fontWeight: "bold", marginTop: "10%", color: "#56a6f5" }}
        variant="h6"
      >
        {t("resetPassword")}
      </Typography>
      {inputs(t).map((el) => {
        return (
          <TextField
            inputProps={{ "data-testid": el.key }}
            type={el.type}
            size="small"
            id={el.key}
            name={el.name}
            label={el.label}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            required
            fullWidth
            sx={StyledTextField}
            InputProps={{ sx: { color: "white" } }}
            style={{ marginTop: "4%", paddingRight: "4%" }}
          />
        );
      })}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "4%",
          marginBottom: "4%",
          paddingRight: "4%",
        }}
      >
        <ButtonItem
          buttonType="outlined"
          label={t("reset")}
          buttonOnClick={submit}
        />
        <Link className="login-form-forgot" to={loginPageUrl}>
          {t("backToLogin")}
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
