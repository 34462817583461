import { installationFields } from "./inputs";
import ButtonItem from "../../custom/ButtonItem";
import ButtonConfItem from "../../custom/ButtonConfItem";
import React, { useEffect, useState } from "react";
import {
  getInstallation,
  UpdateInstallation as updateInstallationApi,
} from "../../../api/services/installationsService";
import { Installation } from "../../../api/requests/installationsService";
import "./UpdateInstallation.css";
import CardOption from "./CardOption";
import FormInput from "../../custom/input/FormInput";
import OperationResult from "../../custom/OperationResult";
import {
  cardKey,
  errorStatus,
  formInputKey,
  primaryType,
  selectType,
  successStatus,
  textType,
  updateInstallationBtnKey,
  updateInstallationTitleKey,
} from "../../../costants/costants";
import FormSelect from "../../custom/select/FormSelect";
import TitleWithBack from "../../custom/TitleWithBack";
import { Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";

type UpdateInstallationProps = {
  installationId: string;
};

const UpdateInstallation: React.FC<UpdateInstallationProps> = ({
  installationId,
}) => {
  const { t } = useTranslation();
  const goBack = () => setStatus("");

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  const [status, setStatus] = useState<string>("");
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [values, setValues] = useState({
    name: "",
    model: "lh",
    activeBoxSn: "",
    mateModel: "",
    mateSn: "",
    customer: "",
  });
  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  useEffect(() => {
    getInstallation({ id: installationId }).then((res: any) => {
      if (res && res.installation && res.installation.installation_info) {
        setValues({
          name: res.installation.name,
          model: String(res.installation.model)
            .toLocaleLowerCase()
            .includes("lh")
            ? "lh"
            : "rh",
          activeBoxSn: res.installation.installation_info.active_box_sn,
          mateModel: res.installation.installation_info.mate_model,
          mateSn: res.installation.installation_info.mate_sn,
          customer: res.installation.installation_info.customer,
        });
        setSelectedInstallation(res.installation);
      } else {
        setStatus(res?.err?.message || "");
      }
    });
  }, []);

  const lhValue: string = "AB(LH)-CR82475800";
  const rhValue: string = "AB(RH)-CR82475700";

  const submit = () => {
    updateInstallationApi({
      id: selectedInstallation?.id ?? "",
      model: values.model === "lh" ? lhValue : rhValue,
      installation_info: {
        active_box_sn: values.activeBoxSn,
        mate_model: values.mateModel,
        mate_sn: values.mateSn,
        customer: values.customer,
      },
    }).then((res) => {
      if (res && !res.err) {
        setStatus("success");
      } else {
        setStatus(res?.err?.message || "");
      }
    });
  };

  if (!selectedInstallation) {
    return null;
  }

  if (status) {
    return (
      <OperationResult
        onClickProp={status === errorStatus ? goBack : undefined}
        status={status === "success" ? "success" : "error"}
        operation={"update"}
        entity={"installation"}
        error={status}
      />
    );
  }
  return (
    <>
      <TitleWithBack title={t("editDevice")} key={updateInstallationTitleKey} />
      <Card
        variant="outlined"
        style={{
          marginLeft: width <= 575 || height <= 815 ? "2%" : "25%",
          marginRight: width <= 575 || height <= 815 ? "2%" : "25%",
        }}
      >
        <CardContent>
          {installationFields(t).map((el) => {
            if (el.type === selectType) {
              return (
                <FormSelect
                  rules={el.rules}
                  key={formInputKey(el.key)}
                  keyValue={el.key}
                  name={el.name}
                  placeholder={el.label}
                  options={
                    el.name === "mateModel"
                      ? (el?.options || []).filter((opt: any) =>
                          String(Object.keys(opt)[0])
                            .toLocaleLowerCase()
                            .includes(values.model)
                        )
                      : el?.options || []
                  }
                  value={(values as any)[el.name]}
                  onChange={(e: any) => {
                    setValues({ ...values, [el.name]: e.target.value });
                  }}
                />
              );
            } else {
              return (
                <FormInput
                  rules={el.rules}
                  key={formInputKey(el.key)}
                  value={(values as any)[el.name]}
                  keyValue={el.key}
                  name={el.name}
                  placeholder={el.label}
                  type={el.type ?? textType}
                  onchange={(e: any) => {
                    setValues({ ...values, [el.name]: e.target.value });
                  }}
                />
              );
            }
          })}
          <div className="btn-container" key={updateInstallationBtnKey}>
            <ButtonConfItem
              buttonLabel={t("reset")}
              buttonOnConfirm={() => {
                setValues({
                  name: "",
                  model: "lh",
                  activeBoxSn: "",
                  mateModel: "",
                  mateSn: "",
                  customer: "",
                });
              }}
              questionLabel={t("question")}
            />
            <ButtonItem
              buttonType={primaryType}
              label={t("ok")}
              buttonOnClick={submit}
            />
          </div>
        </CardContent>
      </Card>
      {width > 575 ? (
        <CardOption installationId={selectedInstallation.id} key={cardKey} />
      ) : null}
    </>
  );
};

export default UpdateInstallation;
