import React, { useCallback, useEffect, useState } from "react";
import TitleWithBack from "../../custom/TitleWithBack";
import { updateAlarmConfigurationTitle } from "../title";
import {
  divKey,
  formInputKey,
  htmlForName,
  labelKey,
  primaryType,
  signalConfigurationBtnDivKey,
} from "../../../costants/costants";
import {
  getAlarmsCalibration,
  updateAlarmsCalibration,
} from "../../../api/services/installationsService";
import FormInput from "../../custom/input/FormInput";
import ButtonConfItem from "../../custom/ButtonConfItem";
import ButtonItem from "../../custom/ButtonItem";
import { AlarmRequest } from "../../../api/requests/installationsService";
import { useHistory } from "react-router";
import { FormControlLabel, Switch } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

type UpdateAlarmConfigurationProps = {
  installation_id: string;
  alarm_id: string;
};

const UpdateAlarmConfiguration: React.FC<UpdateAlarmConfigurationProps> = ({
  installation_id,
  alarm_id,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(true);
  const [alarm, setAlarm] = useState<any>({});
  useEffect(() => {
    getAlarmsCalibration(installation_id).then((res: any) => {
      if (res && res.alarms) {
        const tmp_alarm: any[] = (res.alarms as any[]).filter(
          (a) => a.id === alarm_id
        );
        if (tmp_alarm.length === 1) {
          setAlarm(tmp_alarm[0]);
        }
      }
      setLoading(false);
    });
  }, []);

  const [values, setValues] = useState({
    duration: "",
    threshold: "",
    enabled: "",
  });

  const history = useHistory();

  const handleSubmit = useCallback(() => {
    const request: AlarmRequest = {
      config_id: alarm_id,
      config: {
        enabled: Boolean(values.enabled),
        duration: Number(values.duration),
      },
    };
    if (alarm.config.upper_threshold !== "") {
      request.config.upper_threshold = String(values.threshold);
    }
    if (alarm.config.lower_threshold !== "") {
      request.config.lower_threshold = String(values.threshold);
    }
    updateAlarmsCalibration(installation_id, request).then((res: any) => {
      if (res && !res.err) {
        enqueueSnackbar(t("alarmUpdateSuccess"), {
          variant: "success",
          preventDuplicate: true,
        });
        history.goBack();
      } else {
        enqueueSnackbar(res.err?.message || t("somethingWentWrong"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    });
  }, [alarm, values]);

  if (loading) {
    return null;
  }

  return (
    <>
      <TitleWithBack
        title={t("updateAlarmConfigurator")}
        key={updateAlarmConfigurationTitle}
      />
      <div className="my-signalconfiguration-container my-signalconfiguration-container-responsive">
        <div className="form__group field" key={divKey("enabled")}>
          <label
            key={labelKey("enabled")}
            htmlFor={htmlForName}
            className="form__label"
          >
            {t("enabled")}
          </label>
          <FormControlLabel
            name="enabled"
            control={<Switch defaultChecked={alarm.config.enabled} />}
            label={
              alarm.config.enabled ? (
                <h3 style={{ color: "white" }}>
                  {t("enabled").toLocaleUpperCase()}
                </h3>
              ) : (
                <h3 style={{ color: "white" }}>
                  {t("disabled").toLocaleUpperCase()}
                </h3>
              )
            }
            value={values.enabled}
            onChange={(e: any) => {
              setValues({ ...values, enabled: e.target.value });
            }}
          />
          <FormInput
            key={formInputKey("duration")}
            name="duration"
            onchange={(e: any) => {
              setValues({ ...values, duration: e.target.value });
            }}
            placeholder={t("minDuration")}
            type="number"
            value={alarm.config.duration}
            keyValue="duration"
            rules={{ required: true, message: "Required field!" }}
          />
          <FormInput
            key={formInputKey("threshold")}
            name="threshold"
            onchange={(e: any) => {
              setValues({ ...values, duration: e.target.value });
            }}
            placeholder={
              alarm.config.upper_threshold !== ""
                ? t("upperThreshold")
                : t("lowerThreshold")
            }
            type="number"
            value={
              alarm.config.upper_threshold !== ""
                ? Number(alarm.config.upper_threshold)
                : Number(alarm.config.lower_threshold)
            }
            keyValue="threshold"
            rules={{ required: true, message: "Required field!" }}
          />
        </div>
        <div className="btn-container" key={signalConfigurationBtnDivKey}>
          <ButtonConfItem
            buttonLabel={t("reset")}
            buttonOnConfirm={() => {
              setValues({ duration: "", threshold: "", enabled: "" });
            }}
            questionLabel={t("question")}
          />
          <ButtonItem
            buttonType={primaryType}
            label={t("ok")}
            buttonOnClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default UpdateAlarmConfiguration;
