import React, { useCallback, useEffect, useState } from "react";
import { Subscription } from "../../../api/requests/subscription";
import {
  getAllSubscriptions,
  updateSubscriptionStatus,
} from "../../../api/services/subscriptionService";
import "../../../App.css";
import { updateSubscriptionUrl } from "../costants";
import CustomTitle from "../../custom/CustomTitle";
import "./SelectSubscription.css";
import { useHistory } from "react-router-dom";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { subscriptionsTableColumns } from "./inputs";
import { disabledLabel, enabledLabel } from "../../../costants/costants";
import { useSnackbar } from "notistack";
import { Switch } from "@mui/material";
import IconConfItem from "../../custom/IconConfItem";
import { useTranslation } from "react-i18next";

type SelectSubscriptionPanelProps = {
  SetPopupObject: (obj: any) => void;
  role: string;
};

const SelectSubscription: React.FC<SelectSubscriptionPanelProps> = ({
  SetPopupObject,
  role,
}) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  const history = useHistory();

  const getSubscriptions = useCallback(() => {
    getAllSubscriptions("-1", "", "").then((res: any) => {
      if (res && res.subscriptions) {
        const subscriptionsWithKey = res.subscriptions.map(
          (item: Subscription, index: number) => {
            return { ...item, key: index };
          }
        );
        setSubscriptions(subscriptionsWithKey);
      }
    });
  }, []);

  useEffect(() => {
    getSubscriptions();
  }, []);

  const linkFunction: (value: string) => void = (value: string) => {
    history.push(updateSubscriptionUrl(value));
  };

  const updateState = (record: Subscription) => {
    return (
      <div className="button">
        <IconConfItem
          icon={
            <Switch size={"small"} checked={record?.status === enabledLabel} />
          }
          buttonOnConfirm={() => {
            updateSubscriptionStatus({
              subscription_id: record.id,
              status:
                record.status === enabledLabel ? disabledLabel : enabledLabel,
            }).then((res) => {
              if (res && res.result && !res.error) {
                enqueueSnackbar(res.result, {
                  variant: "success",
                  preventDuplicate: true,
                });
                getSubscriptions();
              } else {
                enqueueSnackbar(res?.error || t("somethingWentWrong"), {
                  variant: "error",
                  preventDuplicate: true,
                });
              }
            });
          }}
        />
      </div>
    );
  };

  return (
    <>
      <CustomTitle title={t("editFleet")} />
      <ResponsiveTable
        columns={subscriptionsTableColumns(
          t,
          subscriptions,
          SetPopupObject,
          linkFunction,
          role,
          updateState,
          enqueueSnackbar
        )}
        data={subscriptions}
      />
    </>
  );
};

export default SelectSubscription;
