import "./SubscriptionAdminTable.css";
import React, { useCallback, useEffect, useState } from "react";
import "../../../App.css";
import {
  AddUsersRelation,
  DeleteUsersRelation,
  GetCustomers,
  GetInstallers,
  GetRelInstallersCustomer,
} from "../../../api/services/userService";
import {
  User,
  UserRelation,
  UsersRelationRequest,
} from "../../../api/requests/userService";
import CustomTitle from "../../custom/CustomTitle";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { installersListColumns } from "./inputs";
import {
  customerSelectRel,
  installerSelectRel,
  primaryType,
} from "../../../costants/costants";
import { debounceFunc } from "../../../utilities/utilities";
import { useSnackbar } from "notistack";
import ButtonItem from "../../custom/ButtonItem";
import FormSelect from "../../custom/select/FormSelect";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

type InstallerCustomerRelationsPageProps = {
  SetPopupObject: (obj: any) => void;
};

const InstallerCustomerRelationsPage: React.FC<
  InstallerCustomerRelationsPageProps
> = ({ SetPopupObject }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [relInstallers, setRelInstallers] = useState<UserRelation[]>([]);
  const [installers, setInstallers] = useState<User[]>([]);
  const [instOpt, setInstOpt] = useState<any[]>([]);
  const [customers, setCustomers] = useState<User[]>([]);
  const [custOpt, setCustOpt] = useState<any[]>([]);

  useEffect(() => {
    GetInstallers("-1", "", "").then((res: any) => {
      if (res && res.installers) {
        let tmp: any[] = [];
        res.installers.map((i: User) => {
          tmp = [...tmp, { [i.uid]: i.email }];
        });
        setInstOpt([...tmp]);
        setInstallers(res.installers);
      }
    });
  }, []);
  useEffect(() => {
    GetCustomers("-1", "", "").then((res: any) => {
      if (res && res.customers) {
        let tmp: any[] = [];
        res.customers.map((i: User) => {
          tmp = [...tmp, { [i.uid]: i.email }];
        });
        setCustOpt([...tmp]);
        setCustomers(res.customers);
      }
    });
  }, []);
  useEffect(() => {
    getRelations();
  }, []);

  const getRelations = useCallback(() => {
    GetRelInstallersCustomer().then((res: any) => {
      if (res && res.relations) {
        setRelInstallers(res.relations);
      }
    });
  }, []);

  const [values, setValues] = useState({ installer: "", user: "" });
  const addRelation = useCallback(() => {
    AddUsersRelation({
      installer_id: values.installer,
      customer_id: values.user,
    } as UsersRelationRequest).then((res) => {
      if (res && !res.err) {
        enqueueSnackbar(t("associateUserToCustomerSuccess"), {
          variant: "success",
          preventDuplicate: true,
        });
        getRelations();
      } else {
        enqueueSnackbar(/*res?.err?.message ||*/ t("somethingWentWrong"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    });
  }, [values]);

  const deleteRelation = useCallback((record: UserRelation) => {
    DeleteUsersRelation({
      installer_id: record.installer_id,
      customer_id: record.customer_id,
    } as UsersRelationRequest).then((res) => {
      if (res && !res.err) {
        enqueueSnackbar(t("dissociateUserToCustomerSuccess"), {
          variant: "success",
          preventDuplicate: true,
        });
        getRelations();
      } else {
        enqueueSnackbar(/*res?.err?.message ||*/ t("somethingWentWrong"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    });
  }, []);

  return (
    <>
      <CustomTitle title={t("userList")} />
      <ResponsiveTable
        columns={installersListColumns(
          t,
          relInstallers,
          SetPopupObject,
          deleteRelation
        )}
        data={relInstallers}
      />
    </>
  );
};

export default InstallerCustomerRelationsPage;
