import React from "react";
import { Button, Card, CardContent, Grid } from "@mui/material";
import { homePageIconCards } from "./constants";
import { useHistory } from "react-router-dom";
import "./homepage.css";
import { useTranslation } from "react-i18next";

type HomePageProps = {
  role: string;
};

const HomePage: React.FC<HomePageProps> = ({ role }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Grid
      container
      justifyContent="center"
      spacing={2}
      style={{ marginTop: "5%" }}
    >
      {homePageIconCards(t, role).map((iconItem, index) => (
        <Grid
          className="home-icon-card"
          item
          xs={12}
          sm={6}
          key={`grid_${index}`}
        >
          <Card
            key={`card_${index}`}
            style={{
              backgroundColor: "#1f3366",
              backgroundImage: "none",
              boxShadow: "none",
            }}
          >
            <CardContent className="card-content">
              <img alt="card-logo" className="card-logo" src={iconItem.img} />
            </CardContent>
            <div className="card-buttons">
              {iconItem.text.map((buttonText, buttonIndex) => (
                <Button
                  style={{ textTransform: "none" }}
                  key={buttonIndex}
                  className="z-button"
                  onClick={() => {
                    history.push(iconItem.link[buttonIndex]);
                  }}
                >
                  {buttonText}
                </Button>
              ))}
            </div>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default HomePage;
