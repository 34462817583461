import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ResponseObject, SummaryObject } from "./costant";
import React, { useEffect, useState } from "react";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { useTranslation } from "react-i18next";

type ResultTestingProps = {
  feedback: ResponseObject[];
  steps: any[];
};

const ResultTesting: React.FC<ResultTestingProps> = ({ feedback, steps }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [resultSummary, setResultSummary] = useState<SummaryObject>({
    success: {
      total: 0,
      feedback: [],
      steps: [],
    },
    failed: {
      total: 0,
      feedback: [],
      steps: [],
    },
    skipped: {
      total: 0,
      feedback: [],
      steps: [],
    },
  });

  useEffect(() => {
    setLoading(true);
    let tmp_res: SummaryObject = {
      success: {
        total: 0,
        feedback: [],
        steps: [],
      },
      failed: {
        total: 0,
        feedback: [],
        steps: [],
      },
      skipped: {
        total: 0,
        feedback: [],
        steps: [],
      },
    };
    feedback.map((f: ResponseObject, index: number) => {
      if (f?.response?.msg.toLocaleLowerCase().includes("skip")) {
        tmp_res.skipped.total = tmp_res.skipped.total + 1;
        tmp_res.skipped.feedback = [...tmp_res.skipped.feedback, f];
        tmp_res.skipped.steps = [
          ...tmp_res.skipped.steps,
          steps?.filter((s) => s.command === f.request).length === 1
            ? steps?.filter((s) => s.command === f.request)[0]
            : {},
        ];
      } else {
        if (f?.response?.msg.toLocaleLowerCase().includes("error")) {
          tmp_res.failed.total = tmp_res.failed.total + 1;
          tmp_res.failed.feedback = [...tmp_res.failed.feedback, f];
          tmp_res.failed.steps = [
            ...tmp_res.failed.steps,
            steps?.filter((s) => s.command === f.request).length === 1
              ? steps?.filter((s) => s.command === f.request)[0]
              : {},
          ];
        } else {
          if (f?.response?.msg) {
            tmp_res.success.total = tmp_res.success.total + 1;
            tmp_res.success.feedback = [...tmp_res.success.feedback, f];
            tmp_res.success.steps = [
              ...tmp_res.success.steps,
              steps?.filter((s) => s.command === f.request).length === 1
                ? steps?.filter((s) => s.command === f.request)[0]
                : {},
            ];
          }
        }
      }
    });
    setResultSummary(tmp_res);
    setLoading(false);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div style={{ marginLeft: "10%", marginRight: "10%" }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="success"
          id="success-header"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckCircleSharpIcon
              style={{ color: "green", marginRight: "16px" }}
            />
            <h3>
              {t("testsPassedSuccessfully")}: {resultSummary.success.total}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {resultSummary.success.steps.map((s: any, index: number) => {
            if (s) {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}
                >
                  <h3 style={{ marginRight: "16px", marginBottom: "0px" }}>
                    {s.label}
                  </h3>
                  <Typography>
                    {resultSummary.success.feedback[index]?.response?.msg}
                  </Typography>
                </div>
              );
            }
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="failed"
          id="failed-header"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <CancelSharpIcon style={{ color: "red", marginRight: "16px" }} />
            <h3>
              {t("testsFailed")}: {resultSummary.failed.total}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {resultSummary.failed.steps.map((s: any, index: number) => {
            if (s) {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}
                >
                  <h3 style={{ marginRight: "16px", marginBottom: "0px" }}>
                    {s.label}
                  </h3>
                  <Typography>
                    {resultSummary.failed.feedback[index]?.response?.msg}
                  </Typography>
                </div>
              );
            }
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="skipped"
          id="skipped-header"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Brightness1Icon style={{ marginRight: "16px" }} />
            <h3>
              {t("testsSkipped")}: {resultSummary.skipped.total}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {resultSummary.skipped.steps.map((s: any, index: number) => {
            if (s) {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}
                >
                  <h3 style={{ marginRight: "16px", marginBottom: "0px" }}>
                    {s.label}
                  </h3>
                  <Typography>
                    {resultSummary.skipped.feedback[index]?.response?.msg}
                  </Typography>
                </div>
              );
            }
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ResultTesting;
