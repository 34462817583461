import "./LoginPanel.css";
import ButtonItem from "../../custom/ButtonItem";
import { inputs } from "./inputs";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { login } from "../../../api/services/loginService";
import { LoginRequest } from "../../../api/requests/loginService";
import { routes } from "../costants";
import logo from "../../../images/Logo-COMAU-white.png";
import { successStatusKey } from "../../../costants/costants";
import { TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../../images/Comau_Mate-XT_Frame.png";

const LoginPanel: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    document.body.style.backgroundImage = `url(${backgroundImage})`;
    document.body.style.backgroundRepeat = "no-repeat";
    return () => {
      document.body.style.backgroundImage = "";
      document.body.style.backgroundRepeat = "";
    };
  }, []);

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const setChange = (e: any, index: number) => {
    if (index === 0) {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const [error, setError] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const checkLogin = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    login({ email: email, password: password } as LoginRequest)
      .then((res: any) => {
        if (res && res.status && res.status === successStatusKey) {
          history.push("/");
        } else {
          setError(true);
          enqueueSnackbar(res?.error?.message || t("incorrectCredentials"), {
            variant: "error",
            preventDuplicate: true,
          });
        }
      })
      .catch((e) => {
        setError(true);
        enqueueSnackbar(t("incorrectCredentials"), {
          variant: "error",
          preventDuplicate: true,
        });
        console.log(e);
      });
  };

  const StyledTextField = {
    "& label": {
      color: "white",
    },
    "&:hover label": {
      fontWeight: 700,
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  };

  return (
    <div className={width <= 768 ? "login-box-responsive" : "login-box"}>
      <div className="image-container">
        <img
          src={logo}
          className={width <= 768 ? "image-responsive" : "image"}
          alt="logo-comau"
          style={{ maxWidth: "130px" }}
        />
      </div>
      <Typography
        style={{ fontWeight: "bold", marginTop: "10%", color: "#56a6f5" }}
        variant="h6"
      >
        {t("welcomeToComau")}
      </Typography>
      <form onSubmit={checkLogin}>
        {inputs(t).map((el, index) => {
          return (
            <TextField
              inputProps={{ "data-testid": el.key }}
              error={error}
              type={el.type}
              size="small"
              id={el.key}
              name={el.name}
              label={el.label}
              onChange={(e) => {
                setChange(e, index);
                setError(false);
              }}
              variant="outlined"
              required
              fullWidth
              sx={StyledTextField}
              InputProps={{ sx: { color: "white" } }}
              style={{ marginTop: "4%", paddingRight: "4%" }}
            />
          );
        })}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "4%",
            marginBottom: "4%",
            paddingRight: "4%",
          }}
        >
          <ButtonItem
            buttonType="outlined"
            label={t("login")}
            buttonOnClick={checkLogin}
          />
          <Link className="login-form-forgot" to={routes.recoverStart}>
            {t("resetPassword")}
          </Link>
        </div>
      </form>
    </div>
  );
};

export default LoginPanel;
