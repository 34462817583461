import {
  Card,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { Installation } from "../../../api/requests/installationsService";
import {
  getInstallation,
  getInstallationLogs,
} from "../../../api/services/installationsService";
import { primaryType } from "../../../costants/costants";
import ButtonItem from "../../custom/ButtonItem";
import CustomLoading from "../../custom/CustomLoading";
import TitleWithBack from "../../custom/TitleWithBack";
import InstallationSummary from "../../summary/installationSummary/InstallationSummary";

type TestHistoryProps = {
  installationId: string;
};

const TestHistory: React.FC<TestHistoryProps> = ({ installationId }) => {
  const { t } = useTranslation();

  const [installation, setInstallation] = useState<Installation | null>(null);
  useEffect(() => {
    getInstallation({ id: installationId }).then((res: any) => {
      if (res && res.installation) {
        setInstallation(res.installation);
      }
    });
  }, []);

  const [logs, setLogs] = useState<any[]>([]);
  useEffect(() => {
    getInstallationLogs(installationId, "-1", "", "").then((res: any) => {
      if (res && res.test_logs) {
        setLogs(res.test_logs);
      }
    });
  }, []);

  const [loading, setLoading] = useState<boolean>(false);
  const componentRef = React.useRef(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const onBeforeGetContentResolve = useRef<any>(null);
  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise((resolve: any) => {
      onBeforeGetContentResolve.current = resolve;
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 2000);
    });
  }, []);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "MateSN",
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });

  if (loading) {
    return <CustomLoading />;
  }
  return (
    <>
      <TitleWithBack title={t("testHistory")} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2%",
          marginRight: "5%",
        }}
      >
        <ButtonItem
          buttonType={primaryType}
          label={t("print")}
          buttonOnClick={handlePrint}
        />
      </div>
      <div ref={componentRef} style={{ marginTop: "2%" }}>
        <InstallationSummary selected_installation={installation} />
        <Card
          variant="outlined"
          style={{
            marginBottom: "5%",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <CardContent>
            <TableContainer>
              <Table size="small" aria-label="simple table">
                <TableBody>
                  <TableRow key="header">
                    <TableCell style={{ fontWeight: "bold" }}>
                      {t("date")}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {t("user")}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {t("request")}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {t("response")}
                    </TableCell>
                  </TableRow>
                  {logs.map((l, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {new Date(l.info.date).toLocaleString()}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {l.user_email}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {l.info.request}
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        {l.info.msg}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
    </>
  );
};
export default TestHistory;
