import "./SubscriptionAdminTable.css";
import React, { useCallback, useEffect, useState } from "react";
import "../../../App.css";
import {
  AddUsersRelation,
  DeleteUsersRelation,
  GetCustomers,
  GetDealers,
  GetRelDealersCustomer,
} from "../../../api/services/userService";
import {
  User,
  UserRelation,
  DealerRelation,
  UsersRelationRequest,
} from "../../../api/requests/userService";
import CustomTitle from "../../custom/CustomTitle";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { dealersListColumns } from "./inputs";
import { customerSelectRel, primaryType } from "../../../costants/costants";
import { debounceFunc } from "../../../utilities/utilities";
import { useSnackbar } from "notistack";
import ButtonItem from "../../custom/ButtonItem";
import FormSelect from "../../custom/select/FormSelect";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

type DealerCustomerRelationsPageProps = {
  SetPopupObject: (obj: any) => void;
};

const DealerCustomerRelationsPage: React.FC<
  DealerCustomerRelationsPageProps
> = ({ SetPopupObject }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [relInstallers, setRelInstallers] = useState<UserRelation[]>([]);
  const [dealOpt, setDealOpt] = useState<any[]>([]);
  const [custOpt, setCustOpt] = useState<any[]>([]);

  useEffect(() => {
    GetDealers("-1", "", "").then((res: any) => {
      if (res && res.dealers) {
        let tmp: any[] = [];
        res.dealers.map((i: User) => {
          tmp = [...tmp, { [i.uid]: i.email }];
        });
        setDealOpt([...tmp]);
      }
    });
  }, []);
  useEffect(() => {
    GetCustomers("-1", "", "").then((res: any) => {
      if (res && res.customers) {
        let tmp: any[] = [];
        res.customers.map((i: User) => {
          tmp = [...tmp, { [i.uid]: i.email }];
        });
        setCustOpt([...tmp]);
      }
    });
  }, []);
  useEffect(() => {
    getRelations();
  }, []);

  const getRelations = useCallback(() => {
    GetRelDealersCustomer().then((res: any) => {
      if (res && res.relations) {
        setRelInstallers(res.relations);
      }
    });
  }, []);

  const [values, setValues] = useState({ installer: "", user: "" });
  const addRelation = useCallback(() => {
    AddUsersRelation({
      installer_id: values.installer,
      customer_id: values.user,
    } as UsersRelationRequest).then((res) => {
      if (res && !res.err) {
        enqueueSnackbar(t("associateUserToCustomerSuccess"), {
          variant: "success",
          preventDuplicate: true,
        });
        getRelations();
      } else {
        enqueueSnackbar(/*res?.err?.message ||*/ t("somethingWentWrong"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    });
  }, [values]);

  const deleteRelation = useCallback((record: DealerRelation) => {
    DeleteUsersRelation({
      installer_id: record.dealer_id,
      customer_id: record.customer_id,
    } as UsersRelationRequest).then((res) => {
      if (res && !res.err) {
        enqueueSnackbar(t("dissociateUserToCustomerSuccess"), {
          variant: "success",
          preventDuplicate: true,
        });
        getRelations();
      } else {
        enqueueSnackbar(res?.err?.message || t("somethingWentWrong"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    });
  }, []);

  return (
    <>
      <CustomTitle title={t("dealersAssociations")} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <FormSelect
            key="dealer"
            keyValue="dealer"
            placeholder={t("selectDealer")}
            rules={{ required: true, message: "Insert dealer!" }}
            name="dealer"
            options={dealOpt}
            onChange={(e: any) => {
              setValues({ ...values, installer: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSelect
            key={customerSelectRel.name}
            keyValue={customerSelectRel.name}
            placeholder={t("selectCustomer")}
            rules={{ required: true, message: customerSelectRel.rules }}
            name={customerSelectRel.name}
            options={custOpt}
            onChange={(e: any) => {
              setValues({ ...values, user: e.target.value });
            }}
          />
        </Grid>
      </Grid>
      <div
        className="btn-confirm-container"
        style={{ paddingBottom: "2%", paddingTop: "2%" }}
      >
        <ButtonItem
          label={t("associateDealer")}
          buttonType={primaryType}
          buttonOnClick={debounceFunc(() => addRelation())}
        />
      </div>
      <ResponsiveTable
        columns={dealersListColumns(
          t,
          relInstallers,
          SetPopupObject,
          deleteRelation
        )}
        data={relInstallers}
      />
    </>
  );
};

export default DealerCustomerRelationsPage;
