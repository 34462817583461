import React from "react";
import { Button, Card, CardContent, Grid } from "@mui/material";
import { adminPanelIconCards } from "./constants";
import { useHistory } from "react-router-dom";
import "./homepage.css";
import { gridCardKey, gridKey } from "../../../costants/costants";
import { useTranslation } from "react-i18next";

const AdminPanel: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <Grid
      container
      justifyContent={"center"}
      spacing={2}
      style={{ marginTop: "5%" }}
    >
      {adminPanelIconCards(t).map((iconItem, index) => (
        <Grid
          className="home-icon-card"
          item
          xs={12}
          sm={6}
          key={gridKey(String(index))}
        >
          <Card
            key={gridCardKey(String(index))}
            style={{
              backgroundColor: "#1f3366",
              backgroundImage: "none",
              boxShadow: "none",
            }}
          >
            <CardContent className="card-content">
              <img alt="card-logo" className="card-logo" src={iconItem.img} />
            </CardContent>
            <div className={"card-buttons"}>
              {iconItem.text.map((buttonText, buttonIndex) => (
                <Button
                  style={{ textTransform: "none" }}
                  key={buttonIndex}
                  className={"z-button"}
                  onClick={() => {
                    history.push(iconItem.link[buttonIndex]);
                  }}
                >
                  {buttonText}
                </Button>
              ))}
            </div>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default AdminPanel;

/*
celeste: #56a6f5
blu: #1f3366
arancione: #cd8133
blu-tabella: #3255ab
*/
