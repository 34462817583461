import {
  ChangeStatusRequest,
  Subscription,
} from "../../../api/requests/subscription";
import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import "../../../App.css";
import React from "react";
import {
  dateType,
  iconType,
  linkType,
  stringType,
} from "../../../utilities/utilities";
import { roles } from "../costants";
import {
  changeStatus,
  DeleteSubscription,
} from "../../../api/services/subscriptionService";
import {
  acceptKey,
  activationLabel,
  address,
  companyInfoLabel,
  deactivationLabel,
  declineKey,
  enabledLabel,
  expiryDate,
  vat,
  zip,
  name,
} from "../../../costants/costants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ButtonConfItem from "../../custom/ButtonConfItem";
import { IconButton, Tooltip } from "@mui/material";

export const subscriptionsTableColumns: (
  t: any,
  rowData: Object[],
  SetPopupObject: (obj: any) => void,
  link: (v: string) => void,
  role: string,
  updSubState: (record: Subscription) => React.ReactNode,
  enqueueSnackbar: any
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  linkFunction,
  role,
  updSubState,
  enqueueSnackbar
) => [
  {
    label: t("name"),
    name: name.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="details-container">
          <Tooltip title={t("details")}>
            <IconButton
              onClick={() => {
                const tmp: any = rowData[data.rowIndex];
                const objects: any[] = [
                  {
                    name: tmp.name,
                    status: tmp.status,
                    created_at: tmp.created_at,
                    expiry_date: tmp.expiry_date,
                  },
                  {
                    detail_title: t("companyInfo"),
                    company: tmp.company_info.name,
                    address: tmp.company_info.address,
                    city: tmp.company_info.city,
                    province: tmp.company_info.province,
                    country: tmp.company_info.country,
                    zip_code: tmp.company_info.zip_code,
                    vat_number: tmp.company_info.vat_number,
                    phone: tmp.company_info.phone,
                    sdi: tmp.company_info.sdi,
                    pec: tmp.company_info.pec,
                  },
                  {
                    detail_title: t("customerInfo"),
                    name: tmp.customer_name,
                    address: tmp.customer_info.address,
                    city: tmp.customer_info.city,
                    province: tmp.customer_info.province,
                    country: tmp.customer_info.country,
                    zip_code: tmp.customer_info.zip_code,
                    phone: tmp.customer_info.phone,
                  },
                ];
                SetPopupObject(objects);
              }}
            >
              <InfoOutlinedIcon className="details-icon" />
            </IconButton>
          </Tooltip>
          <div className="details">
            <ResponsiveTableCell
              type={linkType}
              value={value}
              link={() => {
                linkFunction((rowData[data.rowIndex] as any).id);
              }}
            />
          </div>
        </div>
      ),
    },
  },
  {
    label: t("email"),
    name: "customer_email",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("customer"),
    name: `customer_name`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("company"),
    name: `${companyInfoLabel}.${name.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("vat"),
    name: `${companyInfoLabel}.${vat.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("address"),
    name: `${companyInfoLabel}.${address.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={`${value}, ${
            data.tableData[data.rowIndex].company_info.city
          }, ${data.tableData[data.rowIndex].company_info.province}`}
        />
      ),
    },
  },
  {
    label: t("zip"),
    type: stringType,
    name: `${companyInfoLabel}.${zip.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("expiration"),
    name: expiryDate.key,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={dateType}
          value={
            data.tableData[data.rowIndex].status === enabledLabel ? value : ""
          }
        />
      ),
    },
  },
  ...statusIfAdmin(t, role, updSubState, enqueueSnackbar),
];

const statusIfAdmin = (
  t: any,
  role: string,
  updSubState: (record: Subscription) => React.ReactNode,
  enqueueSnackbar: any
) => {
  if (role === roles.admin) {
    return [
      {
        label: t("status"),
        name: "",
        options: {
          sort: true,
          filter: false,
          customBodyRender: (value: string, data: any) => (
            <div className="cell">
              {updSubState(data.tableData[data.rowIndex])}
            </div>
          ),
        },
      },
      {
        label: t("action"),
        name: "requested_status",
        options: {
          sort: true,
          filter: false,
          customBodyRender: (value: string, data: any) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {value === activationLabel ? (
                <p> {t("activationRequest")} </p>
              ) : value === deactivationLabel ? (
                <p> {t("deactivationRequest")} </p>
              ) : null}
              {value !== "" ? (
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <ButtonConfItem
                    buttonLabel={t("accept")}
                    buttonOnConfirm={() => {
                      const request: ChangeStatusRequest = {
                        subscription_id: data.tableData[data.rowIndex].id,
                        response_status: acceptKey,
                      };
                      changeStatus(request).then((res) => {
                        if (res && res.subscription) {
                          window.location.reload();
                        }
                      });
                    }}
                  />
                  <ButtonConfItem
                    buttonLabel={t("decline")}
                    buttonOnConfirm={() => {
                      const request: ChangeStatusRequest = {
                        subscription_id: data.tableData[data.rowIndex].id,
                        response_status: declineKey,
                      };
                      changeStatus(request).then((res) => {
                        if (res && res.subscription) {
                          window.location.reload();
                        }
                      });
                    }}
                  />
                </div>
              ) : null}
            </div>
          ),
        },
      },
      /*
      {
        label: t("delete"),
        name: "id",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: string) => (
            <ResponsiveTableCell
              type={iconType}
              value=""
              icon={
                <IconConfItem
                  icon={<DeleteOutlineOutlinedIcon />}
                  questionLabel={t("deleteFleetQuestion")}
                  buttonOnConfirm={() => {
                    DeleteSubscription(value).then((res: any) => {
                      if (res && !res.err) {
                        enqueueSnackbar(t("subscriptionDeleteSuccess"), {
                          variant: "success",
                          preventDuplicate: true,
                        });
                        window.location.reload();
                      } else {
                        enqueueSnackbar(
                          res?.err?.message || t("somethingWentWrong"),
                          {
                            variant: "error",
                            preventDuplicate: true,
                          }
                        );
                      }
                    });
                  }}
                />
              }
            />
          ),
        },
      },
      */
    ];
  } else {
    return [];
  }
};
