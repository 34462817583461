export const firstPortRange = 50000;
export const lastPortRange = 50030;
export const maxConnectionAttempts = 3;

export type FileObject = {
  name: string;
  content: Object;
};

export type ResponseObject = {
  request: string;
  response?: {
    action: string;
    status: string;
    result: string;
    msg: string;
  };
};

export type TestObject = {
  label: string;
  command: string;
  description: string;
};

export type SummaryObject = {
  success: {
    total: number;
    steps: any[];
    feedback: ResponseObject[];
  };
  failed: {
    total: number;
    steps: any[];
    feedback: ResponseObject[];
  };
  skipped: {
    total: number;
    steps: any[];
    feedback: ResponseObject[];
  };
};

export const devicesAction: string = "devices";
export const testSignal: string = "test_signal";

export const titleError: string = "Device not detected correctly";
export const subtitleError: string =
  " Please check that the device is connected properly and reload this page";

export const tests = (t: any, model: "lh" | "rh") => [
  {
    label: t("checkUid"),
    tests: [
      {
        label: t("checkUid"),
        command: "uid",
        description: t("makeSureCheckUid"),
      },
    ],
  },
  {
    label: t("wifiConnection"),
    tests: [
      {
        label: t("wifiConnection"),
        command: "wifi",
        description: t(`makeSureWifiConnection`),
      },
    ],
  },
  {
    label: t("temperatureHumidity"),
    tests: [
      {
        label: t("temperatureHumidity"),
        command: "temphum",
        description: t(`makeSureTemperatureHumidity`),
      },
    ],
  },
  {
    label: t("checkAxes"),
    tests: [
      {
        label: t("checkAxes"),
        command: "accelerometer",
        description: t("makeSureCheckAxes"),
      },
    ],
  },
  {
    label: t("batteryStatus"),
    tests: [
      {
        label: t("batteryStatus"),
        command: "battery",
        description: t("makeSureBatteryStatus"),
      },
    ],
  },
  {
    label: t("ledInfo"),
    tests: [
      {
        label: t("ledInfo"),
        command: "led_info",
        description: t("makeSureLed"),
      },
    ],
  },
  {
    label: t("ledBattery"),
    tests: [
      {
        label: t("ledBattery"),
        command: "led_battery",
        description: t("makeSureLed"),
      },
    ],
  },
  {
    label: `${t("correctMovement")} ${
      model === "lh" ? "AB(LH)-CR82475800" : "AB(RH)-CR82475700"
    }`,
    tests: [
      {
        label: `${t("correctMovement")} ${
          model === "lh" ? "AB(LH)-CR82475800" : "AB(RH)-CR82475700"
        }`,
        command: model === "lh" ? "motion_LH" : "motion_RH",
        description: t("makeSureCorrectMovement"),
      },
    ],
  },
];
