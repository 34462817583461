import { MenuType, SubMenuType } from "../types/navbar/types";
import {
  alarmsPageUrl,
  createCustomerPageUrl,
  createSubscriptionPageUrl,
  customersListPageUrl,
  deviceConfigurationUrl,
  installerPanelIconsUrl,
  routes,
  subscriptionsListToDataHistoryPageUrl,
  subscriptionsListToInstallationPageUrl,
  subscriptionsListToUpdateUrl,
} from "../pages/costants";
import {
  addCustomerKey,
  alarmsKey,
  createSubscriptionKey,
  dataRealTimeKey,
  dealerRole,
  historyKey,
  updateCustomerKey,
  updateSubscriptionKey,
} from "../../costants/costants";

const installer: (t: any) => SubMenuType[] = (t: any) => [
  {
    key: addCustomerKey,
    label: t("createCustomer"),
    link: createCustomerPageUrl,
    elements: [],
  },
  {
    key: updateCustomerKey,
    label: t("editCustomer"),
    link: customersListPageUrl,
    elements: [],
  },
  {
    key: createSubscriptionKey,
    label: t("createFleet"),
    link: createSubscriptionPageUrl,
    elements: [],
  },
  {
    key: updateSubscriptionKey,
    label: t("editFleet"),
    link: subscriptionsListToUpdateUrl,
    elements: [],
  },
];

export const dealerMenuItems: (t: any) => MenuType[] = (t: any) => [
  // TODO: remove first element, it is necessary because the first element is not shown
  {
    key: "",
    label: "",
    elements: [],
    link: "",
  },
  {
    key: dataRealTimeKey,
    label: t("realTimeData"),
    elements: [],
    link: subscriptionsListToInstallationPageUrl,
  },
  {
    key: historyKey,
    label: t("dataHistory"),
    elements: [],
    link: subscriptionsListToDataHistoryPageUrl,
  },
  {
    key: alarmsKey,
    label: t("alarms"),
    link: alarmsPageUrl,
    elements: [],
  },
  {
    key: "deviceConfiguration",
    label: t("addDevice"),
    link: deviceConfigurationUrl,
    elements: [],
  },
  {
    key: "wifiConfiguration",
    label: t("wifiConfiguration"),
    link: routes.subscriptionsListToWifi,
    elements: [],
  },
  {
    key: dealerRole,
    label: t("configurator"),
    elements: installer(t),
    link: installerPanelIconsUrl,
  },
];
