import data from "./img/graph.svg";
import dealer from "./img/dealer.svg";
import user from "./img/user.svg";
import change from "./img/change.svg";
import device from "./img/device.svg";
import alarm from "./img/alert.svg";
import inst from "./img/inst.svg";
import history from "./img/cloud-download.svg";

import { deviceConfigurationUrl, roles, routes } from "../costants";
import {
  adminRole,
  dealerRole,
  installerRole,
} from "../../../costants/costants";

export type IconCard = {
  img: string;
  text: string[];
  link: string[];
};

export const roleBasedIcons: (t: any, r: string) => IconCard[] = (t, r) => {
  if (r === installerRole) {
    return [];
  } else if (r === adminRole) {
    return [
      {
        img: device,
        text: [t("wifiConfiguration")],
        link: [routes.subscriptionsListToWifi],
      },
    ];
  } else if (r === dealerRole) {
    return [
      {
        img: device,
        text: [t("addDevice"), t("wifiConfiguration")],
        link: [deviceConfigurationUrl, routes.subscriptionsListToWifi],
      },
    ];
  } else if (r === roles.customer) {
    return [
      {
        img: device,
        text: [t("addDevice"), t("wifiConfiguration")],
        link: [deviceConfigurationUrl, routes.subscriptionsListToWifi],
      },
    ];
  } else {
    return [];
  }
};

export const homePageIconCards: (t: any, r: string) => IconCard[] = (t, r) => {
  return [
    {
      img: data,
      text: [t("realTimeData")],
      link: [routes.subscriptionsListToInstallationPage],
    },
    {
      img: history,
      text: [t("dataHistory")],
      link: [routes.subscriptionsListToDataHistory],
    },
    {
      img: alarm,
      text: [t("alarms")],
      link: [routes.alarms],
    },
    ...roleBasedIcons(t, r),
  ];
};

export const adminPanelIconCards: (t: any) => IconCard[] = (t: any) => [
  {
    img: device,
    text: [t("createInstaller"), t("editInstaller")],
    link: [routes.addInstaller, routes.installersList],
  },
  {
    img: dealer,
    text: [t("createDealer"), t("editDealer")],
    link: [routes.createDealer, routes.dealersList],
  },
  {
    img: change,
    text: [t("userList"), t("dealersAssociations")],
    link: [routes.installersAssociations, routes.dealersAssociations],
  },
];

export const configuratorPanelIconCards: (
  role: string,
  t: any
) => IconCard[] = (role: string, t: any) => {
  if (role === dealerRole) {
    return [
      {
        img: user,
        text: [t("createCustomer"), t("editCustomer")],
        link: [routes.addCustomer, routes.customersList],
      },
      {
        img: change,
        text: [t("createFleet"), t("editFleet")],
        link: [routes.addSubscription, routes.subscriptionsListToUpdate],
      },
    ];
  } else {
    return [
      {
        img: user,
        text: [t("createCustomer"), t("editCustomer")],
        link: [routes.addCustomer, routes.customersList],
      },
      {
        img: change,
        text: [t("createFleet"), t("editFleet")],
        link: [routes.addSubscription, routes.subscriptionsListToUpdate],
      },
      {
        img: device,
        text: [t("createDevice"), t("editDevice")],
        link: [routes.addInstallation, routes.installationsListToUpdate],
      },
      {
        img: inst,
        text: [t("testHistory")],
        link: [routes.subscriptionsListToLogs],
      },
    ];
  }
};
