import { Grid, Typography, Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Installation } from "../../../api/requests/installationsService";
import {
  getInstallation,
  saveLogs,
} from "../../../api/services/installationsService";
import { primaryType, technicalTesting } from "../../../costants/costants";
import ButtonConfItem from "../../custom/ButtonConfItem";
import ButtonItem from "../../custom/ButtonItem";
import TitleWithBack from "../../custom/TitleWithBack";
import { technicalTestingUrl } from "../costants";
import { TestObject, tests } from "./costant";

type SelectTestProps = {
  installationId: string;
  selectedTests_: TestObject[];
  setSelectedTests_: React.Dispatch<React.SetStateAction<TestObject[]>>;
  email: string;
};

const SelectTest: React.FC<SelectTestProps> = ({
  installationId,
  selectedTests_,
  setSelectedTests_,
  email,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTests, setSelectedTests] = useState<TestObject[]>([]);
  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  useEffect(() => {
    getInstallation({ id: installationId }).then((res: any) => {
      if (res && res.installation) {
        setSelectedInstallation(res.installation);
      }
      setLoading(false);
    });
  }, []);

  const checkModelTests = (model: string) => {
    return tests(t, model.toLocaleLowerCase().includes("lh") ? "lh" : "rh");
  };

  const saveHistory = () => {
    saveLogs(installationId, [
      {
        action: "loading_test_firmware",
        status: "finished",
        result: "success",
        request: "loading TEST firmware",
        msg: "OK firmware loading started successfully",
        date: new Date().toISOString(),
        user: email,
      },
    ]).then((res) => {
      if (res && !res.err) {
        console.log("LOGS SAVED CORRECTLY");
      } else {
        console.log(res?.err?.message || "ERROR SAVING LOGS");
      }
    });
  };

  const history = useHistory();
  const submit = () => {
    setSelectedTests_([...selectedTests]);
    saveHistory();
    history.push(technicalTestingUrl(installationId));
  };
  const submitAll = () => {
    let tmp_res: TestObject[] = [];
    checkModelTests(selectedInstallation?.model || "").map(
      (test: any, index: number) => {
        tmp_res = [...tmp_res, ...test.tests];
      }
    );
    setSelectedTests_([...tmp_res]);
    saveHistory();
    history.push(technicalTestingUrl(installationId));
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <TitleWithBack title={t("technicalTesting")} key={technicalTesting} />
      <div className="my-updateinstallation-container my-updateinstallation-container-responsive">
        <Typography style={{ marginBottom: "10px" }}>
          {" "}
          {t("selectSignals")}{" "}
        </Typography>
        <Grid container>
          {checkModelTests(selectedInstallation?.model || "").map(
            (test: any, index: number) => {
              return (
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={test.label}
                        onClick={(e: any) => {
                          if (e.target.checked) {
                            setSelectedTests([...selectedTests, ...test.tests]);
                          } else {
                            let tmp_res: TestObject[] = [...selectedTests];
                            test.tests.map((tt: TestObject) => {
                              tmp_res.splice(tmp_res.indexOf(tt), 1);
                            });
                            setSelectedTests([...tmp_res]);
                          }
                        }}
                      />
                    }
                    label={test.label}
                  />
                </Grid>
              );
            }
          )}
        </Grid>
        <div className="btn-container">
          <ButtonConfItem
            buttonLabel={t("reset")}
            buttonOnConfirm={() => {}}
            questionLabel={t("question")}
          />
          <ButtonItem
            buttonType={primaryType}
            label={t("runAllTests")}
            buttonOnClick={submitAll}
          />
          <ButtonItem
            buttonType={primaryType}
            label={t("runSelectedTests")}
            buttonOnClick={submit}
            disabled={selectedTests.length <= 0}
          />
        </div>
      </div>
    </>
  );
};

export default SelectTest;
