import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { debounceFunc } from "../../../utilities/utilities";

type ConfirmOperationProps = {
  open: boolean;
  question: string;
  onCancel: any;
  onConfirm: any;
};

const ConfirmOperation: React.FC<ConfirmOperationProps> = ({
  open,
  question,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title">
      <DialogTitle id="alert-dialog-title">{question}</DialogTitle>
      <DialogActions>
        <Button onClick={debounceFunc(onConfirm)}>{t("yes")}</Button>
        <Button onClick={debounceFunc(onCancel)} autoFocus>
          {t("no")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmOperation;
