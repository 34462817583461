import "./createDealer.css";
import ButtonItem from "../../custom/ButtonItem";
import ButtonConfItem from "../../custom/ButtonConfItem";
import { AddUser as ApiAddUser } from "../../../api/services/userService";
import React, { useEffect, useState } from "react";
import "../../../App.css";
import CustomTitle from "../../custom/CustomTitle";
import { createDealerFields } from "./inputs";
import FormInput from "../../custom/input/FormInput";
import FormSelect from "../../custom/select/FormSelect";
import { engCountries, itaCountries } from "../../custom/select/countries";
import OperationResult from "../../custom/OperationResult";
import {
  dealerRole,
  errorStatus,
  formInputKey,
  primaryType,
  selectType,
  textType,
} from "../../../costants/costants";
import { Card, CardContent, Grid } from "@mui/material";
import { checkValues } from "../../../utilities/utilities";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { routes } from "../costants";

const CreateDealer: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState<string>("");
  const goBack = () => setStatus("");

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [error, setError] = useState<boolean>(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    customer_phone: "",
    customer_address: "",
    customer_city: "",
    customer_province: "",
    customer_country: "",
    customer_zip_code: "",
  });

  const history = useHistory();
  const handleSubmit = () => {
    if (checkValues(values) && values.password === values.confirm_password) {
      ApiAddUser({
        name: values.name,
        email: values.email,
        password: values.password,
        confirm_password: values.confirm_password,
        role: dealerRole,
        user_info: {
          phone: values.customer_phone,
          address: values.customer_address,
          city: values.customer_city,
          province: values.customer_province,
          country: values.customer_country,
          zip_code: values.customer_zip_code,
        },
      })
        .then((res: any) => {
          if (res && res.user) {
            setStatus("success");
            history.push(routes.addSubscription);
          } else {
            setStatus(res?.err?.message || "");
          }
        })
        .catch((e) => {
          setStatus(e);
          console.log(e);
        });
    } else {
      if (values.password !== values.confirm_password) {
        enqueueSnackbar(t("passwordDoNotMatch"), {
          variant: "error",
          preventDuplicate: true,
        });
      } else {
        setError(true);
      }
    }
  };

  if (status) {
    return (
      <OperationResult
        onClickProp={status === errorStatus ? goBack : undefined}
        status={status === "success" ? "success" : "error"}
        operation={"create"}
        entity={"dealer"}
        error={status}
      />
    );
  }
  return (
    <>
      <CustomTitle title={t("createDealer")} />
      <Card
        variant="outlined"
        style={{
          marginLeft: width <= 575 || height <= 815 ? "2%" : "25%",
          marginRight: width <= 575 || height <= 815 ? "2%" : "25%",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            {createDealerFields(t).map((el) => {
              return (
                <Grid item xs={12} md={6}>
                  {el.type !== selectType ? (
                    <FormInput
                      error={error}
                      key={formInputKey(el.key)}
                      placeholder={el.label}
                      type={el.type ?? textType}
                      keyValue={el.key}
                      name={el.name}
                      rules={el.rules}
                      value={(values as any)[el.name]}
                      onchange={(e: any) => {
                        setValues({ ...values, [el.name]: e.target.value });
                      }}
                    />
                  ) : (
                    <FormSelect
                      error={error}
                      key={formInputKey(el.key)}
                      placeholder={el.label}
                      keyValue={el.key}
                      name={el.name}
                      options={
                        i18n.language === "en" ? engCountries : itaCountries
                      }
                      rules={el.rules}
                      value={(values as any)[el.name]}
                      onChange={(e: any) => {
                        setValues({ ...values, [el.name]: e.target.value });
                      }}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
          <div className="btn-container">
            <ButtonConfItem
              buttonLabel={t("reset")}
              buttonOnConfirm={() =>
                setValues({
                  name: "",
                  email: "",
                  password: "",
                  confirm_password: "",
                  customer_phone: "",
                  customer_address: "",
                  customer_city: "",
                  customer_province: "",
                  customer_country: "",
                  customer_zip_code: "",
                })
              }
              questionLabel={t("question")}
            />
            <ButtonItem
              buttonType={primaryType}
              label={t("ok")}
              buttonOnClick={handleSubmit}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default CreateDealer;
