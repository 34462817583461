import {
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Visibility,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  VisibilityOff,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import TitleWithBack from "../../custom/TitleWithBack";
import { useTranslation } from "react-i18next";
import ButtonConfItem from "../../custom/ButtonConfItem";
import { primaryType } from "../../../costants/costants";
import ButtonItem from "../../custom/ButtonItem";
import { useSnackbar } from "notistack";
import {
  getInstallation,
  wifiConfiguration,
} from "../../../api/services/installationsService";
import InstallationSummary from "../installation/summaryCards/InstallationSummary";
import { Installation } from "../../../api/requests/installationsService";

function isValidSSID(str: string) {
  return /^[^!#;+\]/"\t][^+\]/"\t]{0,30}[^ +\]/"\t]$|^[^ !#;+\]/"\t]$[ \t]+$/g.test(
    str
  );
}

function isValidWPA2(str: string) {
  return /^[\u0020-\u007e]{8,63}$/g.test(str);
}

function isValidIPv4(str: string) {
  return /^(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)$/g.test(
    str
  );
}

type WifiConfigurationProps = {
  installationId: string;
};
const WifiConfiguration: React.FC<WifiConfigurationProps> = ({
  installationId,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [configuration, setConfiguration] = useState({
    ssid: "MATE-XT4.0",
    pwd: "12345678",
    security: 4,
    dhcp: true,
    ip: "",
    mask: "",
    gateway: "",
    dns: "8.8.8.8",
    hostname: "",
    ent_user: "",
    ent_pwd: "",
  });

  const [advancedSettings, setAdvancedSettings] = useState(false);
  const [ipv4, setIpv4] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const checkFields = () => {
    if (
      !(
        configuration.ssid &&
        configuration.pwd &&
        isValidSSID(configuration.ssid) &&
        isValidWPA2(configuration.pwd)
      )
    ) {
      return false;
    }

    if (ipv4) {
      if (
        !(
          !configuration.dhcp &&
          configuration.ip &&
          configuration.mask &&
          configuration.gateway &&
          configuration.dns &&
          isValidIPv4(configuration.ip) &&
          isValidIPv4(configuration.mask) &&
          isValidIPv4(configuration.gateway) &&
          isValidIPv4(configuration.dns)
        )
      ) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    if (checkFields()) {
      wifiConfiguration(installationId, { value: configuration }).then(
        (res) => {
          if (res && !res.err) {
            enqueueSnackbar(t("wifiConfigurationSuccess"), {
              variant: "success",
              preventDuplicate: true,
            });
          } else {
            enqueueSnackbar(t("somethingWentWrong"), {
              variant: "error",
              preventDuplicate: true,
            });
          }
        }
      );
    } else {
      enqueueSnackbar(t("completeAllFields"), {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  useEffect(() => {
    getInstallation({ id: installationId }).then((res: any) => {
      if (res && res.installation) {
        setSelectedInstallation(res.installation);
      }
    });
  }, []);

  return (
    <>
      <TitleWithBack title={t("wifiConfiguration")} key="wifiConfiguration" />
      <div style={{ marginBottom: "3%", marginRight: "5%", marginLeft: "5%" }}>
        <InstallationSummary selected_installation={selectedInstallation} />
      </div>
      <Grid
        container
        item
        spacing={4}
        alignItems="center"
        justifyContent="center"
        textAlign="left"
      >
        <Grid item xs={12} md={9} xl={6}>
          <Card variant="outlined">
            <CardContent style={{ margin: "24px" }}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <TextField
                    onChange={(e) =>
                      setConfiguration({
                        ...configuration,
                        ssid: e.target.value,
                      })
                    }
                    value={configuration.ssid}
                    fullWidth
                    size="small"
                    label={t("ssid")}
                    error={
                      !!configuration.ssid && !isValidSSID(configuration.ssid)
                    }
                    helperText={
                      !!configuration.ssid && !isValidSSID(configuration.ssid)
                        ? t("invalidSsid")
                        : undefined
                    }
                    autoComplete="off"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(e) =>
                      setConfiguration({
                        ...configuration,
                        pwd: e.target.value,
                      })
                    }
                    value={configuration.pwd}
                    fullWidth
                    size="small"
                    label={t("password")}
                    type={showPassword ? "text" : "password"}
                    error={
                      !!configuration.pwd && !isValidWPA2(configuration.pwd)
                    }
                    helperText={
                      !!configuration.pwd && !isValidWPA2(configuration.pwd)
                        ? t("invalidWpa2Password")
                        : undefined
                    }
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title={t("visibilityToken")}>
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              size="small"
                              style={{ marginRight: "8px" }}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => setAdvancedSettings(!advancedSettings)}
                    variant="text"
                    color="inherit"
                    endIcon={
                      !advancedSettings ? (
                        <ExpandMoreOutlined />
                      ) : (
                        <ExpandLessOutlined />
                      )
                    }
                  >
                    {t("advancedSettings")}
                  </Button>
                  <Collapse
                    in={advancedSettings}
                    timeout="auto"
                    unmountOnExit
                    style={{ marginTop: "4px" }}
                  >
                    <Grid container spacing={1} direction="column">
                      <Grid item>
                        <Typography variant="h6">{t("ipv4")}</Typography>
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={ipv4}
                          onChange={() => {
                            setIpv4(!ipv4);
                            setConfiguration({
                              ...configuration,
                              dhcp: ipv4 ? true : false,
                            });
                          }}
                        />
                        {ipv4 ? t("manual") : t("automatic")}
                      </Grid>
                      <Grid item>
                        <Collapse in={ipv4} timeout="auto" unmountOnExit>
                          <Grid container spacing={1} direction="column">
                            <Grid item>
                              <TextField
                                onChange={(e) =>
                                  setConfiguration({
                                    ...configuration,
                                    ip: e.target.value,
                                  })
                                }
                                required={ipv4}
                                value={configuration.ip}
                                fullWidth
                                size="small"
                                label={t("ipAddress")}
                                error={
                                  !!configuration.ip &&
                                  !isValidIPv4(configuration.ip)
                                }
                                helperText={
                                  !!configuration.ip &&
                                  !isValidIPv4(configuration.ip)
                                    ? t("invalidIpv4Address")
                                    : undefined
                                }
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                onChange={(e) =>
                                  setConfiguration({
                                    ...configuration,
                                    mask: e.target.value,
                                  })
                                }
                                required={ipv4}
                                value={configuration.mask}
                                fullWidth
                                size="small"
                                label={t("subnetMask")}
                                error={
                                  !!configuration.mask &&
                                  !isValidIPv4(configuration.mask)
                                }
                                helperText={
                                  !!configuration.mask &&
                                  !isValidIPv4(configuration.mask)
                                    ? t("invalidIpv4Address")
                                    : undefined
                                }
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                onChange={(e) =>
                                  setConfiguration({
                                    ...configuration,
                                    gateway: e.target.value,
                                  })
                                }
                                required={ipv4}
                                value={configuration.gateway}
                                fullWidth
                                size="small"
                                label="Gateway"
                                error={
                                  !!configuration.gateway &&
                                  !isValidIPv4(configuration.gateway)
                                }
                                helperText={
                                  !!configuration.gateway &&
                                  !isValidIPv4(configuration.gateway)
                                    ? t("invalidIpv4Address")
                                    : undefined
                                }
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                onChange={(e) =>
                                  setConfiguration({
                                    ...configuration,
                                    dns: e.target.value,
                                  })
                                }
                                required={ipv4}
                                value={configuration.dns}
                                fullWidth
                                size="small"
                                label={t("dns")}
                                error={
                                  !!configuration.dns &&
                                  !isValidIPv4(configuration.dns)
                                }
                                helperText={
                                  !!configuration.dns &&
                                  !isValidIPv4(configuration.dns)
                                    ? t("invalidIpv4Address")
                                    : undefined
                                }
                              />
                            </Grid>
                          </Grid>
                        </Collapse>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
              <div className="btn-container">
                <ButtonConfItem
                  buttonLabel={t("reset")}
                  buttonOnConfirm={() =>
                    setConfiguration({
                      ssid: "MATE-XT4.0",
                      pwd: "12345678",
                      security: 4,
                      dhcp: true,
                      ip: "",
                      mask: "",
                      gateway: "",
                      dns: "8.8.8.8",
                      hostname: "",
                      ent_user: "",
                      ent_pwd: "",
                    })
                  }
                  questionLabel={t("question")}
                />
                <ButtonConfItem
                  buttonType={primaryType}
                  buttonLabel={t("ok")}
                  buttonOnConfirm={handleSubmit}
                  questionLabel={t("wifiConfigurationUpdate")}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default WifiConfiguration;
